import { useEffect } from 'react';
import { getTestsSuccess, getUserTestsSuccess, startLoadingTests, startLoadingUserTests } from '../../redux/slices/student';
import { useDispatch, useSelector } from '../../redux/store';
import { getMembershipUserCurriculumItemTests } from '../../services/lms/membership/userCurriculumItemTest/getMembershipUserCurriculumItemTests';
import { useStatelesFetch } from '../useFetch';

export default function useTests(options: {
  load: boolean;
  membershipId: number;
  itemId: number;
  rep?: boolean;
}) {
  const { load, membershipId, itemId } = options;

  const { fetch } = useStatelesFetch({
    fetcher: getMembershipUserCurriculumItemTests
  }, membershipId, itemId);

  const currentTestList = useSelector(x => x.student.testList);
  const userTestList = useSelector(x => x.student.userTestList);
  const dispatch = useDispatch();

  useEffect(() => {
    doFetch();
  }, [load, membershipId, itemId]);

  async function doFetch() {
    if (!load) {
      return;
    }

    if (itemId) {
      if (membershipId in userTestList &&
        itemId in userTestList[membershipId] &&
        userTestList[membershipId][itemId].loading) {
        return;
      }

      // TODO: We might want to force reload this data???
      if (membershipId in userTestList &&
        itemId in userTestList[membershipId] &&
        !!userTestList[membershipId][itemId].tests) {
        return;
      }

      dispatch(startLoadingUserTests({
        membershipId: membershipId,
        userCurriculumItemId: itemId,
      }));

      let response = await fetch();
      if (response?.success) {
        dispatch(getUserTestsSuccess({
          membershipId: membershipId,
          userCurriculumItemId: itemId,
          tests: response.datas,
        }));
      }
    }
    else {
      if (membershipId in currentTestList &&
        currentTestList[membershipId].loading) {
        // console.log('Already loading tests...');
        return;
      }

      if (membershipId in currentTestList &&
        !!currentTestList[membershipId].tests) {
        // console.log('Already has tests data...');
        return;
      }

      dispatch(startLoadingTests({
        membershipId: membershipId,
      }));

      let response = await fetch();
      if (response?.success) {
        dispatch(getTestsSuccess({
          membershipId: membershipId,
          tests: response.datas,
        }));
      }
    }
  }

  return !itemId ? {
    ...currentTestList[membershipId]
  } : {
    ...(userTestList[membershipId] ? userTestList[membershipId][itemId] : {})
  };
}