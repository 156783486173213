import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserCurriculumItem, UserCurriculumItemInfo } from '../../../contract/lms/membership/UserCurriculumItem';
import { UserCurriculumItemTestFullDetail, UserCurriculumItemTestList } from '../../../contract/lms/membership/UserCurriculumItemTest';
import { UserCurriculumItemTaskFullDetail, UserTaskList } from '../../../contract/lms/membership/UserTask';
import { MembershipDetail } from '../../../contract/membership/Membership';
import { UserTestList } from '../../../contract/lms/membership/UserTest';

type State = {
  currentUserCurriculumItemList: {
    [membershipId: number]: {
      loading: boolean;
      userCurriculumItem: UserCurriculumItemInfo | null;
    }
  };
  userCurriculumItemList: {
    [membershipId: number]: {
      [userCurriculumItemId: number]: {
        loading: boolean;
        userCurriculumItem: UserCurriculumItemInfo | null;
      };
    }
  };


  taskList: {
    [membershipId: number]: {
      loading: boolean;
      tasks: UserTaskList[] | null;
    };
  };
  userTaskList: {
    [membershipId: number]: {
      [userCurriculumItemId: number]: {
        loading: boolean;
        tasks: UserTaskList[] | null;
      };
    };
  };


  taskDetailList: {
    [membershipId: number]: {
      [taskId: number]: {
        loading: boolean;
        task: UserCurriculumItemTaskFullDetail | null;
      }
    }
  };

  // TODO: Include itemId after membershipId
  testList: {
    [membershipId: number]: {
      loading: boolean;
      tests: UserCurriculumItemTestList[] | null;
    }
  };
  userTestList: {
    [membershipId: number]: {
      [userCurriculumItemId: number]: {
        loading: boolean;
        tests: UserCurriculumItemTestList[] | null;
      };
    };
  };
  // TODO: Include itemId after membershipId
  testDetailList: {
    [membershipId: number]: {
      [testId: number]: {
        loading: boolean;
        test: UserCurriculumItemTestFullDetail | null;
      }
    }
  };

  // TODO: Include itemId after membershipId
  membershipList: {
    [membershipId: number]: {
      loading: boolean;
      membership: MembershipDetail | null;
    }
  };
};

const initialState: State = {
  currentUserCurriculumItemList: [],
  userCurriculumItemList: [],

  taskList: [],
  userTaskList: [],

  taskDetailList: [],

  testList: [],
  userTestList: [],
  
  testDetailList: [],

  membershipList: [],
};

const slice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    // START LOADING TASKS
    startLoadingCurrentUserCurriculumItem(state, action: PayloadAction<{ membershipId: number; }>) {
      const { membershipId } = action.payload;
      if (membershipId in state.currentUserCurriculumItemList) {
        state.currentUserCurriculumItemList[membershipId] = {
          ...state.currentUserCurriculumItemList[membershipId],
          loading: true,
          // TODO: Also set tasks to null?
        };
      }
      else {
        state.currentUserCurriculumItemList = {
          ...state.currentUserCurriculumItemList, [membershipId]: {
            loading: true,
            userCurriculumItem: null,
          }
        };
      }
    },

    getCurrentUserCurriculumItemSuccess(state, action: PayloadAction<{ membershipId: number, userCurriculumItem: UserCurriculumItemInfo | null; }>) {
      const { membershipId, userCurriculumItem, } = action.payload;

      if (membershipId in state.currentUserCurriculumItemList) {
        state.currentUserCurriculumItemList[membershipId] = {
          ...state.currentUserCurriculumItemList[membershipId],
          loading: false,
          userCurriculumItem: userCurriculumItem,
        };
      }
      else {
        state.currentUserCurriculumItemList = {
          ...state.currentUserCurriculumItemList, [membershipId]: {
            loading: false,
            userCurriculumItem: userCurriculumItem,
          }
        };
      }
    },




    // START LOADING USER CURRICULUM ITEM
    startLoadingUserCurriculumItem(state, action: PayloadAction<{ membershipId: number; userCurriculumItemId: number; }>) {
      const { membershipId, userCurriculumItemId } = action.payload;

      if (membershipId in state.userCurriculumItemList) {
        if (userCurriculumItemId in state.userCurriculumItemList[membershipId]) {
          state.userCurriculumItemList[membershipId][userCurriculumItemId] = {
            ...state.userCurriculumItemList[membershipId][userCurriculumItemId],
            loading: true,
          };
        }
        else {
          state.userCurriculumItemList[membershipId] = {
            ...state.userCurriculumItemList[membershipId],
            [userCurriculumItemId]: {
              loading: true,
              userCurriculumItem: null,
            }
          };
        }
      }
      else {
        state.userCurriculumItemList = {
          ...state.userCurriculumItemList,
          [membershipId]: {
            [userCurriculumItemId]: {
              loading: true,
              userCurriculumItem: null,
            }
          },
        };
      }
    },

    // GET TASK DETAIL
    getUserCurriculumItemSuccess(state, action: PayloadAction<{ membershipId: number, userCurriculumItemId: number, userCurriculumItem: UserCurriculumItemInfo | null; }>) {
      const { membershipId, userCurriculumItemId, userCurriculumItem } = action.payload;

      if (membershipId in state.userCurriculumItemList) {
        if (userCurriculumItemId in state.userCurriculumItemList[membershipId]) {
          state.userCurriculumItemList[membershipId][userCurriculumItemId] = {
            ...state.userCurriculumItemList[membershipId][userCurriculumItemId],
            loading: false,
            userCurriculumItem: userCurriculumItem,
          };
        }
        else {
          state.userCurriculumItemList[membershipId] = {
            ...state.userCurriculumItemList[membershipId],
            [userCurriculumItemId]: {
              loading: false,
              userCurriculumItem: userCurriculumItem,
            }
          };
        }
      }
      else {
        state.userCurriculumItemList = {
          ...state.userCurriculumItemList,
          [membershipId]: {
            [userCurriculumItemId]: {
              loading: false,
              userCurriculumItem: userCurriculumItem,
            }
          },
        };
      }
    },


    // START LOADING TASKS
    startLoadingTasks(state, action: PayloadAction<{ membershipId: number; }>) {
      if (action.payload.membershipId in state.taskList) {
        state.taskList[action.payload.membershipId] = {
          ...state.taskList[action.payload.membershipId],
          loading: true,
          // TODO: Also set tasks to null?
        };
      }
      else {
        state.taskList = {
          ...state.taskList, [action.payload.membershipId]: {
            loading: true,
            tasks: null,
          }
        };
      }
    },

    // GET SELF USER
    getTasksSuccess(state, action: PayloadAction<{ membershipId: number, tasks: UserTaskList[] | null; }>) {
      // state.loadingTasks = false;
      // state.tasks = action.payload.tasks;
      if (action.payload.membershipId in state.taskList) {
        state.taskList[action.payload.membershipId] = {
          ...state.taskList[action.payload.membershipId],
          loading: false,
          tasks: action.payload.tasks,
        };
      }
      else {
        state.taskList = {
          ...state.taskList, [action.payload.membershipId]: {
            loading: false,
            tasks: action.payload.tasks,
          }
        };
      }
    },



    // START LOADING TASKS
    startLoadingUserTasks(state, action: PayloadAction<{ membershipId: number; userCurriculumItemId: number; }>) {
      const { membershipId, userCurriculumItemId } = action.payload;

      if (membershipId in state.userTaskList) {
        if (userCurriculumItemId in state.userTaskList[membershipId]) {
          state.userTaskList[membershipId][userCurriculumItemId] = {
            ...state.userTaskList[membershipId][userCurriculumItemId],
            loading: true,
          };
        }
        else {
          state.userTaskList[membershipId] = {
            ...state.userTaskList[membershipId],
            [userCurriculumItemId]: {
              loading: true,
              tasks: null,
            }
          };
        }
      }
      else {
        state.userTaskList = {
          ...state.userTaskList,
          [membershipId]: {
            [userCurriculumItemId]: {
              loading: true,
              tasks: null,
            }
          },
        };
      }
    },
    getUserTasksSuccess(state, action: PayloadAction<{ membershipId: number, userCurriculumItemId: number, tasks: UserTaskList[] | null; }>) {
      const { membershipId, userCurriculumItemId, tasks } = action.payload;

      if (membershipId in state.userTaskList) {
        if (userCurriculumItemId in state.userTaskList[membershipId]) {
          state.userTaskList[membershipId][userCurriculumItemId] = {
            ...state.userTaskList[membershipId][userCurriculumItemId],
            loading: false,
            tasks: tasks,
          };
        }
        else {
          state.userTaskList[membershipId] = {
            ...state.userTaskList[membershipId],
            [userCurriculumItemId]: {
              loading: false,
              tasks: tasks,
            }
          };
        }
      }
      else {
        state.userTaskList = {
          ...state.userTaskList,
          [membershipId]: {
            [userCurriculumItemId]: {
              loading: false,
              tasks: tasks,
            }
          },
        };
      }
    },


    // START LOADING TASK DETAIL
    startLoadingTaskDetail(state, action: PayloadAction<{ membershipId: number; taskId: number; }>) {
      const { membershipId, taskId } = action.payload;

      if (membershipId in state.taskDetailList) {
        if (taskId in state.taskDetailList[membershipId]) {
          state.taskDetailList[membershipId][taskId] = {
            ...state.taskDetailList[membershipId][taskId],
            loading: true,
          };
        }
        else {
          state.taskDetailList[membershipId] = {
            ...state.taskDetailList[membershipId],
            [taskId]: {
              loading: true,
              task: null,
            }
          };
        }
      }
      else {
        state.taskDetailList = {
          ...state.taskDetailList,
          [membershipId]: {
            [taskId]: {
              loading: true,
              task: null,
            }
          },
        };
      }
    },

    // GET TASK DETAIL
    getTaskDetailSuccess(state, action: PayloadAction<{ membershipId: number, taskId: number, task: UserCurriculumItemTaskFullDetail | null; }>) {
      const { membershipId, taskId, task } = action.payload;

      if (membershipId in state.taskDetailList) {
        if (taskId in state.taskDetailList[membershipId]) {
          state.taskDetailList[membershipId][taskId] = {
            ...state.taskDetailList[membershipId][taskId],
            loading: false,
            task: task,
          };
        }
        else {
          state.taskDetailList[membershipId] = {
            ...state.taskDetailList[membershipId],
            [taskId]: {
              loading: false,
              task: task,
            }
          };
        }
      }
      else {
        state.taskDetailList = {
          ...state.taskDetailList,
          [membershipId]: {
            [taskId]: {
              loading: false,
              task: task,
            }
          },
        };
      }
    },



    // START LOADING TESTS
    startLoadingTests(state, action: PayloadAction<{ membershipId: number; }>) {
      const { membershipId } = action.payload;

      if (membershipId in state.taskList) {
        state.testList[membershipId] = {
          ...state.testList[membershipId],
          loading: true,
          // TODO: Also set tests to null?
        };
      }
      else {
        state.testList = {
          ...state.testList, [membershipId]: {
            loading: true,
            tests: null,
          }
        };
      }
    },

    // GET TESTS
    getTestsSuccess(state, action: PayloadAction<{ membershipId: number, tests: UserCurriculumItemTestList[] | null; }>) {
      const { membershipId, tests } = action.payload;
      if (membershipId in state.testList) {
        state.testList[membershipId] = {
          ...state.testList[membershipId],
          loading: false,
          tests: tests,
        };
      }
      else {
        state.testList = {
          ...state.testList, [membershipId]: {
            loading: false,
            tests: tests,
          }
        };
      }
    },



    // START LOADING TASKS
    startLoadingUserTests(state, action: PayloadAction<{ membershipId: number; userCurriculumItemId: number; }>) {
      const { membershipId, userCurriculumItemId } = action.payload;

      if (membershipId in state.userTestList) {
        if (userCurriculumItemId in state.userTestList[membershipId]) {
          state.userTestList[membershipId][userCurriculumItemId] = {
            ...state.userTestList[membershipId][userCurriculumItemId],
            loading: true,
          };
        }
        else {
          state.userTestList[membershipId] = {
            ...state.userTestList[membershipId],
            [userCurriculumItemId]: {
              loading: true,
              tests: null,
            }
          };
        }
      }
      else {
        state.userTestList = {
          ...state.userTestList,
          [membershipId]: {
            [userCurriculumItemId]: {
              loading: true,
              tests: null,
            }
          },
        };
      }
    },
    getUserTestsSuccess(state, action: PayloadAction<{ membershipId: number, userCurriculumItemId: number, tests: UserCurriculumItemTestList[] | null; }>) {
      const { membershipId, userCurriculumItemId, tests } = action.payload;

      if (membershipId in state.userTaskList) {
        if (userCurriculumItemId in state.userTestList[membershipId]) {
          state.userTestList[membershipId][userCurriculumItemId] = {
            ...state.userTestList[membershipId][userCurriculumItemId],
            loading: false,
            tests: tests,
          };
        }
        else {
          state.userTestList[membershipId] = {
            ...state.userTestList[membershipId],
            [userCurriculumItemId]: {
              loading: false,
              tests: tests,
            }
          };
        }
      }
      else {
        state.userTestList = {
          ...state.userTestList,
          [membershipId]: {
            [userCurriculumItemId]: {
              loading: false,
              tests: tests,
            }
          },
        };
      }
    },




    // START LOADING TEST DETAIL
    startLoadingTestDetail(state, action: PayloadAction<{ membershipId: number; testId: number; }>) {
      const { membershipId, testId } = action.payload;

      if (membershipId in state.testDetailList) {
        if (testId in state.testDetailList[membershipId]) {
          state.testDetailList[membershipId][testId] = {
            ...state.testDetailList[membershipId][testId],
            loading: true,
          };
        }
        else {
          state.testDetailList[membershipId] = {
            ...state.testDetailList[membershipId],
            [testId]: {
              loading: true,
              test: null,
            }
          };
        }
      }
      else {
        state.testDetailList = {
          ...state.testDetailList,
          [membershipId]: {
            [testId]: {
              loading: true,
              test: null,
            }
          },
        };
      }
    },

    // GET TEST DETAIL
    getTestDetailSuccess(state, action: PayloadAction<{ membershipId: number, testId: number, test: UserCurriculumItemTestFullDetail | null; }>) {
      const { membershipId, testId, test } = action.payload;

      if (membershipId in state.testDetailList) {
        if (testId in state.testDetailList[membershipId]) {
          state.testDetailList[membershipId][testId] = {
            ...state.testDetailList[membershipId][testId],
            loading: false,
            test: test,
          };
        }
        else {
          state.testDetailList[membershipId] = {
            ...state.testDetailList[membershipId],
            [testId]: {
              loading: false,
              test: test,
            }
          };
        }
      }
      else {
        state.testDetailList = {
          ...state.testDetailList,
          [membershipId]: {
            [testId]: {
              loading: false,
              test: test,
            }
          },
        };
      }
    },



    startLoadingMembershipDetail(state, action: PayloadAction<{ membershipId: number; }>) {
      const { membershipId } = action.payload;

      if (membershipId in state.membershipList) {
        state.membershipList[membershipId] = {
          ...state.membershipList[membershipId],
          loading: true,
          // TODO: Also set tests to null?
        };
      }
      else {
        state.membershipList = {
          ...state.membershipList, [membershipId]: {
            loading: true,
            membership: null,
          }
        };
      }
    },

    getMembershipDetailSuccess(state, action: PayloadAction<{ membershipId: number, membership: MembershipDetail | null; }>) {
      const { membershipId, membership } = action.payload;
      if (membershipId in state.membershipList) {
        state.membershipList[membershipId] = {
          ...state.membershipList[membershipId],
          loading: false,
          membership: membership
        };
      }
      else {
        state.membershipList = {
          ...state.membershipList, [membershipId]: {
            loading: false,
            membership: membership
          }
        };
      }
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoadingCurrentUserCurriculumItem,
  getCurrentUserCurriculumItemSuccess,

  startLoadingUserCurriculumItem,
  getUserCurriculumItemSuccess,

  startLoadingTasks,
  getTasksSuccess,

  startLoadingUserTasks,
  getUserTasksSuccess,

  startLoadingTaskDetail,
  getTaskDetailSuccess,


  startLoadingTests,
  getTestsSuccess,

  
  startLoadingUserTests,
  getUserTestsSuccess,

  startLoadingTestDetail,
  getTestDetailSuccess,

  startLoadingMembershipDetail,
  getMembershipDetailSuccess,
} = slice.actions;
