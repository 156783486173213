import { Button, IconButton, Tooltip } from '@mui/material';
import Iconify from '../../../../components/Iconify';
import usePlaygroundContext from '../../../../hooks/usePlaygroundContext';

export default function MuteMetronomeButton() {
  const { playSound, setPlaySound } = usePlaygroundContext();
  const handleClick = () => {
    setPlaySound(!playSound);
  };

  return (
    <>
      <Tooltip title={!playSound ? "Metronom sesi çal" : "Metronom sesi çalma"}>
        <IconButton
          onClick={handleClick}
          color={playSound ? "primary" : "default"}
        >
          {!playSound ? <Iconify icon="eva:volume-off-outline" /> : <Iconify icon="eva:volume-up-outline" />}
        </IconButton>
      </Tooltip>
    </>
  );
}
