import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Drawer, List } from '@mui/material';
import useResponsive from '../../../../hooks/useResponsive';
import useCollapseDrawer from '../../../../hooks/useCollapseDrawer';
import cssStyles from '../../../../utils/cssStyles';
import { NAVBAR } from '../../../../config';
import Logo from '../../../../components/Logo';
import Scrollbar from '../../../../components/Scrollbar';
import NavbarAccount from './NavbarAccount';
import CollapseButton from './CollapseButton';
import useAuth from '../../../../hooks/useAuth';
import useNumberSearchParam from '../../../../hooks/useNumberSearchParam';
import useTasks from '../../../../hooks/student/useTasks';
import { useTranslation } from 'react-i18next';
import { PlayNavListRoot } from '../../../../components/playground-nav-section/vertical/NavList';
import { ListSubheaderStyle } from '../../../../components/playground-nav-section/vertical';
import { Linq } from '../../../../utils/linq';
import { ExtraPropertyKeys } from '../../../../contract/constants/ExtraPropertyKeys';
import Iconify from '../../../../components/Iconify';
import usePlaygroundContext from '../../../../hooks/usePlaygroundContext';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import useTests from '../../../../hooks/student/useTests';
import useCurrentUserCurriculumItem from '../../../../hooks/student/useCurrentUserCurriculumItem';
import useUserCurriculumItem from '../../../../hooks/student/useUserCurriculumItem';
import useBooleanSearchParam from '../../../../hooks/useBooleanSearchParam';
import LogoIcon from '../../../../components/LogoIcon';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }: { isOpenSidebar: boolean; onCloseSidebar: () => void; }) {
  const theme = useTheme();

  const { active, currentTaskId, setCurrentTaskId, currentTestId, setCurrentTestId, } = usePlaygroundContext();

  const { user } = useAuth();

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    // console.log('Close the damn thing??');
    // if (isOpenSidebar) {
    // onToggleCollapse();
    // }
  }, []);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const { t } = useTranslation("navigation");

  const membershipId = useNumberSearchParam('mid');
  const userCurriculumItemId = useNumberSearchParam('cid');
  const rep = useBooleanSearchParam('rep');


  // const { loading: loadingCurrentUserCurriculumItem, userCurriculumItem } = useCurrentUserCurriculumItem({
  //   load: !!membershipId,
  //   membershipId: membershipId || 0
  // });
  const { loading: loadingCurrentUserCurriculumItem, userCurriculumItem } = useUserCurriculumItem({
    load: false,
    membershipId: membershipId || 0,
    userCurriculumItemId: userCurriculumItemId || undefined,
  });


  const { loading, tasks } = useTasks({
    load: false,
    membershipId: membershipId || 0,
    itemId: userCurriculumItem?.id || 0,
    rep: rep,
  });
  const tasksSorted = useMemo(() => tasks ? Linq.groupBySorted(tasks, t => t.task.customExercise.category.name) : null, [tasks]);

  const { loading: loadingTests, tests } = useTests({
    load: false,
    membershipId: membershipId || 0,
    itemId: userCurriculumItem?.id || 0,
  });

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {isCollapse && (
            <LogoIcon />
          )}

          {!isCollapse && (
            <Logo
              sx={{
                width: {
                  'xs': 80,
                  'sm': 90,
                  'md': 100,
                  'lg': 110,
                  'xl': 120
                }
              }}
              disabledLink={false} linkTo={PATH_DASHBOARD.root} />
          )}

          {isDesktop && !isCollapse && (
            <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
          )}
        </Stack>
      </Stack>


      <Box>
        <List key={'today'} disablePadding sx={{ px: 2 }}>
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {/* {t(`subheaders.${'today'}` as Parameters<typeof t>[0])} */}
            {userCurriculumItem && `${userCurriculumItem.curriculumItem.name} - Seviye ${userCurriculumItem.curriculumItem.level}`}
            {!userCurriculumItem && t(`subheaders.${'today'}` as Parameters<typeof t>[0])}
          </ListSubheaderStyle>

          {!!tasksSorted && !!tasksSorted?.length && tasksSorted?.map((taskGroup, i) => (
            <PlayNavListRoot
              key={i}
              previousCount={tasksSorted.slice(0, i).map(x => x.value.length).reduce((a, b) => a + b, 0) || 0}
              isCollapse={isCollapse}
              activeColor={taskGroup.value[0].task.customExercise.category.extraProperties.find(x => x.key === ExtraPropertyKeys.Category.Exercise.Color)?.value}
              // onClick={(e) => { e.preventDefault(); return false; }}
              list={{
                path: '#',
                // title: task.task.customExercise.name,
                // caption: task.task.customExercise.categoryId.toString(),

                title: taskGroup.key.toString(),

                // TODO: Disabled doesn't work??
                // disabled: !taskGroup.value.some(x => x.id === currentTaskId),
                icon: <Iconify
                  color={taskGroup.value[0].task.customExercise.category.extraProperties.find(x => x.key === ExtraPropertyKeys.Category.Exercise.Color)?.value}
                  icon={taskGroup.value[0].task.customExercise.category.extraProperties.find(x => x.key === ExtraPropertyKeys.Category.Exercise.Icon)?.value}
                />,
                isActive: tasks?.some(x => x.id === currentTaskId || x.completed), // TODO: Only active when task mode (useBoard)
                disabled: !tasks?.some(x => !x.completed),
                children: taskGroup.value.map((task, j) => ({
                  title: task.task.customExercise.name,
                  // icon: task.task.customExercise.category.extraProperties.find(x => x.key === ExtraPropertyKeys.Category.Exercise.Icon)?.value,
                  activeColor: task.task.customExercise.category.extraProperties.find(x => x.key === ExtraPropertyKeys.Category.Exercise.Color)?.value,
                  path: '#',
                  onClick: (e) => {
                    e.preventDefault();

                    // setCurrentTaskId(task.id);

                    return false;
                  },
                  isActive: task.id === currentTaskId || task.completed,

                  disabled: task.id !== currentTaskId,
                  isCompleted: task.completed,
                })),
              }}
            />
          ))}

          {!!tests && !!tests?.length && (
            <PlayNavListRoot
              isCollapse={isCollapse}
              previousCount={0}
              list={{
                path: '#',
                title: 'Gün Sonu Testleri',

                // TODO: Disabled doesn't work??
                // disabled: !taskGroup.value.some(x => x.id === currentTaskId),
                icon: <Iconify icon="bx:test-tube" />,
                isActive: tests.some(x => x.id === currentTestId || x.completed), // TODO: Only active when task mode (useBoard)
                disabled: !tests?.some(x => !x.completed),
                children: tests.map((test, j) => ({
                  title: test.curriculumItemTest.test.title,
                  // activeColor: task.task.customExercise.category.extraProperties.find(x => x.key === ExtraPropertyKeys.Category.Exercise.Color)?.value,
                  path: '#',
                  onClick: (e) => {
                    e.preventDefault();

                    // setCurrentTestId(test.id);

                    return false;
                  },
                  isActive: test.id === currentTestId || test.completed,

                  disabled: test.id !== currentTestId,
                  isCompleted: test.completed,
                })),
              }}
            />)}
        </List>
      </Box>

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.PLAYGROUND_COLLAPSE_WIDTH : NAVBAR.PLAYGROUND_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.PLAYGROUND_WIDTH } }}>
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.PLAYGROUND_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.PLAYGROUND_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => (theme as any).customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
