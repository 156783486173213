import { Button } from '@mui/material';
import Iconify from '../../../../components/Iconify';
import usePlaygroundContext from '../../../../hooks/usePlaygroundContext';

export default function FilterButton() {
  const { showFilters, setShowFilters } = usePlaygroundContext();
  const handleClick = () => {
    setShowFilters(!showFilters);
  };

  return (
    <>
      <Button
        onClick={handleClick}
        sx={{
        }}
        startIcon={
          <Iconify icon="charm:filter" />
        }
        variant="contained"
        color="primary"
      // color="text.primary"
      >
        {!showFilters && "Filtreleri Göster"}
        {!!showFilters && "Filteleri Gizle"}
      </Button>
    </>
  );
}
