import { Box, IconButtonProps } from '@mui/material';
import { m, Variant, Variants } from 'framer-motion';
import { forwardRef, PropsWithChildren, useState } from 'react';
import Label, { LabelProps } from './Label';

type LabelAnimateProps = PropsWithChildren<LabelProps & {
  autoShake?: boolean;
}>;
const LabelAnimate = forwardRef<HTMLButtonElement, LabelAnimateProps>(({ children, autoShake, ...other }, ref) => (
  <AnimateWrap autoShake={autoShake}>
    <Label
      // size={size}
      ref={ref}
      {...other}
    >
      {children}
    </Label>
  </AnimateWrap>
));

export default LabelAnimate;

// ----------------------------------------------------------------------

// const getRandomDelay = () => -(Math.random() * 0.7 + 0.05);

// const randomDuration = () => Math.random() * 0.07 + 1;

const scaleMin = 0.95;
const scaleMax = 1.05;
const rotateMin = -3;
const rotateMax = 3;


const smin = scaleMin;
const smax = scaleMax;
const rmin = rotateMin;
const rmax = rotateMax;

const scaleOrder = [smax, smin, smax, smin, smax, smin, 1];
const rtateOrder = [0, rmax, rmin, rmax, rmin, rmax, rmin, rmax, rmin, 0];

const dur = 0.7;
const repeatDelay = 2;

const shakeVariant: Variant = {
  // scale: scaleOrder,
  rotate: rtateOrder,
  transition: {
    delay: -1,
    repeat: Infinity,
    duration: dur,
    repeatDelay: repeatDelay,
  }
};

const unShakeVariant: Variant = {
  rotate: 0,
};

const varMedium: Variants = {
  // hover: { scale: 1.09 },
  // tap: { scale: 0.97 },
  shake: shakeVariant,
  unShake: unShakeVariant,
};


function AnimateWrap({ children, autoShake }: PropsWithChildren<{ autoShake?: boolean; }>) {
  return (
    <Box
      component={m.div}
      // whileTap="tap"
      // whileHover="hover"
      whileInView={autoShake ? "shake" : 'unshake'}
      variants={varMedium}
      sx={{
        display: 'inline-flex'
      }}
    >
      {children}
    </Box>
  );
}