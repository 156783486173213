import { useEffect } from 'react';
import { getTasksSuccess, getUserTasksSuccess, startLoadingTasks, startLoadingUserTasks } from '../../redux/slices/student';
import { useDispatch, useSelector } from '../../redux/store';
import { getMembershipUserTasks } from '../../services/lms/membership/userTask/getMembershipUserTasks';
import { useStatelesFetch } from '../useFetch';

export default function useTasks(options: {
  load: boolean;
  membershipId: number;
  itemId: number;
  rep: boolean | null;
}) {
  const { load, membershipId, itemId } = options;
  const { fetch } = useStatelesFetch({
    fetcher: getMembershipUserTasks
  }, membershipId, itemId);

  const currentTaskList = useSelector(x => x.student.taskList);
  const userTaskList = useSelector(x => x.student.userTaskList);
  const dispatch = useDispatch();

  useEffect(() => {
    doFetch();
  }, [load, membershipId, itemId]);

  async function doFetch() {
    if (!load) {
      return;
    }

    if (itemId) {
      if (membershipId in userTaskList &&
        itemId in userTaskList[membershipId] &&
        userTaskList[membershipId][itemId].loading) {
        return;
      }

      // TODO: We might want to force reload this data???
      if (membershipId in userTaskList &&
        itemId in userTaskList[membershipId] &&
        !!userTaskList[membershipId][itemId].tasks) {
        return;
      }

      dispatch(startLoadingUserTasks({
        membershipId: membershipId,
        userCurriculumItemId: itemId,
      }));

      let response = await fetch();
      if (response?.success) {
        dispatch(getUserTasksSuccess({
          membershipId: membershipId,
          userCurriculumItemId: itemId,
          tasks: response.datas.map(x => ({
            ...x,
            completed: options.rep === true ? false : x.completed,
          })),
        }));
      }
    }
    else {
      if (membershipId in currentTaskList &&
        currentTaskList[membershipId].loading) {
        // console.log('Already loading tasks...');
        return;
      }

      if (membershipId in currentTaskList &&
        !!currentTaskList[membershipId].tasks) {
        // console.log('Already has tasks data...');
        return;
      }

      dispatch(startLoadingTasks({
        membershipId: membershipId,
      }));

      let response = await fetch();
      if (response?.success) {
        dispatch(getTasksSuccess({
          membershipId: membershipId,
          tasks: response.datas.map(x => ({
            ...x,
            completed: options.rep === true ? false : x.completed,
          })),
        }));
      }
    }
  }

  // useEffect(() => {
  //   console.log('REP is', options.rep);
  // }, [options.rep]);

  return !itemId ? {
    ...currentTaskList[membershipId]
  } : {
    ...(userTaskList[membershipId] ? userTaskList[membershipId][itemId] : {})
  };
}