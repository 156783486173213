import { Box, Link, ListItemText } from '@mui/material';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink } from 'react-router-dom';
import { isExternalLink } from '..';
import { NavConfigGroupItem } from '../../../layouts/dashboard/navbar/NavConfig';
import Iconify from '../../Iconify';
import { ListItemIconStyle, ListItemStyle, ListItemTextStyle } from './style';


type NavItemRootProps = {
  item: NavConfigGroupItem;
  isCollapse: boolean;
  open?: boolean;
  active: boolean;
  important?: boolean;
  onOpen?: () => void;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export function NavItemRoot({
  item,
  isCollapse,
  open = false,
  active,
  important,
  onOpen,
  onClick,
}: NavItemRootProps) {
  const { title, path, icon, info, children } = item;
  const { t } = useTranslation('navigation');

  const renderContent = (
    <>
      {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}
      <ListItemTextStyle
        disableTypography
        primary={t(`itemTitles.${title}.title` as Parameters<typeof t>[0])}
        onClick={onClick}
        // @ts-ignore
        isCollapse={isCollapse}
      />
      {!isCollapse && (
        <>
          {info && info}
          {children && <ArrowIcon open={open} />}
        </>
      )}
    </>
  );

  if (children) {
    return (
      <ListItemStyle
        onClick={onOpen}
        // @ts-ignore
        activeRoot={active}
        important={important}
      >
        {renderContent}
      </ListItemStyle>
    );
  }

  return isExternalLink(path) ? (
    <ListItemStyle
      // @ts-ignore
      component={Link}
      href={path}
      target="_blank"
      rel="noopener"
      onClick={onClick}
      important={important}
    >
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle
      // @ts-ignore
      component={RouterLink}
      to={path}
      activeRoot={active}
      onClick={onClick}
      important={important}
    >
      {renderContent}
    </ListItemStyle>
  );
}


type NavItemSubProps = {
  parent: NavConfigGroupItem;
  item: NavConfigGroupItem;
  open?: boolean;
  active?: boolean;
  important?: boolean;
  onOpen?: () => void;
};

export function NavItemSub({
  parent,
  item,
  open = false,
  active = false,
  important,
  onOpen
}: NavItemSubProps) {
  const { title, path, info, children } = item;
  const { t } = useTranslation('navigation');

  const renderContent = (
    <>
      <DotIcon active={active} />
      <ListItemText disableTypography primary={t(`itemTitles.${parent.title}.${title}` as Parameters<typeof t>[0])} />
      {info && info}
      {children && <ArrowIcon open={open} />}
    </>
  );

  if (children) {
    return (
      <ListItemStyle
        onClick={onOpen}

        // @ts-ignore
        activeSub={active}
        important={important}

        subItem>
        {renderContent}
      </ListItemStyle>
    );
  }

  return isExternalLink(path) ? (
    <ListItemStyle
      // @ts-ignore
      component={Link}
      href={path}
      target="_blank"
      rel="noopener"
      subItem
      important={important}
    >
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle
      // @ts-ignore
      component={RouterLink}
      to={path}
      activeSub={active}
      subItem
      important={important}
    >
      {renderContent}
    </ListItemStyle>
  );
}


type DotIconProps = {
  active: boolean;
};

export function DotIcon({ active }: DotIconProps) {
  return (
    <ListItemIconStyle>
      <Box
        component="span"
        sx={{
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'text.disabled',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: 'scale(2)',
            bgcolor: 'primary.main',
          }),
        }}
      />
    </ListItemIconStyle>
  );
}


type ArrowIconProps = {
  open?: boolean;
};

export function ArrowIcon({ open }: ArrowIconProps) {
  return (
    <Iconify
      icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
      sx={{ width: 16, height: 16, ml: 1 }}
    />
  );
}
