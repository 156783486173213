import { useTranslation } from 'react-i18next';
import { enUS, trTR } from '@mui/material/locale';

const LANGS = [
  {
    label: 'Türkçe',
    value: 'tr',
    systemValue: trTR,
    icon: 'tr',
  },
  // {
  //   label: 'English',
  //   value: 'en',
  //   systemValue: enUS,
  //   icon: 'gb',
  // },
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[0];

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS,
  };
}
