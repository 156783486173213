import MenuPopover from '../../../../components/MenuPopover';
import MyAvatar from '../../../../components/MyAvatar';
import useAuth from '../../../../hooks/useAuth';
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
import { alpha } from '@mui/material/styles';
import {
  Box,
  Divider,
  MenuItem,
  Stack,
  Typography
} from '@mui/material';
import { IconButtonAnimate } from '../../../../components/animate';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { PATH_AUTH, PATH_DASHBOARD } from '../../../../routes/paths';
import { useSnackbar } from 'notistack';
import { MouseEventHandler, useState } from 'react';
import { roleText } from '../../../../contract/constants/Roles';
import { useDispatch } from '../../../../redux/store';
import { clearSelfUser } from '../../../../redux/slices/user/user';

const MENU_OPTIONS = [
  {
    label: 'Ana Sayfa',
    linkTo: '/',
  },
  {
    label: 'Profil',
    linkTo: PATH_DASHBOARD.user.profile,
  },
  {
    label: 'Ayarlar',
    linkTo: PATH_DASHBOARD.user.settings,
  },
];

export default function AccountPopover() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, logout } = useAuth();

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState<Element | null>(null);

  const handleOpen: MouseEventHandler<HTMLButtonElement> = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      dispatch(clearSelfUser());
      navigate(PATH_AUTH.login, { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar sx={{}} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.given_name} {user?.family_name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {!!user?.role && roleText(user.role)}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Çıkış Yap
        </MenuItem>
      </MenuPopover>
    </>
  );
}
