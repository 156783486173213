import { Controller, useFormContext } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';

export default function RHFTextField({ name, ...other }: { name: string; } & TextFieldProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field}, fieldState: { error } }) => (
        <TextField
          {...field}
          inputRef={ref}
          fullWidth
          error={!!error}
          {...other}
          helperText={error?.message || other.helperText}
          value={(field.value === undefined || field.value === null) ? '' : field.value}
        />
      )}
    />
  );
}