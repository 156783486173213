import { FailApiResponse, SuccessApiResponse } from '../../../contract/base/Response';
import { createPhoneNumberVerificationToken } from '../../../services/identity/user/createPhoneNumberVerificationToken';
import useFetch from '../../useFetch';

export function useCreatePhoneNumberVerificationToken(
  successCallback?: (response: SuccessApiResponse) => void,
  errorCallback?: (response: FailApiResponse) => void,) {
  const { loading, response, fetch } = useFetch({
    fetcher: createPhoneNumberVerificationToken
  });

  const ftch = async (request: Parameters<typeof createPhoneNumberVerificationToken>[0]) => {
    let resp = await fetch(request);
    if (resp?.success) {
      successCallback && successCallback(resp);
    }
    else if (resp) {
      errorCallback && errorCallback(resp);
    }
  }

  return {
    loading,
    createPhoneNumberVerificationToken: ftch
  };
}