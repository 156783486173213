import { Alert, Box, Button, Container, Stack } from '@mui/material';
import useSelfUser from '../hooks/identity/user/useSelfUser';
import { LoadingButton } from '@mui/lab';
import useNavigateSearch from '../hooks/useNavigateSearch';
import { PATH_AUTH, PATH_DASHBOARD } from '../routes/paths';
import useSettings from '../hooks/useSettings';
import { useCreateEmailAddressVerificationToken } from '../hooks/identity/user/useCreateEmailAddressVerificationToken';
import { useCreatePhoneNumberVerificationToken } from '../hooks/identity/user/useCreatePhoneNumberVerificationToken';

export default function UserAlert() {
  const { themeStretch } = useSettings();

  const { data: user, loading, forceReload } = useSelfUser({
    load: true,
  });

  const searchNavigate = useNavigateSearch();
  const { createEmailAddressVerificationToken, loading: isLoading, } = useCreateEmailAddressVerificationToken(() => {
    searchNavigate(PATH_AUTH.emailVerification, {
      ea: user!.email,
    });
  });
  const { createPhoneNumberVerificationToken, loading: isLoadingPhone, } = useCreatePhoneNumberVerificationToken(() => {
    searchNavigate(PATH_AUTH.emailVerification, {
      pn: user!.phoneNumber,
    });
  });

  const redirectToEmailVerification = () => {
    if (!user?.email) {
      return;
    }

    searchNavigate(PATH_AUTH.emailVerification, {
      ea: user.email,
    });
  };

  const redirectToPhoneVerification = () => {
    if (!user?.phoneNumber) {
      return;
    }

    searchNavigate(PATH_AUTH.emailVerification, {
      pn: user.phoneNumber,
    });
  };

  return (
    <>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Stack spacing={2} sx={{ mx: 3, my: 2, }}>
          {!!user?.email && !user?.emailConfirmed && (
            // <Grid item xs={12} md={12}>
            <Alert severity="warning"
              action={
                <>
                  <Box sx={{
                    flexGrow: 1,
                    flexShrink: 0,
                    mr: 2,
                    alignSelf: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}>
                    <Button
                      color="inherit"
                      size="small"
                      sx={{
                        flexGrow: 1,
                        flexShrink: 0,
                        // alignSelf: 'center',
                      }}
                      onClick={redirectToEmailVerification}
                    >
                      Doğrula
                    </Button>
                    <LoadingButton
                      loading={isLoading}
                      color="inherit"
                      size="small"
                      sx={{
                        flexGrow: 1,
                        flexShrink: 0,
                        // alignSelf: 'center',
                      }}
                      onClick={() => !!user?.email && createEmailAddressVerificationToken({
                        emailAddress: user?.email || '',
                      })}>Tekrar kod gönder</LoadingButton>
                  </Box>
                </>
              }>
              E-posta adresiniz onaylanmamış ({user?.email}). Bu durum bazı işlemleri yapmanıza engel olabilir.

            </Alert>
            // </Grid>
          )}

          {!!user?.phoneNumber && !user?.phoneNumberConfirmed && (
            // <Grid item xs={12} md={12}>
            <Alert severity="warning"
              action={
                <>

                  <Box sx={{
                    flexGrow: 1,
                    flexShrink: 0,
                    mr: 2,
                    alignSelf: 'center',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}>
                    <Button
                      color="inherit"
                      size="small"
                      sx={{
                        flexGrow: 1,
                        flexShrink: 0,
                        // alignSelf: 'center',
                      }}
                      onClick={redirectToPhoneVerification}
                    >
                      Doğrula
                    </Button>

                    <LoadingButton
                      loading={isLoadingPhone}
                      color="inherit"
                      size="small"
                      sx={{
                        flexGrow: 1,
                        flexShrink: 0,
                        // alignSelf: 'center',
                      }}
                      onClick={() => !!user?.phoneNumber && createPhoneNumberVerificationToken({
                        phoneNumber: user?.phoneNumber || '',
                      })}>Tekrar kod gönder</LoadingButton>
                  </Box>
                </>
              }>
              Telefon numaranız onaylanmamış ({user?.phoneNumber}). Bu durum bazı işlemleri yapmanıza engel olabilir.
            </Alert>
            // </Grid>
          )}
        </Stack>
      </Container>
    </>
  );
}
