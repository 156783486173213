import { Collapse, List } from '@mui/material';
import { MouseEventHandler, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getActive } from '..';
import { PlaygroundNavConfigGroupItem } from './Config';
import { PlaygroundNavItemRoot, PlaygroundNavItemSub } from './NavItem';

type PlaygroundNavListRootProps = {
  activeColor?: string;
  list: PlaygroundNavConfigGroupItem;
  previousCount: number;
  isCollapse: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export function PlayNavListRoot({
  activeColor,
  list,
  previousCount,
  isCollapse,
  onClick, }: PlaygroundNavListRootProps) {
  const { pathname } = useLocation();

  // const active = getActive(list.path, pathname);
  const active = getActive(list);

  const [open, setOpen] = useState(active);

  useEffect(() => {
    setOpen(active);
  }, [active]);

  const hasChildren = list.children;

  // useEffect(() => {
  //   console.log('Previous count for me', {
  //     t: list.title,
  //     previousCount,
  //   })
  // }, [previousCount]);

  // useEffect(() => {
  //   console.log('This childs', list.children?.length);
  //   console.log('previous counts', (list.children || []).map((item, i) => ({
  //     previousCount: list.children?.slice(0, i).map(x => (('children' in x) ? x.children?.length : 0) || 0).reduce((a, b) => a + b, 0) || 0
  //   })));
  // }, [list]);

  if (hasChildren) {
    return (
      <>
        <PlaygroundNavItemRoot
          item={list}
          isCollapse={isCollapse}
          active={active}
          activeColor={activeColor}
          open={open}
          onOpen={() => setOpen(!open)}
          onClick={onClick}
        />

        {!isCollapse && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {(list.children || []).map((item, i) => (
                <NavListSub
                  key={i}
                  // previousCount={list.children?.slice(0, i).map(x => (('children' in x) ? x.children?.length : 0) || 0).reduce((a, b) => a + b, 0) || 0}
                  previousCount={previousCount + i}
                  parent={list}
                  list={item}
                />
              ))}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return <PlaygroundNavItemRoot item={list} active={active} isCollapse={isCollapse} onClick={onClick} />;
}


type NavListSubProps = {
  parent: PlaygroundNavConfigGroupItem;
  list: PlaygroundNavConfigGroupItem;
  previousCount: number;
};

function NavListSub({ parent, list, previousCount, }: NavListSubProps) {
  const { pathname } = useLocation();

  // const active = getActive(list.path, pathname);
  const active = getActive(list);

  const [open, setOpen] = useState(active);

  const hasChildren = list.children;

  // useEffect(() => {
  //   console.log('Me prev count ist', {
  //     t: list.title,
  //     previousCount
  //   });
  // }, [previousCount]);

  if (hasChildren) {
    return (
      <>
        <PlaygroundNavItemSub
          parent={parent}
          order={0}
          item={list}
          onOpen={() => setOpen(!open)} open={open} active={active} />

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 3 }}>
            {(list.children || []).map((item, i) => (
              <PlaygroundNavItemSub
                key={item.title}
                order={previousCount + i}
                parent={list}
                item={item}
                // active={getActive(item.path, pathname)}
                active={getActive(item)}
              />
            ))}
          </List>
        </Collapse>
      </>
    );
  }

  return <PlaygroundNavItemSub
    order={previousCount + 1}
    parent={parent}
    item={list}
    active={active}
  />;
}
