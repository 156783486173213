import jwtDecode from 'jwt-decode';
import { ClaimTypes } from '../contract/constants/ClaimTypes';
import axiosInstance from './axios';
// import { axiosPostResponseModel } from '../services/base/baseService';
// import axios from './axios';

export type Jwt = {
  // exp: number;

  nameid: string;
  given_name: string;
  family_name: string;
  [ClaimTypes.organizationId]: string;
  email: string;
  role: string;
  // [ClaimTypes.hasActiveMembership]: string;
  nbf: number;
  exp: number;
  iat: number;
};

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode<Jwt>(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handleTokenExpired = (
  exp: number,
  expireCallback?: () => void) => {
  let expiredTimer;

  // console.log('Going to clear timeout: ', expiredTimer);

  // console.log('Exp', exp);
  // console.log('Exp * 1000', exp * 1000);
  // console.log('Now', Date.now());

  // console.log('Expired...', exp);

  window.clearTimeout(expiredTimer);
  const currentTime = Date.now();
  const timeLeft = exp * 1000 - currentTime;
  // console.log(timeLeft);
  expiredTimer = window.setTimeout(() => {
    // console.log('expired');
    expireCallback && expireCallback();
    return;
    // window.location.reload();
    // TODO: Refresh token here...
    // console.log('expired');
    // You can do what ever you want here, like show a notification
  }, timeLeft);
};

const handleRefreshTokenExpired = (timeLeft: number) => {
  let expiredTimer2;

  window.clearTimeout(expiredTimer2);
  // console.log('Time left for refresh token', timeLeft);
  expiredTimer2 = window.setTimeout(() => {
    localStorage.removeItem('refreshToken');
    // console.log('expired');
    // You can do what ever you want here, like show a notification
  }, timeLeft);
};

const setRefreshToken = (refreshToken: string | null) => {
  if (refreshToken) {
    localStorage.setItem('refreshToken', refreshToken);

    const timeLeft = 2592000; // TODO: Should come from response
    handleRefreshTokenExpired(timeLeft);
  }
  else {
    localStorage.removeItem('refreshToken');
  }
};

const setSession = (
  accessToken: string | null,
  expireCallback?: () => void) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    // console.log('Axios should be set...');

    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode<Jwt>(accessToken);
    handleTokenExpired(exp, expireCallback);
  }
  else {
    localStorage.removeItem('accessToken');
    delete axiosInstance.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setRefreshToken, setSession };
