import { matchPath } from 'react-router-dom';
import { PlaygroundNavConfigGroupItem, PlaygroundNavItem } from './vertical/Config';

export { default as NavSectionVertical } from './vertical';

export function isExternalLink(path?: string) {
  return path?.includes('http');
}

// export function getActive(path: string | undefined, pathname: string) {
//   return path ? !!matchPath({ path, end: false }, pathname) : false;
// }

export function getActive(item: PlaygroundNavItem | PlaygroundNavConfigGroupItem) {
  if ('children' in item) {
    return item.children?.some(x => x.isActive || x.isCompleted) || false;
  }
  else {
    return item.isActive || false;
  }
}