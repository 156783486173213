import { Collapse, List } from '@mui/material';
import { MouseEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { getActive } from '..';
import { NavConfigGroupItem, NavItem } from '../../../layouts/dashboard/navbar/NavConfig';
import { NavItemRoot, NavItemSub } from './NavItem';

type NavListRootProps = {
  list: NavConfigGroupItem;
  isCollapse: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export function NavListRoot({ list, isCollapse, onClick, }: NavListRootProps) {
  const { pathname } = useLocation();

  const { t } = useTranslation("navigation");

  const hasChildren = list.children;

  const selfActive = !!list.active ? list.active(pathname) : getActive(list.path, pathname);
  const active = !!list.children ? (list.children.some(x => !!x.active ? x.active(pathname) : getActive(x.path, pathname))) : selfActive;

  const [open, setOpen] = useState(active);

  if (hasChildren) {
    return (
      <>
        <NavItemRoot item={list} isCollapse={isCollapse} active={active} important={list.important} open={open} onOpen={() => setOpen(!open)} onClick={onClick} />

        {!isCollapse && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {(list.children || []).map((item) => (
                <NavListSub key={item.title} parent={list} list={item} />
              ))}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return <NavItemRoot item={list} active={active} important={list.important} isCollapse={isCollapse} onClick={onClick} />;
}


type NavListSubProps = {
  parent: NavConfigGroupItem;
  list: NavConfigGroupItem;
};

function NavListSub({ parent, list }: NavListSubProps) {
  const { pathname } = useLocation();

  const active = !!list.active ? list.active(pathname) : getActive(list.path, pathname);

  const [open, setOpen] = useState(active);

  const hasChildren = list.children;

  if (hasChildren) {
    return (
      <>
        {/* TODO: Pass parent */}
        <NavItemSub parent={parent} item={list} important={list.important} onOpen={() => setOpen(!open)} open={open} active={active} />

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 3 }}>
            {(list.children || []).map((item) => (
              <NavItemSub key={item.title} important={list.important} parent={list} item={item} active={getActive(item.path, pathname)} />
            ))}
          </List>
        </Collapse>
      </>
    );
  }

  return <NavItemSub parent={parent} important={list.important} item={list} active={active} />;
}
