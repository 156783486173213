import useAuth from './useAuth';

export default function useHasRole() {
  const { user } = useAuth();

  const hasRole = (role: string) => {
    return user?.role === role;
  };

  const hasOneOfRoles = (...role: string[]) => {
    return !!user?.role && role.includes(user?.role);
  };

  return { hasRole, hasOneOfRoles };
};