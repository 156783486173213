import { Box, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import MyAvatar from '../../../components/MyAvatar';
import Roles, { roleText } from '../../../contract/constants/Roles';
import useSelfUser from '../../../hooks/identity/user/useSelfUser';
import useAuth from '../../../hooks/useAuth';
import useHasRole from '../../../hooks/useHasRole';
import { PATH_DASHBOARD } from '../../../routes/paths';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: (theme.palette.grey as any)[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

export default function NavbarAccount({ isCollapse }: { isCollapse: boolean; }) {
  const { user: cuser } = useAuth();
  const { data: user } = useSelfUser({ load: true });

  const { hasRole } = useHasRole();
  const isStudent = hasRole(Roles.Student);
  if (isStudent) {
    return null;
  }

  return (
    <Link underline="none" color="inherit" component={RouterLink} to={PATH_DASHBOARD.user.settings}>
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        <MyAvatar sx={{}} />

        <Box
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Typography variant="subtitle2" noWrap>
            {user?.firstName} {user?.lastName}
          </Typography>
          <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
            {!!user?.title && roleText(user.title)}
            {!!cuser?.role && roleText(cuser?.role)}
          </Typography>
        </Box>
      </RootStyle>
    </Link>
  );
}
