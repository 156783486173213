import { DataApiResponse } from '../../../../contract/base/Response';
import { UserCurriculumItemInfo } from '../../../../contract/lms/membership/UserCurriculumItem';
import axiosInstance from '../../../../utils/axios';
import { handleError } from '../../../base/baseService';

export async function getUserCurriculumItem(
  membershipId: number,
  id: number) {
  try {
    const response = await axiosInstance.get<DataApiResponse<UserCurriculumItemInfo>>(`/memberships/${membershipId}/items/${id}`);

    return response.data;
  }
  catch (error) {
    return handleError(error);
  }
}