import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function useBooleanSearchParam(id: string) {
  const [searchParams] = useSearchParams();
  const res = useMemo(() => {
    if (!searchParams.has(id)) {
      return null;
    }

    let strValue = searchParams.get(id);

    if (!strValue) {
      return null;
    }

    try {
      return Boolean(strValue);
    }
    catch {
      return null;
    }
  }, [searchParams]);

  return res;
}