import axios, { AxiosRequestConfig } from 'axios';
import { FailApiResponse } from '../../contract/base/Response';
import { FailResponseModel, ResponseModel } from '../../contract/base/ResponseModel';
// import axios, { AxiosError, AxiosRequestConfig } from 'axios';

// export async function fetchResponseModel<T>(
//   input: RequestInfo,
//   init?: RequestInit
// ): Promise<ResponseModel<T>> {
//   if (typeof input === 'string') {
//     if (!input.startsWith('/api')) {
//       input = '/api' + (input.startsWith('/') ? input : ('/' + input));
//     }
//   }

//   try {
//     let response = await fetch(input, init);

//     let result = await response.json() as ResponseModel<T>;

//     return result;
//   }
//   catch (err) {
//     console.log('Error is', err);
//     return {
//       isSuccess: false,
//       errorList: [
//         { code: -999999, name: 'CUSTOM_HTTP_ERROR', description: 'Bağlantı hatası', parameter: null }
//       ]
//     };
//   }
// }

async function handleAxiosOperation<T>(action: () => Promise<ResponseModel<T>>) {
  try {
    return await action();
  }
  catch (err) {
    if (axios.isAxiosError(err)) {
      return err.response?.data as FailResponseModel;
    } else {
      console.log('Error is', err);
      return {
        isSuccess: false,
        errorList: [
          { code: -999999, name: 'CUSTOM_HTTP_ERROR', description: 'Bağlantı hatası', parameter: null }
        ]
      } as FailResponseModel;
    }
  }
}

// export function handleError<TError>(err: TError): FailResponseModel {
//   if (axios.isAxiosError(err)) {
//     return err.response?.data as FailResponseModel;
//   } else {
//     if ('isSuccess' in err &&
//       'errorList' in err) {
//       return err as unknown  as FailResponseModel;
//     }
//     else {
//       console.log('Error is', err);
//       return {
//         isSuccess: false,
//         errorList: [
//           { code: -999999, name: 'CUSTOM_HTTP_ERROR', description: 'Bağlantı hatası', parameter: null }
//         ]
//       } as FailResponseModel;
//     }
//   }
// }

export async function axiosPostResponseModel<T>(url: string, data: unknown) {
  return await handleAxiosOperation(async () => {
    const response = await axios.post<ResponseModel<T>>(url, data);

    return response.data;
  });
}

export async function axiosGetResponseModel<T>(url: string, config?: AxiosRequestConfig<any>) {
  return await handleAxiosOperation(async () => {
    const response = await axios.get<ResponseModel<T>>(url, config);

    return response.data;
  });
}


export function handleError<TError>(err: TError): FailApiResponse {
  if (axios.isAxiosError(err)) {
    return err.response?.data as FailApiResponse;
  }
  else {
    if (typeof err === 'string') {
      console.log('Error is', err);
      return {
        success: false,
        errors: [
          { code: 'CUSTOM_HTTP_ERROR', message: err }
        ]
      } as FailApiResponse;
    }
    else if (typeof err === 'object' && !!err && 'success' in err &&
      'errors' in err) {
      return err as unknown as FailApiResponse;
    }
    else {
      console.log('Error is', err);
      return {
        success: false,
        errors: [
          { code: 'CUSTOM_HTTP_ERROR', message: 'Bağlantı hatası' }
        ]
      } as FailApiResponse;
    }
  }
}