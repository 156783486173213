import useSelfUser from '../../../hooks/identity/user/useSelfUser';
import useAuth from '../../../hooks/useAuth';
import { NavConfigGroup, NavConfigGroupItem, NavItem } from '../../../layouts/dashboard/navbar/NavConfig';

export function useFilteredNavItems(navItems: NavItem[]): NavItem[] {
  const { user, } = useAuth();
  const { data: currentUser, loading: loadingCurrentUser } = useSelfUser();
  const loading = !user || loadingCurrentUser || !currentUser;

  if (loading) {
    return [];
  }

  return navItems.filter(x =>
    (
      !x.customUserCheck ||
      x.customUserCheck(currentUser)
    ) &&
    (
      !x.roles ||
      !x.roles.length ||
      (user?.role && x.roles.includes(user.role))
    ));
}

export function useFilteredNavConfigGroupItems(navItems: NavConfigGroupItem[]): NavConfigGroupItem[] {
  const { user, } = useAuth();
  const { data: currentUser, loading: loadingCurrentUser } = useSelfUser();
  const loading = !user || loadingCurrentUser || !currentUser;

  if (loading) {
    return [];
  }

  return navItems.filter(x =>
    (
      !x.customUserCheck ||
      x.customUserCheck(currentUser)
    ) &&
    (
      !x.roles ||
      !x.roles.length ||
      (user?.role && x.roles.includes(user.role))
    ));
}

export function useFilteredNavConfigGroups(navItems: NavConfigGroup[]): NavConfigGroup[] {
  const { user, } = useAuth();
  const { data: currentUser, loading: loadingCurrentUser } = useSelfUser();
  const loading = !user || loadingCurrentUser || !currentUser;

  if (loading) {
    return [];
  }

  return navItems.filter(x =>
    (
      !x.customUserCheck ||
      x.customUserCheck(currentUser)
    ) &&
    (
      !x.roles ||
      !x.roles.length ||
      (user?.role && x.roles.includes(user.role))
    ));
}