import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { ApiResponse } from '../contract/base/Response';
import { ResponseModel } from '../contract/base/ResponseModel';

export default function useFetch<TResponse extends ApiResponse>(options: {
  fetcher: (...args: any[]) => Promise<TResponse>;
  successMessage?: string;
}, ...args: any[]) {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<TResponse | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  async function fetchData(...localArgs: any[]) {
    if (loading) {
      return;
    }

    setLoading(true);
    const argsUsed = (localArgs && localArgs.length && localArgs) || args;
    let resp = await options.fetcher(...argsUsed);
    setLoading(false);
    if (resp.success) {
      setResponse(resp);
      if (!!options.successMessage) {
        enqueueSnackbar(options.successMessage, {
          variant: "success",
        });
      }
    }
    else {
      resp.errors.forEach((err) =>
        enqueueSnackbar(err.message, {
          title: err.code,
          variant: "error",
        }));
    }

    return resp;
  }

  const { success, ...rest } = response ? response : { success: false };
  return {
    loading,
    response: response,
    data: response?.success ? rest : null,
    fetch: fetchData
  };
}


export function useStatelesFetch<TResponse extends ApiResponse>(options: {
  fetcher: (...args: any[]) => Promise<TResponse>;
  successMessage?: string;
}, ...args: any[]) {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  async function fetchData(...localArgs: any[]) {
    if (loading) {
      return;
    }

    setLoading(true);
    const argsUsed = (localArgs && localArgs.length && localArgs) || args;
    let resp = await options.fetcher(...argsUsed);
    setLoading(false);
    if (resp.success) {
      if (!!options.successMessage) {
        enqueueSnackbar(options.successMessage, {
          variant: "success",
        });
      }
    }
    else {
      resp.errors.forEach((err) =>
        enqueueSnackbar(err.message, {
          title: err.code,
          variant: "error",
        }));
    }

    return resp;
  }

  return {
    loading,
    fetch: fetchData
  };
}



export function useStatelesFetch2<TResponse extends ApiResponse>(options: {
  successMessage?: string;
}, ...args: any[]) {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  async function fetchData(fetcher: (...args: any[]) => Promise<TResponse>, ...localArgs: any[]) {
    if (loading) {
      return;
    }

    setLoading(true);
    const argsUsed = (localArgs && localArgs.length && localArgs) || args;
    let resp = await fetcher(...argsUsed);
    setLoading(false);
    if (resp.success) {
      if (!!options.successMessage) {
        enqueueSnackbar(options.successMessage, {
          variant: "success",
        });
      }
    }
    else {
      resp.errors.forEach((err) =>
        enqueueSnackbar(err.message, {
          title: err.code,
          variant: "error",
        }));
    }

    return resp;
  }

  return {
    loading,
    fetch: fetchData
  };
}