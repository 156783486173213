import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// import enLocales from './en.json';
import trLocales from './tr.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      tr: trLocales,
      // en: enLocales,
    },
    lng: localStorage.getItem('i18nextLng') || 'tr',
    fallbackLng: 'tr',
    debug: false,
    // ns: ['default'],
    defaultNS: 'default',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;