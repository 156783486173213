import { PlanFreeIcon, PlanStarterIcon, PlanPremiumIcon } from '../assets';

// ----------------------------------------------------------------------

const LICENSES = ['Standard', 'Standard Plus', 'Extended'];

export const _homePlans = [...Array(3)].map((_, index) => ({
  license: LICENSES[index],
  commons: ['One end products', '12 months updates', '6 months of support'],
  options: ['JavaScript version', 'TypeScript version', 'Design Resources', 'Commercial applications'],
  icons: [
    'https://minimal-assets-api-dev.vercel.app/assets/images/home/ic_sketch.svg',
    'https://minimal-assets-api-dev.vercel.app/assets/images/home/ic_figma.svg',
    'https://minimal-assets-api-dev.vercel.app/assets/images/home/ic_js.svg',
    'https://minimal-assets-api-dev.vercel.app/assets/images/home/ic_ts.svg',
  ],
}));

export const _pricingPlans = [
  {
    subscription: 'basic',
    icon: <PlanFreeIcon />,
    price: 0,
    caption: 'forever',
    lists: [
      { text: '3 prototypes', isAvailable: true },
      { text: '3 boards', isAvailable: true },
      { text: 'Up to 5 team members', isAvailable: false },
      { text: 'Advanced security', isAvailable: false },
      { text: 'Permissions & workflows', isAvailable: false },
    ],
    labelAction: 'current plan',
  },
  {
    subscription: 'starter',
    icon: <PlanStarterIcon />,
    price: 4.99,
    caption: 'saving $24 a year',
    lists: [
      { text: '3 prototypes', isAvailable: true },
      { text: '3 boards', isAvailable: true },
      { text: 'Up to 5 team members', isAvailable: true },
      { text: 'Advanced security', isAvailable: false },
      { text: 'Permissions & workflows', isAvailable: false },
    ],
    labelAction: 'choose starter',
  },
  {
    subscription: 'premium',
    icon: <PlanPremiumIcon />,
    price: 9.99,
    caption: 'saving $124 a year',
    lists: [
      { text: '3 prototypes', isAvailable: true },
      { text: '3 boards', isAvailable: true },
      { text: 'Up to 5 team members', isAvailable: true },
      { text: 'Advanced security', isAvailable: true },
      { text: 'Permissions & workflows', isAvailable: true },
    ],
    labelAction: 'choose premium',
  },
];

export const _packagePlans = [
  {
    subscription: 'BİR AYLIK',
    icon: <PlanFreeIcon />,
    // TODO
    // icon: <CalendarEmptyIcon />,
    price: 49,
    caption: 'Hemen Başla',
    lists: [
      { text: ['Eğitim günü: ', '24'], isAvailable: true },
      { text: ['Egzersiz sayısı: ', '72-144'], tooltip: 'Tekrar edebilir egzersiz sayısı', isAvailable: true },
      { text: ['Zaman ve Kategori Bazlı Rapor'], isAvailable: true },
      { text: ['Arkadaş Davet Kodu'], tooltip: 'Birlikte öğrenmeyi sevenler için', isAvailable: false },
      { text: ['Tek Çekim İndirimi'], tooltip: 'Ödeme ekranında görüntülenir', isAvailable: false },
    ],
    labelAction: 'SATIN AL',
  },
  {
    subscription: 'İKİ AYLIK',
    icon: <PlanStarterIcon />,
    price: 44,
    caption: 'Yıllık 60₺  Daha Ucuz',
    lists: [
      { text: ['Eğitim günü: ', '48'], isAvailable: true },
      { text: ['Egzersiz sayısı: ', '144-288'], tooltip: 'Tekrar edebilir egzersiz sayısı', isAvailable: true },
      { text: ['Zaman ve Kategori Bazlı Rapor'], isAvailable: true },
      { text: ['Arkadaş Davet Kodu'], tooltip: 'Birlikte öğrenmeyi sevenler için', isAvailable: false },
      { text: ['Tek Çekim İndirimi'], tooltip: 'Ödeme ekranında görüntülenir', isAvailable: false },
    ],
    labelAction: 'SATIN AL',
  },
  {
    subscription: 'ÜÇ AYLIK',
    icon: <PlanPremiumIcon />,
    price: 39,
    caption: 'Yıllık 120₺ Daha Ucuz',
    lists: [
      { text: ['Eğitim günü: ', '72'], isAvailable: true },
      { text: ['Egzersiz sayısı: ', '216-432'], tooltip: 'Tekrar edebilir egzersiz sayısı', isAvailable: true },
      { text: ['Zaman ve Kategori Bazlı Rapor'], isAvailable: true },
      { text: ['Arkadaş Davet Kodu'], tooltip: 'Birlikte öğrenmeyi sevenler için', isAvailable: true },
      { text: ['Tek Çekim İndirimi'], tooltip: 'Ödeme ekranında görüntülenir', isAvailable: true },
    ],
    labelAction: 'SATIN AL',
  },
  {
    subscription: 'ALTI AYLIK',
    icon: <PlanPremiumIcon />,
    price: 29,
    caption: 'Yıllık 240₺  Daha Ucuz',
    lists: [
      { text: ['Eğitim günü: ', '144'], isAvailable: true },
      { text: ['Egzersiz sayısı: ', '432-864'], tooltip: 'Tekrar edebilir egzersiz sayısı', isAvailable: true },
      { text: ['Zaman ve Kategori Bazlı Rapor'], isAvailable: true },
      { text: ['Arkadaş Davet Kodu'], tooltip: 'Birlikte öğrenmeyi sevenler için', isAvailable: true },
      { text: ['Tek Çekim İndirimi'], tooltip: 'Ödeme ekranında görüntülenir', isAvailable: true },
    ],
    labelAction: 'SATIN AL',
  },
];