import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, SxProps, Theme } from '@mui/material';

export default function LogoIcon({ disabledLink, sx, linkTo, }: {
  disabledLink?: boolean;
  sx?: SxProps<Theme>;
  linkTo?: string;
} = { disabledLink: true, linkTo: "/" }) {
  const logo = (
    <Box sx={{ width: 50, height: "auto", ...sx }}>
      <img src={"/logo/logo-sm.svg"} />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to={linkTo || '/'}>{logo}</RouterLink>;
}