import * as Yup from 'yup';
import Iconify from '../../../components/Iconify';
import useAuth from '../../../hooks/useAuth';
import {
  Alert,
  Collapse,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  Typography
} from '@mui/material';
import { FormProvider, RHFCheckbox, RHFTextField } from '../../../components/hook-form';
import { Link as RouterLink } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { PATH_AUTH } from '../../../routes/paths';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ApiError } from '../../../contract/base/Response';
import { authenticateUser } from '../../../services/identity/authentication/token/authenticateUser';

export default function LoginForm() {
  const [responseErrors, setResponseErrors] = useState<ApiError[]>([]);

  const { loginAccessToken } = useAuth();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Kullanıcı adı gerekli'),
    password: Yup.string().required('Parola gerekli'),
  });

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
  });

  const {
    // reset,
    // setError,
    handleSubmit,
    reset,
    formState: {
      // errors, 
      isSubmitting },
  } = methods;

  const onSubmit = async (values: any) => {
    // console.log('Values', values);

    setResponseErrors([]);

    let response = await authenticateUser({
      username: values.username,
      password: values.password,
    });

    // console.log('Response', response);

    if (response.success) {
      setResponseErrors([]);
      loginAccessToken(response.data.accessToken);
      // reset();
    }
    else {
      setResponseErrors(response.errors);
    }
  };


  return (
    <FormProvider methods={methods}
      // onSubmit={handleSubmit(async (data) => {
      //   console.log('formSubmitted ', data);
      //   try {
      //     setResponseErrors([]);
      //     await login(data.username, data.password);
      //   } catch (error) {
      //     if (error instanceof ResponseModelError) {
      //       console.log('Error is response model error');
      //       setResponseErrors(error.response.errorList);
      //     }

      //     console.log('Error is not response model error', error);
      //     // reset();
      //   }
      // })}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack spacing={3}>
        <Collapse in={!!responseErrors.length}>
          {responseErrors.map((responseError, i) => (
            <Alert key={i} severity="error">{responseError.message}</Alert>
          ))}
        </Collapse>

        <RHFTextField name="username" label="Telefon Numarası / E-posta Adresi" required />

        <RHFTextField
          name="password"
          label="Parola"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Typography sx={{ color: 'text.secondary' }} variant="subtitle2" >
          Hesabın yok mu?
          <Link component={RouterLink}
            // variant="subtitle2"
            to={PATH_AUTH.register}
          > Kayıt Ol</Link>
        </Typography>


        <Link component={RouterLink} variant="subtitle2"
          to={PATH_AUTH.resetPassword}
        >
          Parolamı unuttum
        </Link>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>

        <RHFCheckbox name="remember" label="Beni hatırla" />
      </Stack>
      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Giriş Yap
      </LoadingButton>
    </FormProvider>
  );
}
