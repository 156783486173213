import { FailResponseModel } from '../contract/base/ResponseModel';

export class ResponseModelError extends Error {
  public response: FailResponseModel;

  constructor(response: FailResponseModel) {
    super('Response model error');

    this.response = response;
  }
}