import { ListApiResponse } from '../../../contract/base/Response';
import axiosInstance from '../../../utils/axios';
import { handleError } from '../../base/baseService';

type ClaimDetail = {
  issuer: string;
  originalIssuer: string;
  properties: {};
  subject: string;
  type: string;
  value: string;
  valueType: string;
};

export async function getSelfClaims() {
  try {
    const response = await axiosInstance.get<ListApiResponse<ClaimDetail>>('/users/self/claims');

    return response.data;
  }
  catch (error) {
    return handleError(error);
  }
}