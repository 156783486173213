import { Box, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CurrentTaskParameter } from '../../../../contexts/PlaygroundContext';
import useCurrentUserCurriculumItem from '../../../../hooks/student/useCurrentUserCurriculumItem';
import useTask from '../../../../hooks/student/useTask';
import useNumberSearchParam from '../../../../hooks/useNumberSearchParam';
import usePlaygroundContext from '../../../../hooks/usePlaygroundContext';
import cssStyles from '../../../../utils/cssStyles';
import useUserCurriculumItem from '../../../../hooks/student/useUserCurriculumItem';

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const InfobarStyle = styled('div')(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: (theme as any).customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

function ParameterValue<TValue = unknown>({ index, currentTaskParameter, onChange }: {
  index: number;
  currentTaskParameter: CurrentTaskParameter<TValue>;
  onChange: (currentTaskParameter: CurrentTaskParameter<TValue>) => void;
}) {
  if (currentTaskParameter.type === 'boolean') {
    return (
      <FormControlLabel
        label={currentTaskParameter.name}
        control={
          <Switch
            checked={currentTaskParameter.value}
            onChange={(_, v) => onChange({ ...currentTaskParameter, value: v })}
          />
        }
      />
    );
  }

  if (currentTaskParameter.type === 'selection') {
    return (
      <FormControl>
        <InputLabel>{currentTaskParameter.name}</InputLabel>
        <Select
          variant="outlined"
          sx={{
            minWidth: 150
          }}
          label={currentTaskParameter.name}
          // placeholder={currentTaskParameter.name}
          value={currentTaskParameter.value}
          onChange={(e) => onChange({ ...currentTaskParameter, value: e.target.value as unknown as any })}
        >
          {currentTaskParameter.valueSet.map((v, i) => (
            <MenuItem key={i} value={v.value as unknown as string}>{v.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  if (currentTaskParameter.type === 'number') {
    return (
      <TextField
        variant="outlined"
        label={currentTaskParameter.name}
        disabled={currentTaskParameter.readonly}
        type="number"
        value={currentTaskParameter.value}
        onChange={(e) => onChange({ ...currentTaskParameter, value: e.target.value as unknown as any })}
      />
    );
  }

  if (currentTaskParameter.type === 'info') {
    return (
      <Typography sx={{ marginLeft: 'auto !important' }}>
        {currentTaskParameter.name}
        &nbsp;
        <strong>{currentTaskParameter.value}</strong>
      </Typography>
    );
  }

  return null;
}

export default function Infobar() {
  const membershipId = useNumberSearchParam('mid');
  const userCurriculumItemId = useNumberSearchParam('cid');
  const {
    currentTaskId,
    setCurrentTaskId,
    active,
    setActive,
    addTaskChangedEventHandler,
    currentTaskParameters,
    setCurrentTaskParameters,
    currentTaskInfoParameters,
    setCurrentTaskInfoParameters,
  } = usePlaygroundContext();

  // const { loading: loadingCurrentUserCurriculumItem, userCurriculumItem } = useCurrentUserCurriculumItem({
  //   load: !!membershipId,
  //   membershipId: membershipId || 0
  // });
  const { loading: loadingCurrentUserCurriculumItem, userCurriculumItem } = useUserCurriculumItem({
    load: !!membershipId,
    membershipId: membershipId || 0,
    userCurriculumItemId: userCurriculumItemId || undefined,
  });

  const { loading: loadingTask, task } = useTask({
    load: false,
    membershipId: membershipId || 0,
    taskId: currentTaskId,
    itemId: userCurriculumItem?.id || 0,
  });

  const handleParameterValueChange = (index: number, v: CurrentTaskParameter) => {
    const clone = [...currentTaskParameters];
    const item = clone[index];
    item.value = v.value;

    setCurrentTaskParameters(clone);
  };

  const handleInfoParameterValueChange = (index: number, v: CurrentTaskParameter) => {
    const clone = [...currentTaskInfoParameters];
    const item = clone[index];
    item.value = v.value;

    setCurrentTaskInfoParameters(clone);
  };

  return (
    <Box
      sx={{ flexGrow: 1 }}
    >
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          px: 2
        }}
        spacing={2}
      >
        {/* <InfobarStyle> */}
        <Typography variant="h6" color="text.primary">
          {task?.task?.customExercise?.name || 'Egzersiz Tahtası'}
        </Typography>
        {/* </InfobarStyle> */}
        {currentTaskParameters.map((x, i) => (
          <ParameterValue
            key={i}
            index={i}
            currentTaskParameter={x}
            onChange={(v) => handleParameterValueChange(i, v)}
          />
        ))}
        {currentTaskInfoParameters.map((x, i) => (
          <ParameterValue
            key={i}
            index={i}
            currentTaskParameter={x}
            onChange={(v) => handleInfoParameterValueChange(i, v)}
          />
        ))}
      </Stack>
    </Box>
  );
}