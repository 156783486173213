import {
  Box,
  BoxProps,
  List,
  ListSubheader
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { NavConfigGroup } from '../../../layouts/dashboard/navbar/NavConfig';
import { NavListRoot } from './NavList';
import { useFilteredNavConfigGroupItems, useFilteredNavConfigGroups } from './useFilteredItems';

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

function Group({
  isCollapse,
  group,
}: {
  isCollapse: boolean;
  group: NavConfigGroup;
}) {
  const { t } = useTranslation("navigation");
  const items = useFilteredNavConfigGroupItems(group.items);

  return (
    <List key={group.subheader} disablePadding sx={{ px: 2 }}>
      <ListSubheaderStyle
        sx={{
          ...(isCollapse && {
            opacity: 0,
          }),
        }}
      >
        {t(`subheaders.${group.subheader}` as Parameters<typeof t>[0])}
      </ListSubheaderStyle>

      {/* {group.items.filter(x => !x.roles || !x.roles.length || (user?.role && x.roles.includes(user.role))).map((list) => ( */}
      {items.map((list) => (
        <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
      ))}
    </List>
  );
}

type NavSectionVerticalProps = {
  isCollapse: boolean;
  navConfig: NavConfigGroup[];
} & BoxProps;
export default function NavSectionVertical({ navConfig, isCollapse = false, ...other }: NavSectionVerticalProps) {
  const { t } = useTranslation("navigation");

  // const { user } = useAuth();
  // const { data: currentUser } = useSelfUser();
  const groups = useFilteredNavConfigGroups(navConfig);

  return (
    <Box {...other}>
      {/* {navConfig.filter(x => !x.roles || !x.roles.length || (user?.role && x.roles.includes(user.role))).map((group) => ( */}
      {groups.map((group, i) => (
        <Group
          key={i}
          group={group}
          isCollapse={isCollapse}
        />
      ))}
    </Box>
  );

  // return (
  //   <Box {...other}>
  //     {/* {navConfig.filter(x => !x.roles || !x.roles.length || (user?.role && x.roles.includes(user.role))).map((group) => ( */}
  //     {groups.map((group) => {
  //       return (
  //         <List key={group.subheader} disablePadding sx={{ px: 2 }}>
  //           <ListSubheaderStyle
  //             sx={{
  //               ...(isCollapse && {
  //                 opacity: 0,
  //               }),
  //             }}
  //           >
  //             {t(`subheaders.${group.subheader}` as Parameters<typeof t>[0])}
  //           </ListSubheaderStyle>

  //           {group.items.filter(x => !x.roles || !x.roles.length || (user?.role && x.roles.includes(user.role))).map((list) => (
  //             <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
  //           ))}
  //         </List>
  //       );
  //     })}
  //   </Box>
  // );
}
