import {
  Box,
  BoxProps,
  List,
  ListSubheader
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from '../../../hooks/useAuth';
import { PlaygroundNavConfigGroup } from './Config';
import { PlayNavListRoot } from './NavList';

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

type NavSectionVerticalProps = {
  activeColor: string;
  isCollapse: boolean;
  navConfig: PlaygroundNavConfigGroup[];
} & BoxProps;
export default function NavSectionVertical({ navConfig, isCollapse = false, ...other }: NavSectionVerticalProps) {
  const { t } = useTranslation("navigation");

  const { user } = useAuth();

  const showNavConfigItems = useMemo(
    () =>
      navConfig.filter(x => !x.roles || !x.roles.length || (user?.role && x.roles.includes(user.role))),
    [navConfig, user]
  );

  const RenderItems = ({ group }: { group: PlaygroundNavConfigGroup }) => {
    const shownItems = useMemo(
      () =>
        group.items.filter(x => !x.roles || !x.roles.length || (user?.role && x.roles.includes(user.role))),
      [group, user]
    );

    return (
      <>
        {shownItems.map((list, i) => (
          <PlayNavListRoot
            key={list.title}
            activeColor={list.activeColor}
            list={list}
            previousCount={shownItems.slice(0, i).map(x => (('children' in x) ? x.children?.length : 0) || 0).reduce((a, b) => a + b, 0) || 0}
            isCollapse={isCollapse}
          />
        ))}
      </>
    );
  };

  return (
    <Box
      {...other}
    >
      {showNavConfigItems.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {t(`subheaders.${group.subheader}` as Parameters<typeof t>[0])}
          </ListSubheaderStyle>

          <RenderItems group={group} />
          {/* {group.items.filter(x => !x.roles || !x.roles.length || (user?.role && x.roles.includes(user.role))).map((list) => (
            <PlayNavListRoot
              key={list.title}
              activeColor={list.activeColor}
              list={list}
              previousCount={list.children?.slice(0, i).map(x => (('children' in x) ? x.children?.length : 0) || 0).reduce((a, b) => a + b, 0) || 0}
              isCollapse={isCollapse}
            />
          ))} */}
        </List>
      ))}
    </Box>
  );
}
