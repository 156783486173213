import LoadingScreen from '../components/LoadingScreen';
import Login from '../pages/auth/Login';
import useAuth from '../hooks/useAuth';
import { Navigate, useLocation } from 'react-router-dom';
import { PropsWithChildren, useState } from 'react';

export default function AuthGuard({ children }: PropsWithChildren<{}>) {
  const { isAuthenticated, isInitialized } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  // console.log('Authenticated', {
  //   isAuthenticated,
  //   isInitialized,
  //   pathname,
  //   requestedLocation
  // });

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
