import { ListApiResponse } from '../../../../contract/base/Response';
import { UserTaskList } from '../../../../contract/lms/membership/UserTask';
import axiosInstance from '../../../../utils/axios';
import { handleError } from '../../../base/baseService';

export async function getMembershipUserTasks(
  membershipId: number,
  itemId: number) {
  try {
    const response = await axiosInstance.get<ListApiResponse<UserTaskList>>(`/memberships/${membershipId}/items/${itemId}/tasks`);

    return response.data;
  }
  catch (error) {
    return handleError(error);
  }
}