import { DataApiResponse } from '../../../../contract/base/Response';
import axiosInstance from '../../../../utils/axios';
import { handleError } from '../../../base/baseService';

type AuthenticateUser = {
  username: string;
  password: string;
};

type AuthenticateUserResponse = {
  accessToken: string;
};

export async function authenticateUser(request: AuthenticateUser) {
  try {
    const response = await axiosInstance.post<DataApiResponse<AuthenticateUserResponse>>('/authentication/token', request);

    return response.data;
  }
  catch (error) {
    return handleError(error);
  }
}