import { Func, IEnumerable, int } from '../types/dotnet';

export namespace Linq {
  export function max<T, TKey>(
    collection: IEnumerable<T>,
    keySelector: Func<TKey, T>,
    comparer: Func<int, TKey, TKey>) {
    const ordered = orderBy(collection, keySelector, comparer);
    return first(ordered);
  }

  export function min<T, TKey>(
    collection: IEnumerable<T>,
    keySelector: Func<TKey, T>,
    comparer: Func<int, TKey, TKey>) {
    const ordered = orderBy(collection, keySelector, comparer);
    return last(ordered);
  }

  export function orderBy<T, TKey>(
    collection: IEnumerable<T>,
    keySelector: Func<TKey, T>,
    comparer: Func<int, TKey, TKey>) {

    return collection.sort((a, b) => comparer(keySelector(a), keySelector(b)));
  }

  export function first<T>(collection: IEnumerable<T>) {
    return collection[0];
  }

  export function last<T>(collection: IEnumerable<T>) {
    return collection[collection.length - 1];
  }

  export function distinct<T>(
    collection: IEnumerable<T>,
    equalityComparer?: Func<boolean, T, T>) {
    let eqComparer = equalityComparer || ((a, b) => a === b);

    return collection.filter((thing, i, arr) => {
      let index = arr.findIndex(t => eqComparer(t!, thing!));
      return index === i;
    });
  }

  export const intComparer: Func<int, int, int> = (a, b) => a! - b!;


  export type KeyValuePair<TKey, TValue> = {
    key: TKey;
    value: TValue;
  };

  export type Group<TKey, TValue> = KeyValuePair<TKey, TValue>[];

  export function groupBy<T, TProp>(elements: T[], selector: (i: T) => (TProp)) {
    const map: Group<TProp, T[]> = [];

    elements.forEach((item) => {
      const key = selector(item);
      const collection = map.find(x => x.key === key);
      if (!collection) {
        map.push({
          key: key,
          value: [item]
        });
      } else {
        collection.value.push(item);
      }
    });

    return map;
  }

  export function groupBySorted<T, TProp>(elements: T[], selector: (i: T) => (TProp)) {
    const map: Group<TProp, T[]> = [];

    let tempArray: T[] = [];
    let lastGroupKey: TProp | null = null;

    const processItem = () => {
      // Append temp array to the results
      if (tempArray.length) {
        map.push({
          key: lastGroupKey!,
          value: tempArray
        });
      }

      // Clear temp array
      tempArray = [];
    };

    elements.forEach((item) => {
      const key = selector(item);

      // When key changes
      if (key !== lastGroupKey) {
        processItem();
      }

      lastGroupKey = key;
      tempArray.push(item);
    });

    processItem();

    return map;
  }
}