import { Checkbox, FormControl, FormControlLabel, FormControlLabelProps, FormGroup, FormHelperText } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

type RHFCheckboxProps = Omit<FormControlLabelProps, "control"> & {
  name: string;
};

export function RHFCheckbox({ name, ...other }: RHFCheckboxProps) {
  const { control } = useFormContext();

  return (

    <>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (

          <FormControl variant="standard" error={!!error} >
            <FormControlLabel
              {...other}
              control={
                <Checkbox {...field}
                  value={field.value !== null ? field.value : false}
                  checked={field.value !== null ? field.value : false}
                  onChange={(event) => field.onChange(event.target.checked)}
                />
              }
            />
            {error && (
              <FormHelperText>{error.message}</FormHelperText>
            )}
          </FormControl>
        )}
      />

    </>
  );
}


type RHFMultiCheckboxProps = Omit<FormControlLabelProps, "control" | "label"> & {
  name: string;
  options: string[];
};

export function RHFMultiCheckbox({ name, options, ...other }: RHFMultiCheckboxProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const onSelected = (option: string) =>
          field.value?.includes(option) ? field.value.filter((value: string) => value !== option) : [...field.value, option];

        return (
          <FormGroup>
            {options.map((option) => (
              <FormControlLabel
                key={option}
                {...other}
                control={
                  <Checkbox
                    checked={field.value?.includes(option)}
                    onChange={() => field.onChange(onSelected(option))}
                  />
                }
                label={option}
              />
            ))}
          </FormGroup>
        );
      }}
    />
  );
}
