import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import { rootPersistConfig, rootReducer } from './rootReducer';

export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: persistReducer<RootState>(rootPersistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

const persistor = persistStore(store);

const { dispatch } = store;

const useSelector = <TSelected = unknown>(
  selector: (state: RootState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean) => useAppSelector<RootState, TSelected>(selector, equalityFn);

const useDispatch = () => useAppDispatch();

export { store, persistor, dispatch, useSelector, useDispatch };
