import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function useNumberSearchParam(id: string) {
  const [searchParams] = useSearchParams();
  const membershipId = useMemo(() => {
    if (!searchParams.has(id)) {
      return null;
    }

    let strValue = searchParams.get(id);

    if (!strValue) {
      return null;
    }

    try {
      return parseInt(strValue);
    }
    catch {
      return null;
    }
  }, [searchParams]);

  return membershipId;
}