import { Helmet } from 'react-helmet-async';
import { forwardRef, PropsWithChildren, PropsWithRef } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

const Page = forwardRef<unknown, PropsWithChildren<{ title: string; meta?: any; } & BoxProps>>((props, ref) => {
  const { children, title, meta, ...other } = props;

  return (
    <>
      <Helmet>
        <title>{`${title} | Etkin Okuma`}</title>
        {/* {meta} */}
      </Helmet>

      <Box ref={ref} {...other}>
        {children}
      </Box>
    </>
  );
});

export default Page;
