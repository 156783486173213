import { ListApiResponse } from '../../../contract/base/Response';
import { Product, ProductList } from '../../../contract/catalog/Product';
import axiosInstance from '../../../utils/axios';
import { handleError } from '../../base/baseService';


export async function listActiveProducts() {
  try {
    const response = await axiosInstance.get<ListApiResponse<ProductList>>('/products/active');

    return response.data;
  }
  catch (error) {
    return handleError(error);
  }
}