import { DataApiResponse } from '../../../../contract/base/Response';
import { UserCurriculumItem, UserCurriculumItemInfo } from '../../../../contract/lms/membership/UserCurriculumItem';
import axiosInstance from '../../../../utils/axios';
import { handleError } from '../../../base/baseService';

export async function getCurrentUserCurriculumItem(membershipId: number) {
  try {
    const response = await axiosInstance.get<DataApiResponse<UserCurriculumItemInfo>>(`/memberships/${membershipId}/items/current`);

    return response.data;
  }
  catch (error) {
    return handleError(error);
  }
}