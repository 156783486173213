import { useEffect } from 'react';
import { getSelfUserSuccess, startLoadingSelfUser } from '../../../redux/slices/user/user';
import { useDispatch, useSelector } from '../../../redux/store';
import { getSelfUser } from '../../../services/identity/user/getSelfUser';
import useFetch, { useStatelesFetch } from '../../useFetch';

export default function useSelfUser(options: {
  load: boolean
} = { load: true }) {
  const { fetch } = useStatelesFetch({
    fetcher: getSelfUser
  });

  const user = useSelector(x => x.user.selfUser);
  const loadingSelfUser: boolean = useSelector(x => x.user.loadingSelfUser);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchData();
  }, [options.load]);

  async function fetchData() {
    if (!options.load) {
      return;
    }

    if (loadingSelfUser) {
      return;
    }

    if (!!user) {
      return;
    }

    doFetch();
  }

  async function doFetch() {
    dispatch(startLoadingSelfUser());

    let response = await fetch();
    if (response?.success) {
      dispatch(getSelfUserSuccess({
        user: response.data,
      }));
    }
  }

  const forceReload = doFetch;

  return {
    loading: loadingSelfUser,
    data: user,
    forceReload,
  };
}