import { enumText, getText } from '../enum/Base';

const Roles = {
  SystemAdmin: "SystemAdmin",
  PortalAdmin: "PortalAdmin",
  OrganizationAdmin: "OrganizationAdmin",
  Teacher: "Teacher",
  Student: "Student",
  Parent: "Parent",
};

export function roleText(role: string) {
  return getText(role, {
    [Roles.SystemAdmin]: {
      capitalize: 'Sistem Yöneticisi',
      upper: 'SİSTEM YÖNETİCİSİ',
      lower: 'sistem yöneticisi',
    },
    [Roles.PortalAdmin]: {
      capitalize: 'Portal Yöneticisi',
      upper: 'PORTAL YÖNETİCİSİ',
      lower: 'portal yöneticisi',
    },
    [Roles.OrganizationAdmin]: {
      capitalize: 'Kurum Yöneticisi',
      upper: 'KURUM YÖNETİCİSİ',
      lower: 'kurum yöneticisi',
    },
    [Roles.Teacher]: {
      capitalize: 'Öğretmen',
      upper: 'ÖĞRETMEN',
      lower: 'öğretmen',
    },
    [Roles.Student]: {
      capitalize: 'Öğrenci',
      upper: 'ÖĞRENCİ',
      lower: 'öğrenci',
    },
    [Roles.Parent]: {
      capitalize: 'Veli',
      upper: 'VELİ',
      lower: 'veli',
    },
  });
}

export default Roles;