import { useEffect } from 'react';
import { UserCurriculumItemTaskFullDetail } from '../../contract/lms/membership/UserTask';
import { getTaskDetailSuccess, startLoadingTaskDetail } from '../../redux/slices/student';
import { useDispatch, useSelector } from '../../redux/store';
import { getMembershipUserTask } from '../../services/lms/membership/userTask/getMembershipUserTask';
import { useStatelesFetch } from '../useFetch';

export default function useTask(options: {
  load: boolean;
  membershipId: number;
  itemId: number;
  taskId: number | null;
}): { loading: boolean; task: UserCurriculumItemTaskFullDetail | null } {
  const { load, membershipId, itemId, taskId } = options;
  const { fetch } = useStatelesFetch({
    fetcher: getMembershipUserTask
  }, membershipId, itemId, taskId);

  const taskDetailList = useSelector(x => x.student.taskDetailList);
  const dispatch = useDispatch();

  useEffect(() => {
    doFetch();
  }, [load, membershipId, itemId, taskId,]);

  async function doFetch() {
    if (!load) {
      return;
    }

    if (!taskId) {
      return;
    }

    if (membershipId in taskDetailList &&
      taskId in taskDetailList[membershipId] &&
      taskDetailList[membershipId][taskId].loading) {
      console.log('Already loading task detail...', {
        membershipId,
        taskId,
      });
      return;
    }

    if (membershipId in taskDetailList &&
      taskId in taskDetailList[membershipId] &&
      !!taskDetailList[membershipId][taskId].task) {
      // console.log('Already has tasks data...', {
      //   membershipId,
      //   taskId,
      //   task: taskDetailList[membershipId][taskId].task,
      // });
      return;
    }

    dispatch(startLoadingTaskDetail({
      membershipId: membershipId,
      taskId: taskId,
    }));

    let response = await fetch();
    if (response?.success) {
      dispatch(getTaskDetailSuccess({
        membershipId: membershipId,
        taskId: taskId,
        task: response.data,
      }));
    }
  }

  const returnValue: { loading: boolean; task: UserCurriculumItemTaskFullDetail | null } = {
    loading: false,
    task: null
  };

  if (!membershipId ||
    !taskId) {
    return returnValue;
  }

  if (!(membershipId in taskDetailList)) {
    return returnValue;
  }

  if (!(taskId in taskDetailList[membershipId])) {
    return returnValue;
  }

  return {
    ...taskDetailList[membershipId][taskId]
  };
}