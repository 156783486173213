import { Button, IconButton, Tooltip } from '@mui/material';
import Iconify from '../../../../components/Iconify';
import usePlaygroundContext from '../../../../hooks/usePlaygroundContext';

export default function MusicButton() {
  const { playMusic, setPlayMusic } = usePlaygroundContext();
  const handleClick = () => {
    setPlayMusic(!playMusic);
  };

  return (
    <>
      <Tooltip title={!playMusic ? "Müzik çal" : "Müziği durdur"}>
        <IconButton
          onClick={handleClick}
          color={playMusic ? "primary" : "default"}
        >
          <Iconify icon="eva:music-outline" />
        </IconButton>
      </Tooltip>
    </>
  );
}
