import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation, Outlet } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import PlaygroundLayout from '../layouts/dashboard/playground';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard, { StudentGuard, SystemAdminGuard, BuyerGuard, OrganizationAdminGuard } from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import Roles from '../contract/constants/Roles';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'giris-yap',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'kayit-ol',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'parola-sifirla', element: <ResetPassword /> },
        { path: 'yeni-parola', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: 'email-verification', element: <EmailVerification /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <SystemAdminGuard hasContent redirectTo={(role) => role === Roles.Student ? "/dashboard/ogrenci" : "/dashboard/org"} ><GeneralApp /></SystemAdminGuard> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },
        {
          path: 'ogrenci',
          children: [
            { element: <Navigate to="/dashboard/ogrenci/bugun" replace />, index: true },
            { path: 'bugun', element: <StudentGuard hasContent><StudentApp /></StudentGuard> },
            { path: 'ogrenme-heritasi', element: <StudentGuard hasContent><LearningMap /></StudentGuard> },
            { path: 'exercise', element: <StudentGuard hasContent><StudentApp /></StudentGuard> },
            { path: 'sts', element: <StudentGuard hasContent><DashboardPlacementTestPage /></StudentGuard> },
            { path: 'rapor', element: <StudentGuard hasContent><StudentReportsPage /></StudentGuard> },
          ],
        },
        {
          path: 'yarisma',
          children: [
            { element: <Navigate to="/dashboard/yarisma/aktif" replace />, index: true },
            { path: 'aktif', element: <StudentGuard hasContent><ActiveCompetitions /></StudentGuard> },
          ],
        },
        {
          path: 'basarilar',
          children: [
            { element: <StudentGuard hasContent><AchievementsPage /></StudentGuard>, index: true },
          ],
        },
        {
          path: 'org',
          children: [
            { element: <OrganizationAdminGuard hasContent><OrganizationAdminApp /></OrganizationAdminGuard>, index: true },
          ],
        },

        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
          ],
        },
        {
          path: 'kullanici',
          children: [
            { element: <Navigate to="/dashboard/kullanici/profil" replace />, index: true },
            { path: 'profil', element: <UserProfile /> },
            // { path: 'cards', element: <UserCards /> },
            { path: 'liste', element: <UserList /> },
            // { path: 'new', element: <UserCreate /> },
            // { path: ':name/edit', element: <UserCreate /> },
            { path: 'ayarlar', element: <UserAccount /> },
            { path: 'tam-abonelik-erisimi', element: <FullMembershipAccessList /> },
            
            { path: ':id/abonelikler', element: <UserMembershipsPage /> },
            { path: ':userId/abonelikler/:id', element: <UserMembershipDetailPage /> },
          ],
        },
        // {
        //   path: 'curriculum_old',
        //   children: [
        //     { element: <Navigate to="/dashboard/curriculum_old" replace />, index: true },
        //     { path: 'list', element: <CurriculumOldList /> },
        //     { path: 'new', element: <CurriculumOldNew /> },
        //     { path: ':name/details', element: <CurriculumOldDetails /> },
        //     { path: ':name/edit', element: <CurriculumOldDetails /> },
        //   ],
        // },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceList /> },
            { path: ':id', element: <InvoiceDetails /> },
            { path: ':id/edit', element: <InvoiceEdit /> },
            { path: 'new', element: <InvoiceCreate /> },
          ],
        },
        {
          path: 'sss',
          children: [
            { element: <Navigate to="/dashboard/sss/liste" replace />, index: true },
            { path: 'liste', element: <FaqList /> },
            { path: ':id', element: <FaqCreate /> },
            { path: ':id/duzenle', element: <FaqCreate /> },
            { path: 'yeni', element: <FaqCreate /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
        { path: 'permission-denied', element: <PermissionDenied /> },

        // {
        //   path: 'organization_old',
        //   children: [
        //     { element: <Navigate to="/dashboard/organization/list" replace />, index: true },
        //     { path: 'list', element: <OrganizationList /> },
        //     // { path: ':id', element: <OrganizationDetails /> },
        //     { path: ':id/edit', element: <OrganizationSave /> },
        //     { path: 'new', element: <OrganizationSave /> },
        //   ],
        // },

        {
          path: 'kurum',
          children: [
            { element: <Navigate to="/dashboard/kurum/liste" replace />, index: true },
            { path: 'liste', element: <OrganizationList /> },
            { path: ':id/duzenle', element: <OrganizationCreate /> },
            { path: 'yeni', element: <OrganizationCreate /> },
            { path: ':id', element: <OrganizationDetailPage /> },
          ],
        },

        {
          path: 'birimler',
          children: [
            { element: <Navigate to="/dashboard/birimler/liste" replace />, index: true },
            { path: 'liste', element: <OrganizationUnitList /> },
            // { path: ':id', element: <OrganizationUnitDetails /> },
            { path: ':id/duzenle', element: <OrganizationUnitCreate /> },
            { path: 'yeni', element: <OrganizationUnitCreate /> },
          ],
        },
        {
          path: 'kurum-kullanicilari',
          children: [
            { element: <Navigate to="/dashboard/kurum-kullanicilari/liste" replace />, index: true },
            { path: 'liste', element: <OrganizationMemberList /> },
            { path: 'yeni', element: <OrganizationMemberCreate /> },
            { path: ':id/duzenle', element: <OrganizationMemberCreate /> },
            { path: 'toplu-ekle', element: <OrganizationMemberImport /> },
          ],
        },
        {
          path: 'magaza',
          children: [
            { element: <Navigate to="/dashboard/magaza/katalog" replace /> },
            { path: 'katalog', element: <BuyerGuard hasContent><PackageBuyPage /></BuyerGuard> },
            { path: 'kasa', element: <BuyerGuard hasContent><ProductCheckoutPage /></BuyerGuard> },
          ],
        },
        {
          path: 'katalog',
          children: [
            { element: <Navigate to="/dashboard/katalog/liste" replace /> },
            { path: 'urun/:name', element: <BuyerGuard hasContent><EcommerceProductDetails /></BuyerGuard> },
            { path: 'liste', element: <BuyerGuard hasContent><EcommerceProductList /></BuyerGuard> },
            // { path: 'urun/yeni', element: <ProductNewPage /> },
            { path: 'urun/:name/edit', element: <EcommerceProductCreate /> },
          ],
        },
        {
          path: 'kupon',
          children: [
            { element: <Navigate to="/dashboard/kupon/liste" replace /> },
            // { path: 'kupon/:id', element: <EcommerceProductDetails /> },
            { path: 'liste', element: <CouponListPage /> },
            { path: 'yeni', element: <CouponCreatePage /> },
            { path: ':id/duzenle', element: <CouponCreatePage /> },
          ],
        },
        {
          path: 'kategori',
          children: [
            { element: <Navigate to="/dashboard/kategori/liste" replace />, index: true },
            { path: 'liste', element: <CategoryList /> },
            { path: 'yeni', element: <CategoryCreate /> },
            { path: ':id/duzenle', element: <CategoryCreate /> },
          ],
        },
        {
          path: 'egzersiz',
          children: [
            { element: <Navigate to="/dashboard/egzersiz/liste" replace />, index: true },
            { path: 'liste', element: <CustomExerciseList /> },
            { path: 'yeni', element: <CustomExerciseCreate /> },
            { path: ':id/duzenle', element: <CustomExerciseCreate /> },
          ],
        },
        {
          path: 'sistem-egzersizi',
          element: <SystemAdminGuard hasContent ><Outlet /></SystemAdminGuard>,
          children: [
            { element: <Navigate to="/dashboard/sistem-egzersizi/liste" replace />, index: true },
            { path: 'liste', element: <ExerciseList /> },
            { path: 'yeni', element: <ExerciseCreate /> },
            { path: ':id/duzenle', element: <ExerciseCreate /> },
          ],
        },
        {
          path: 'test',
          // Herhangi bir root'u rol dışına göstermeme
          element: <SystemAdminGuard hasContent ><Outlet /></SystemAdminGuard>,
          children: [
            { element: <Navigate to="/dashboard/test/liste" replace />, index: true },
            // Herhangi bir page'i rol dışına göstermeme
            // { path: 'liste', element: <SystemAdminGuard hasContent ><TestListPage /></SystemAdminGuard> },
            { path: 'liste', element: <TestListPage /> },
            { path: 'yeni', element: <TestCreate /> },
            { path: ':id/duzenle', element: <TestCreate /> },
            { path: 'toplu-ekle', element: <TestsCreate /> },
          ],
        },
        {
          path: 'mufredat',
          children: [
            { element: <Navigate to="/dashboard/mufredat/liste" replace />, index: true },
            { path: 'liste', element: <CurriculumListPage /> },
            { path: 'yeni', element: <CurriculumCreate /> },
            { path: 'yukle', element: <CurriculumImport /> },
            { path: ':id/duzenle', element: <CurriculumCreate /> },
            { path: ':id', element: <CurriculumDetailPage /> },
          ],
        },
        {
          path: 'mufredat-gunleri',
          children: [
            // { element: <Navigate to="/dashboard/mufredat/liste" replace />, index: true },
            { path: 'yeni', element: <CurriculumItemCreatePage /> },
            { path: ':id/duzenle', element: <CurriculumItemCreatePage /> },
          ],
        },
        {
          path: 'seviye',
          children: [
            { element: <Navigate to="/dashboard/seviye/liste" replace />, index: true },
            { path: 'liste', element: <LevelList /> },
            { path: 'yeni', element: <LevelCreate /> },
            { path: ':id/duzenle', element: <LevelCreate /> },
          ],
        },
        {
          path: 'urun',
          children: [
            { element: <Navigate to="/dashboard/urun/liste" replace />, index: true },
            { path: 'liste', element: <ProductListPage /> },
            { path: 'yeni', element: <ProductCreatePage /> },
            { path: ':id/duzenle', element: <ProductCreatePage /> },
          ],
        },
        {
          path: 'siparis',
          children: [
            { element: <Navigate to="/dashboard/siparis/liste" replace />, index: true },
            // { path: 'liste', element: <BuyerGuard hasContent><OrderListPage /></BuyerGuard> },
            // { path: ':id', element: <BuyerGuard hasContent><OrderDetailPage /></BuyerGuard> },
            { path: 'liste', element: <OrderListPage /> },
            { path: ':id', element: <OrderDetailPage /> },
          ],
        },
        {
          path: 'abonelik',
          children: [
            { element: <Navigate to="/dashboard/abonelik/aktif" replace />, index: true },
            { path: 'aktif', element: <ActiveMemberships /> },
            { path: ':id', element: <MembershipDetailPage /> },
            { path: ':mid/uye/:id', element: <MembershipUserDetailPage /> },
          ],
        },
        {
          path: 'icerik',
          children: [
            { element: <Navigate to="/dashboard/icerik/yonetim" replace />, index: true },
            { path: 'yonetim', element: <ContentIndex /> },
          ],
        },
        {
          path: 'icerik/grup',
          children: [
            { element: <Navigate to="/dashboard/icerik/grup/liste" replace />, index: true },
            { path: 'liste', element: <ContentGroupList /> },
            { path: 'yeni', element: <ContentGroupCreate /> },
            { path: ':id', element: <ContentGroupDetail /> },
            { path: ':id/duzenle', element: <ContentGroupCreate /> },
          ],
        },
        {
          path: 'icerik/gorsel',
          children: [
            { element: <Navigate to="/dashboard/icerik/gorsel/liste" replace />, index: true },
            { path: 'liste', element: <ImageContentList /> },
            { path: 'yeni', element: <ImageContentCreate /> },
            { path: 'grup-ekle', element: <ImageContentsCreate /> },
            { path: ':id/duzenle', element: <ImageContentCreate /> },
          ],
        },
        {
          path: 'icerik/metin',
          children: [
            { element: <Navigate to="/dashboard/icerik/metin/liste" replace />, index: true },
            { path: 'liste', element: <TextContentList /> },
            { path: 'yeni', element: <TextContentCreate /> },
            { path: 'toplu-ekle', element: <TextContentsCreate /> },
            { path: ':id/duzenle', element: <TextContentCreate /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        // { path: 'kullanim-kosullari', element: <TermsOfUse /> },
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        // { path: 'payment_old', element: <Payment /> },
        { path: 'payment/success', element: <PaymentSuccess /> },
        { path: 'payment/failure', element: <PaymentFailure /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'hakkinda', element: <About /> },
        { path: 'iletisim', element: <Contact /> },
        { path: 'sss', element: <Faqs /> },
        { path: 'kullanim-kosullari', element: <TermsOfUse /> },
        { path: 'gizlilik-sozlesmesi', element: <PrivacyPage /> },
        // { path: 'seviye-belirleme-testi', element: <PlacementTestPage /> },
        {
          path: '/seviye-belirleme-testi',
          element: <PlacementTestPage />,
          children: [
            { path: 'seviye-belirleme-testi/kayit', element: <PlacementTestPage />, index: true },
          ],
        },
        { path: 'paket-satin-al', element: <PackageBuyGuest /> },
      ],
    },
    {
      path: '/dashboard/exercise',
      element: <PlaygroundLayout />,
      children: [
        { element: <Board />, index: true },
      ],
    },

    {
      path: '/dashboard/playground',
      element: <PlaygroundLayout />,
      children: [
        { element: <Playground />, index: true },
        { path: 'text-diag', element: <TextDiag /> },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const EmailVerification = Loadable(lazy(() => import('../pages/auth/EmailVerification')));


// PLACEMENT TEST
const PlacementTestPage = Loadable(lazy(() => import('../pages/PlacementTest')));

// PACKAGE BUY GUEST
const PackageBuyGuest = Loadable(lazy(() => import('../pages/PackageBuyGuest')));

// TERMS OF USE
const TermsOfUse = Loadable(lazy(() => import('../pages/TermsOfUse')));

// TERMS OF USE
const PrivacyPage = Loadable(lazy(() => import('../pages/Privacy')));


// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
const StudentApp = Loadable(lazy(() => import('../pages/dashboard/StudentApp')));
const LearningMap = Loadable(lazy(() => import('../pages/dashboard/student/LearningMap')));

const OrganizationAdminApp = Loadable(lazy(() => import('../pages/dashboard/OrganizationAdminApp')));


// ECOMMERCE
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));

// INVOICE
const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/InvoiceCreate')));
const InvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/InvoiceEdit')));

// BLOG
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));

// USER
// const OLD_UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
// const OLD_UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
// const OLD_UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
// const OLD_UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
// const OLD_UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// APP
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));

// ORGANIZATION
// const OrganizationList = Loadable(lazy(() => import('../pages/dashboard/organization/OrganizationList')));
// const OrganizationSave = Loadable(lazy(() => import('../pages/dashboard/organization/OrganizationSave')));
// const OrganizationDetails = Loadable(lazy(() => import('../pages/dashboard/organization/OrganizationDetails')));

// ORGANIZATION
const OrganizationList = Loadable(lazy(() => import('../pages/dashboard/organization/OrganizationList')));
const OrganizationCreate = Loadable(lazy(() => import('../pages/dashboard/organization/OrganizationCreate')));
const OrganizationDetailPage = Loadable(lazy(() => import('../pages/dashboard/organization/OrganizationDetail')));

// ORGANIZATIONUNIT
const OrganizationUnitList = Loadable(lazy(() => import('../pages/dashboard/organizationUnit/OrganizationUnitList')));
const OrganizationUnitCreate = Loadable(lazy(() => import('../pages/dashboard/organizationUnit/OrganizationUnitCreate')));
// const OrganizationUnitDetails = Loadable(lazy(() => import('../pages/dashboard/organizationUnit/OrganizationUnitDetails')));

// ORGANIZATIONMEMBER
const OrganizationMemberList = Loadable(lazy(() => import('../pages/dashboard/organizationMember/OrganizationMemberList')));
const OrganizationMemberCreate = Loadable(lazy(() => import('../pages/dashboard/organizationMember/OrganizationMemberCreate')));
const OrganizationMemberImport = Loadable(lazy(() => import('../pages/dashboard/organizationMember/OrganizationMemberImport')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/users/UserDetails')));
// const UserCards = Loadable(lazy(() => import('../pages/dashboard/user/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/users/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/users/UserSave')));
// const UserCreate = Loadable(lazy(() => import('../pages/dashboard/user/UserCreate')));
const FullMembershipAccessList = Loadable(lazy(() => import('../pages/dashboard/users/FullMembershipAccessList')));


// CURRICULUM 
// const CurriculumOldDetails = Loadable(lazy(() => import('../pages/dashboard/curriculums_old/CurriculumDetails')));
// const CurriculumOldList = Loadable(lazy(() => import('../pages/dashboard/curriculums_old/CurriculumList')));
// const CurriculumOldSave = Loadable(lazy(() => import('../pages/dashboard/curriculums_old/CurriculumSave')));
// const CurriculumOldNew = Loadable(lazy(() => import('../pages/dashboard/curriculums_old/CurriculumNew')));


// CHECKOUT
const ProductCheckoutPage = Loadable(lazy(() => import('../pages/dashboard/productCheckout/ProductCheckoutPage')));


// CATALOG 
// const ProductNewPage = Loadable(lazy(() => import('../pages/dashboard/catalog/ProductNew')));


// CATEGORY
const CategoryList = Loadable(lazy(() => import('../pages/dashboard/category/category/CategoryList')));
const CategoryCreate = Loadable(lazy(() => import('../pages/dashboard/category/category/CategoryCreate')));


// CUSTOM EXERCISE
const CustomExerciseList = Loadable(lazy(() => import('../pages/dashboard/lms/customExercise/customExercise/CustomExerciseList')));
const CustomExerciseCreate = Loadable(lazy(() => import('../pages/dashboard/lms/customExercise/customExercise/CustomExerciseCreate')));


// EXERCISE
const ExerciseList = Loadable(lazy(() => import('../pages/dashboard/lms/exercise/exercise/ExerciseList')));
const ExerciseCreate = Loadable(lazy(() => import('../pages/dashboard/lms/exercise/exercise/ExerciseCreate')));

// LEVEL
const LevelList = Loadable(lazy(() => import('../pages/dashboard/lms/level/level/LevelList')));
const LevelCreate = Loadable(lazy(() => import('../pages/dashboard/lms/level/level/LevelCreate')));


// TEST
const TestListPage = Loadable(lazy(() => import('../pages/dashboard/lms/test/TestList')));
const TestCreate = Loadable(lazy(() => import('../pages/dashboard/lms/test/TestCreate')));
const TestsCreate = Loadable(lazy(() => import('../pages/dashboard/lms/test/TestsCreate')));

// CURRICULUM
const CurriculumListPage = Loadable(lazy(() => import('../pages/dashboard/lms/curriculum/curriculum/CurriculumList')));
const CurriculumCreate = Loadable(lazy(() => import('../pages/dashboard/lms/curriculum/curriculum/CurriculumCreate')));
const CurriculumImport = Loadable(lazy(() => import('../pages/dashboard/lms/curriculum/curriculum/CurriculumImport')));
const CurriculumDetailPage = Loadable(lazy(() => import('../pages/dashboard/lms/curriculum/curriculum/CurriculumDetail')));
const CurriculumTestPage = Loadable(lazy(() => import('../pages/dashboard/lms/curriculum/curriculum/TestPage')));

// CURRICULUMITEM
const CurriculumItemCreatePage = Loadable(lazy(() => import('../pages/dashboard/lms/curriculum/curriculumItem/CurriculumItemCreate')));

// PACKAGE BUY GUEST
const PackageBuyPage = Loadable(lazy(() => import('../pages/dashboard/packageBuy/PackageBuyPage')));

// PRODUCT
const ProductListPage = Loadable(lazy(() => import('../pages/dashboard/catalog/product/ProductList')));
const ProductCreatePage = Loadable(lazy(() => import('../pages/dashboard/catalog/product/ProductCreate')));

// COUPON
const CouponListPage = Loadable(lazy(() => import('../pages/dashboard/shopping/coupon/CouponList')));
const CouponCreatePage = Loadable(lazy(() => import('../pages/dashboard/shopping/coupon/CouponCreate')));

// PLAYGROUND
const Playground = Loadable(lazy(() => import('../pages/dashboard/playground/Playground')));
const TextDiag = Loadable(lazy(() => import('../pages/dashboard/playground/TextDiag')));

// ORDER
const OrderListPage = Loadable(lazy(() => import('../pages/dashboard/order/order/OrderList')));
const OrderDetailPage = Loadable(lazy(() => import('../pages/dashboard/order/order/OrderDetail')));

// PAYMENT
const PaymentSuccess = Loadable(lazy(() => import('../pages/payment/PaymentSuccess')));
const PaymentFailure = Loadable(lazy(() => import('../pages/payment/PaymentFailure')));

// MEMBERSHIP
const ActiveMemberships = Loadable(lazy(() => import('../pages/dashboard/membership/membership/ActiveMemberships')));
const MembershipDetailPage = Loadable(lazy(() => import('../pages/dashboard/membership/membership/MembershipDetail')));
const UserMembershipsPage = Loadable(lazy(() => import('../pages/dashboard/membership/membership/UserMemberships')));
const UserMembershipDetailPage = Loadable(lazy(() => import('../pages/dashboard/membership/membership/UserMembershipDetail')));

// MEMBERSHIPUSER
const MembershipUserDetailPage = Loadable(lazy(() => import('../pages/dashboard/membership/membershipUser/MembershipUserDetail')));

// STUDENT
const Board = Loadable(lazy(() => import('../pages/dashboard/student/Board')));

// CONTENT
const ContentIndex = Loadable(lazy(() => import('../pages/dashboard/lms/content/index')));

// CONTENTGROUP
const ContentGroupList = Loadable(lazy(() => import('../pages/dashboard/lms/content/contentGroup/ContentGroupList')));
const ContentGroupCreate = Loadable(lazy(() => import('../pages/dashboard/lms/content/contentGroup/ContentGroupCreate')));
const ContentGroupDetail = Loadable(lazy(() => import('../pages/dashboard/lms/content/contentGroup/ContentGroupDetail')));

// IMAGECONTENT
const ImageContentList = Loadable(lazy(() => import('../pages/dashboard/lms/content/imageContent/ImageContentList')));
const ImageContentCreate = Loadable(lazy(() => import('../pages/dashboard/lms/content/imageContent/ImageContentCreate')));
const ImageContentsCreate = Loadable(lazy(() => import('../pages/dashboard/lms/content/imageContent/ImageContentsCreate')));

// TEXTCONTENT
const TextContentList = Loadable(lazy(() => import('../pages/dashboard/lms/content/textContent/TextContentList')));
const TextContentCreate = Loadable(lazy(() => import('../pages/dashboard/lms/content/textContent/TextContentCreate')));
const TextContentsCreate = Loadable(lazy(() => import('../pages/dashboard/lms/content/textContent/TextContentsCreate')));

// FAQ
const FaqList = Loadable(lazy(() => import('../pages/dashboard/faq/FaqList')));
const FaqCreate = Loadable(lazy(() => import('../pages/dashboard/faq/FaqCreate')));

// COMPETITION
const ActiveCompetitions = Loadable(lazy(() => import('../pages/dashboard/competition/ActiveCompetitions')));


// USERACHIEVEMENT
const AchievementsPage = Loadable(lazy(() => import('../pages/dashboard/competition/Achievements')));


// PLACEMENT_TEST
const DashboardPlacementTestPage = Loadable(lazy(() => import('../pages/dashboard/student/PlacementTest')));

const StudentReportsPage = Loadable(lazy(() => import('../pages/dashboard/student/Reports')));