import { DataApiResponse } from '../../../../contract/base/Response';
import { UserCurriculumItemTaskFullDetail } from '../../../../contract/lms/membership/UserTask';
import axiosInstance from '../../../../utils/axios';
import { handleError } from '../../../base/baseService';

export async function getMembershipUserTask(
  membershipId: number,
  itemId: number,
  taskId: number) {
  try {
    const response = await axiosInstance.get<DataApiResponse<UserCurriculumItemTaskFullDetail>>(`/memberships/${membershipId}/items/${itemId}/tasks/${taskId}`);

    return response.data;
  }
  catch (error) {
    return handleError(error);
  }
}