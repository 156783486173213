// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/giris-yap'),
  register: path(ROOTS_AUTH, '/kayit-ol'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  emailVerification: path(ROOTS_AUTH, '/email-verification'),
  resetPassword: path(ROOTS_AUTH, '/parola-sifirla'),
  newPassword: path(ROOTS_AUTH, '/yeni-parola'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment_old: '/payment_old',
  payment: {
    success: '/payment/success',
    failure: '/payment/failure',
  },
  about: '/hakkinda',
  contact: '/iletisim',
  faqs: '/sss',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  termsOfUse: '/kullanim-kosullari',
  privacy: '/gizlilik-sozlesmesi',
  placementTest: {
    root: '/seviye-belirleme-testi',
    form: path('/seviye-belirleme-testi/kayit'),
    questions: (id) => path(`/seviye-belirleme-testi/soru/${id}`),
  },
  packageBuyGuest: '/paket-satin-al',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
    curriculum: path(ROOTS_DASHBOARD, '/curriculum'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/kullanici'),
    new: path(ROOTS_DASHBOARD, '/kullanici/yeni'),
    list: path(ROOTS_DASHBOARD, '/kullanici/liste'),
    cards: path(ROOTS_DASHBOARD, '/kullanici/cards'),
    profile: path(ROOTS_DASHBOARD, '/kullanici/profil'),
    settings: path(ROOTS_DASHBOARD, '/kullanici/ayarlar'),
    view: (id) => path(ROOTS_DASHBOARD, `/kullanici/${id}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/kullanici/${name}/duzenle`),
    demoEdit: path(ROOTS_DASHBOARD, `/kullanici/reece-chung/edit`),

    fullMembershipAccessStatuses: path(ROOTS_DASHBOARD, '/kullanici/tam-abonelik-erisimi'),
    
    viewUserMemberships: (id) => path(ROOTS_DASHBOARD, `/kullanici/${id}/abonelikler`),
    viewUserMembership: (userId, id) => path(ROOTS_DASHBOARD, `/kullanici/${userId}/abonelikler/${id}`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
  faq: {
    root: path(ROOTS_DASHBOARD, '/sss'),
    list: path(ROOTS_DASHBOARD, '/sss/liste'),
    new: path(ROOTS_DASHBOARD, '/sss/yeni'),
    view: (id) => path(ROOTS_DASHBOARD, `/sss/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/sss/${id}/duzenle`),
  },
  student: {
    root: path(ROOTS_DASHBOARD, '/ogrenci'),
    today: path(ROOTS_DASHBOARD, '/ogrenci/bugun'),
    learningMap: path(ROOTS_DASHBOARD, '/ogrenci/ogrenme-heritasi'),
    exercise: (membershipId, userCurriculumItemId, rep) => path(ROOTS_DASHBOARD, `/exercise?mid=${membershipId}${!!userCurriculumItemId ? `&cid=${userCurriculumItemId}` : ''}${!!rep ? `&rep=${rep}` : ''}`),
    // list: path(ROOTS_DASHBOARD, '/user/list'),
    // cards: path(ROOTS_DASHBOARD, '/user/cards'),
    // profile: path(ROOTS_DASHBOARD, '/user/profile'),
    // account: path(ROOTS_DASHBOARD, '/user/account'),
    // edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    // demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    placementTest: path(ROOTS_DASHBOARD, '/ogrenci/sts'),
    reports: path(ROOTS_DASHBOARD, '/ogrenci/rapor'),
  },
  // organization_old: {
  //   root: path(ROOTS_DASHBOARD, '/organization'),
  //   list: path(ROOTS_DASHBOARD, '/organization/list'),
  //   view: (id) => path(ROOTS_DASHBOARD, `/organization/${id}`),
  //   edit: (id) => path(ROOTS_DASHBOARD, `/organization/${id}/edit`),
  //   new: path(ROOTS_DASHBOARD, '/organization/new'),
  // },
  organizationAdmin: {
    root: path(ROOTS_DASHBOARD, '/org'),
  },
  organization: {
    root: path(ROOTS_DASHBOARD, '/kurum'),
    list: path(ROOTS_DASHBOARD, '/kurum/liste'),
    view: (id) => path(ROOTS_DASHBOARD, `/kurum/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/kurum/${id}/duzenle`),
    new: path(ROOTS_DASHBOARD, '/kurum/yeni'),
  },
  organizationUnit: {
    root: path(ROOTS_DASHBOARD, '/birimler'),
    list: path(ROOTS_DASHBOARD, '/birimler/liste'),
    view: (id) => path(ROOTS_DASHBOARD, `/birimler/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/birimler/${id}/duzenle`),
    new: path(ROOTS_DASHBOARD, '/birimler/yeni'),
  },
  organizationMember: {
    root: path(ROOTS_DASHBOARD, '/kurum-kullanicilari'),
    list: path(ROOTS_DASHBOARD, '/kurum-kullanicilari/liste'),
    view: (id) => path(ROOTS_DASHBOARD, `/kurum-kullanicilari/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/kurum-kullanicilari/${id}/duzenle`),
    new: path(ROOTS_DASHBOARD, '/kurum-kullanicilari/yeni'),
    bulk: path(ROOTS_DASHBOARD, '/kurum-kullanicilari/toplu-ekle'),
  },
  curriculum_old: {
    root: path(ROOTS_DASHBOARD, '/curriculum'),
    new: path(ROOTS_DASHBOARD, '/curriculum/new'),
    list: path(ROOTS_DASHBOARD, '/curriculum/list'),
    cards: path(ROOTS_DASHBOARD, '/curriculum/cards'),
    view: (id) => path(ROOTS_DASHBOARD, `/curriculum/${id}/details`),
    edit: (name) => path(ROOTS_DASHBOARD, `/curriculum/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/curriculum/mufredat-ornegi/edit`),
    demoView: path(ROOTS_DASHBOARD, `/curriculum/mufredat-ornegi/details`),
  },
  shopping: {
    root: path(ROOTS_DASHBOARD, '/magaza'),
    shop: path(ROOTS_DASHBOARD, '/magaza/katalog'),
    checkout: path(ROOTS_DASHBOARD, '/magaza/kasa'),
  },
  catalog: {
    root: path(ROOTS_DASHBOARD, '/katalog'),
    list: path(ROOTS_DASHBOARD, '/katalog/liste'),
    new: path(ROOTS_DASHBOARD, '/katalog/urun/yeni'),
    view: (name) => path(ROOTS_DASHBOARD, `/katalog/urun/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/katalog/urun/${name}/duzenle`),
    demoEdit: path(ROOTS_DASHBOARD, '/katalog/urun/nike-blazer-low-77-vintage/duzenle'),
    demoView: path(ROOTS_DASHBOARD, '/katalog/urun/nike-air-force-1-ndestrukt'),
  },
  category: {
    root: path(ROOTS_DASHBOARD, '/kategori'),
    new: path(ROOTS_DASHBOARD, '/kategori/yeni'),
    list: path(ROOTS_DASHBOARD, '/kategori/liste'),
    view: (id) => path(ROOTS_DASHBOARD, `/kategori/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/kategori/${id}/duzenle`),
  },
  customExercise: {
    root: path(ROOTS_DASHBOARD, '/egzersiz'),
    new: path(ROOTS_DASHBOARD, '/egzersiz/yeni'),
    list: path(ROOTS_DASHBOARD, '/egzersiz/liste'),
    view: (id) => path(ROOTS_DASHBOARD, `/egzersiz/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/egzersiz/${id}/duzenle`),
  },
  exercise: {
    root: path(ROOTS_DASHBOARD, '/sistem-egzersizi'),
    new: path(ROOTS_DASHBOARD, '/sistem-egzersizi/yeni'),
    list: path(ROOTS_DASHBOARD, '/sistem-egzersizi/liste'),
    view: (id) => path(ROOTS_DASHBOARD, `/sistem-egzersizi/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/sistem-egzersizi/${id}/duzenle`),
  },
  test: {
    root: path(ROOTS_DASHBOARD, '/test'),
    new: path(ROOTS_DASHBOARD, '/test/yeni'),
    list: path(ROOTS_DASHBOARD, '/test/liste'),
    view: (id) => path(ROOTS_DASHBOARD, `/test/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/test/${id}/duzenle`),
    newGroup: path(ROOTS_DASHBOARD, '/test/toplu-ekle'),
  },
  curriculum: {
    root: path(ROOTS_DASHBOARD, '/mufredat'),
    new: path(ROOTS_DASHBOARD, '/mufredat/yeni'),
    import: path(ROOTS_DASHBOARD, '/mufredat/yukle'),
    list: path(ROOTS_DASHBOARD, '/mufredat/liste'),
    view: (id) => path(ROOTS_DASHBOARD, `/mufredat/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/mufredat/${id}/duzenle`),
  },
  curriculumItem: {
    root: path(ROOTS_DASHBOARD, '/mufredat-gunleri'),
    new: path(ROOTS_DASHBOARD, '/mufredat-gunleri/yeni'),
    edit: (id) => path(ROOTS_DASHBOARD, `/mufredat-gunleri/${id}/duzenle`),
  },
  packageBuy: {
    root: path(ROOTS_DASHBOARD, '/paket-satin-al'),
  },
  playground: {
    root: path(ROOTS_DASHBOARD, '/playground'),
  },
  level: {
    root: path(ROOTS_DASHBOARD, '/seviye'),
    new: path(ROOTS_DASHBOARD, '/seviye/yeni'),
    list: path(ROOTS_DASHBOARD, '/seviye/liste'),
    edit: (id) => path(ROOTS_DASHBOARD, `/seviye/${id}/duzenle`),
  },
  product: {
    root: path(ROOTS_DASHBOARD, '/urun'),
    list: path(ROOTS_DASHBOARD, '/urun/liste'),
    new: path(ROOTS_DASHBOARD, '/urun/yeni'),
    view: (name) => path(ROOTS_DASHBOARD, `/urun/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/urun/${name}/duzenle`),
  },
  coupon: {
    root: path(ROOTS_DASHBOARD, '/kupon'),
    list: path(ROOTS_DASHBOARD, '/kupon/liste'),
    new: path(ROOTS_DASHBOARD, '/kupon/yeni'),
    view: (id) => path(ROOTS_DASHBOARD, `/kupon/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/kupon/${id}/duzenle`),
  },
  order: {
    root: path(ROOTS_DASHBOARD, '/siparis'),
    list: path(ROOTS_DASHBOARD, '/siparis/liste'),
    view: (ohuid, query) => path(ROOTS_DASHBOARD, `/siparis/${ohuid}?${query || ''}`),
  },
  competition: {
    root: path(ROOTS_DASHBOARD, '/yarisma'),
    active: path(ROOTS_DASHBOARD, '/yarisma/aktif'),
    achievements: path(ROOTS_DASHBOARD, '/basarilar'),
  },
  membership: {
    root: path(ROOTS_DASHBOARD, '/abonelik'), // TODO: /abonelik
    active: path(ROOTS_DASHBOARD, '/abonelik/aktif'), // TODO: /abonelik
    view: (id) => path(ROOTS_DASHBOARD, `/abonelik/${id}`), // TODO: /abonelik
  },
  membershipUser: {
    view: (mid, id) => path(ROOTS_DASHBOARD, `/abonelik/${mid}/uye/${id}`),
  },
  content: {
    root: path(ROOTS_DASHBOARD, '/icerik'),
    index: path(ROOTS_DASHBOARD, '/icerik/yonetim'),
  },
  contentGroup: {
    root: path(ROOTS_DASHBOARD, '/icerik/grup'),
    new: path(ROOTS_DASHBOARD, '/icerik/grup/yeni'),
    list: path(ROOTS_DASHBOARD, '/icerik/grup/liste'),
    view: (id) => path(ROOTS_DASHBOARD, `/icerik/grup/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/icerik/grup/${id}/duzenle`),
  },
  imageContent: {
    root: path(ROOTS_DASHBOARD, '/icerik/gorsel'),
    new: path(ROOTS_DASHBOARD, '/icerik/gorsel/yeni'),
    newGroup: path(ROOTS_DASHBOARD, '/icerik/gorsel/grup-ekle'),
    list: path(ROOTS_DASHBOARD, '/icerik/gorsel/liste'),
    view: (id) => path(ROOTS_DASHBOARD, `/icerik/gorsel/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/icerik/gorsel/${id}/duzenle`),
  },
  textContent: {
    root: path(ROOTS_DASHBOARD, '/icerik/metin'),
    new: path(ROOTS_DASHBOARD, '/icerik/metin/yeni'),
    newGroup: path(ROOTS_DASHBOARD, '/icerik/metin/toplu-ekle'),
    list: path(ROOTS_DASHBOARD, '/icerik/metin/liste'),
    view: (id) => path(ROOTS_DASHBOARD, `/icerik/metin/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/icerik/metin/${id}/duzenle`),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
