export type Casing = 'capitalize' | 'upper' | 'lower';

export function c(
  casing: Casing,
  capitalized: string,
  upper: string,
  lower: string) {
  return casing === 'capitalize' ? capitalized : casing === 'upper' ? upper : lower;
}

type EnumStringifier<TEnum extends number> = {
  [key in TEnum]: {
    'capitalize': string;
    'upper': string;
    'lower': string;
  }
};

export function enumText<TEnum extends number, TEnumStringifier extends EnumStringifier<TEnum>>(
  value: TEnum,
  stringifier: TEnumStringifier,
  casing: 'capitalize' | 'upper' | 'lower' = 'capitalize') {
  return stringifier[value][casing];
}

type Stringifier<TKeys extends string | number | symbol> = {
  [key in TKeys]: {
    'capitalize': string;
    'upper': string;
    'lower': string;
  }
};

export function getText<TKey extends string, TStringifier extends Stringifier<TKey>>(
  value: TKey,
  stringifier: TStringifier,
  casing: 'capitalize' | 'upper' | 'lower' = 'capitalize') {
  return stringifier[value][casing];
}