import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { createContext, PropsWithChildren, useEffect, useReducer } from 'react';
import { DataApiResponse } from '../contract/base/Response';
import { ResponseModel, SuccessResponseModel } from '../contract/base/ResponseModel';
import { ClaimTypes } from '../contract/constants/ClaimTypes';
import { clearSelfUser } from '../redux/slices/user/user';
import { axiosGetResponseModel, axiosPostResponseModel } from '../services/base/baseService';
import { getSelfClaims } from '../services/identity/user/getSelfClaims';
import { ResponseModelError } from '../types/ResponseModelError';
import axiosInstance from '../utils/axios';
import { isValidToken, Jwt, setRefreshToken, setSession } from '../utils/jwt';

type AccessTokenResponse = {
  accessToken: string;
  expiresIn: number;
  // tokenType: string;
  // refreshToken: string;
};

type UserInfoResponse = {
  claims: ClaimDetail[];
};

type ClaimDetail = {
  issuer: string;
  originalIssuer: string;
  properties: { [key: string]: string; };
  subject: string;
  type: string;
  value: string;
  valueType: string;
};

type UserInfo = {
  [ClaimTypes.organizationId]: string;
  email: string;
  phoneNumber?: string;
  exp: number;
  given_name: string;
  family_name: string;
  // [ClaimTypes.hasActiveMembership]: string;
  iat: string;
  nameid: string;
  nbf: string;
  role: string;
};

type JWTState = {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: UserInfo | null;
}

const initialState: JWTState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

function mapClaimsToUser(claims: ClaimDetail[]): UserInfo {
  let user: { [key: string]: unknown } = {};
  for (let i = 0; i < claims.length; i++) {
    const item = claims[i];
    // const itemType = item.type;
    const getItemType = (item: ClaimDetail) => item.properties['http://schemas.xmlsoap.org/ws/2005/05/identity/claimproperties/ShortTypeName'] || item.type;
    const itemType = getItemType(item);
    const count = claims.filter(x => getItemType(x) === itemType).length;

    if (count > 1) {
      if (itemType in user) {
        user[itemType] = [...user[itemType] as any, item.value];
      }
      else {
        user[itemType] = [item.value];
      }
    }
    else {
      user[itemType] = item.value;
    }
  }

  // Old impl
  // const user = claims.reduce((prev, cur) => {
  //   (prev as any)[cur.type] = cur.value;

  //   return prev;
  // }, {}) as UserInfo;

  return user as UserInfo;
}

const jwtslice = createSlice({
  name: 'jwt',
  initialState,
  reducers: {
    INITIALIZE: (state, action: PayloadAction<Pick<JWTState, "isAuthenticated" | "user">>) => {
      const { isAuthenticated, user } = action.payload;

      state.isInitialized = true;
      state.isAuthenticated = isAuthenticated;
      state.user = user;

      // return {
      //   ...state,
      //   isAuthenticated,
      //   isInitialized: true,
      //   user,
      // };
    },
    LOGIN: (state, action: PayloadAction<Pick<JWTState, "user">>) => {
      const { user } = action.payload;

      state.isAuthenticated = true;
      state.user = user;
      // return {
      //   ...state,
      //   isAuthenticated: true,
      //   user,
      // };
    },
    LOGOUT: (state) => {
      state.isAuthenticated = false;
      state.user = null;

      // ...state,
      // isAuthenticated: false,
      // user: null,
    },
    // REGISTER: (state, action) => {
    //   const { user } = action.payload;

    //   return {
    //     ...state,
    //     isAuthenticated: true,
    //     user,
    //   };
    // },
  }
});

const reducer = jwtslice.reducer;

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: (username: string, password: string) => Promise.resolve(),
  loginAccessToken: (accessToken: string) => Promise.resolve(),
  logout: () => Promise.resolve(),
  refresh: (refreshToken: string) => Promise.resolve(),
});

function AuthProvider({ children }: PropsWithChildren<{}>) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        // const refreshToken = window.localStorage.getItem('refreshToken');

        if (accessToken && isValidToken(accessToken)
          // && refreshToken
        ) {
          setSession(accessToken, () => refresh(/*refreshToken*/));
          // setRefreshToken(refreshToken);

          const response = await getSelfClaims();
          // const response: ResponseModel<UserInfoResponse> = {
          //   data: {
          //     claims: [
          //       // {}
          //     ],
          //   },
          //   isSuccess: true,
          //   totalRowCount: 1
          // };

          if (response.success) {
            const user = mapClaimsToUser(response.datas);

            dispatch(jwtslice.actions.INITIALIZE({
              isAuthenticated: true,
              user: user,
            }));
            // dispatch({
            //   type: 'INITIALIZE',
            //   payload: {
            //     isAuthenticated: true,
            //     user,
            //   },
            // });

          }
          else {
            dispatch(jwtslice.actions.INITIALIZE({
              isAuthenticated: false,
              user: null,
            }));
            // dispatch({
            //   type: 'INITIALIZE',
            //   payload: {
            //     isAuthenticated: false,
            //     user: null,
            //   },
            // });
          }
        }
        else {
          dispatch(jwtslice.actions.INITIALIZE({
            isAuthenticated: false,
            user: null,
          }));
          // dispatch({
          //   type: 'INITIALIZE',
          //   payload: {
          //     isAuthenticated: false,
          //     user: null,
          //   },
          // });
        }
      } catch (err) {
        console.error(err);
        dispatch(jwtslice.actions.INITIALIZE({
          isAuthenticated: false,
          user: null,
        }));
        // dispatch({
        //   type: 'INITIALIZE',
        //   payload: {
        //     isAuthenticated: false,
        //     user: null,
        //   },
        // });
      }
    };

    initialize();
  }, []);

  const login = async (username: string, password: string) => {
    // TODO: ...
    // const response = await axiosPostResponseModel<AccessTokenResponse>('/api/connect/accessToken', {
    //   username: username,
    //   password: password,
    // });
    const response: ResponseModel<AccessTokenResponse> = {
      data: {
        accessToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiIxIiwiZ2l2ZW5fbmFtZSI6IlN5c3RlbSIsImZhbWlseV9uYW1lIjoiQWRtaW4iLCJlbWFpbCI6InN5c2FkbWluQGV0a2lub2t1bWEuY29tIiwicm9sZSI6IlN5c3RlbUFkbWluIiwibmJmIjoxNjU5MDExMzE1LCJleHAiOjE2NTk2MTYxMTUsImlhdCI6MTY1OTAxMTMxNX0.L-r_8DUr8zNEHfqYVNLwVILbXaFPo037_6ljIN7UffM',
        expiresIn: 9999999999999,
        // refreshToken: '8C41BE9F4239EAA870585CFBA33171691A6FD24D429851B9188143AD49B2C562',
        // tokenType: 'Bearer'
      },
      isSuccess: true,
      totalRowCount: 1
    };

    // console.log('Access Token Response', response);

    if (response.isSuccess) {
      const {
        accessToken,
        // refreshToken
      } = response.data;

      setSession(accessToken, () => refresh(/*refreshToken*/));
      // setRefreshToken(refreshToken);

      // const userInfoResp = await axiosGetResponseModel<UserInfoResponse>('/api/connect/userInfo', {
      //   headers: {
      //     'Authorization': `Bearer ${accessToken}`
      //   }
      // });
      const userInfoResp: ResponseModel<UserInfoResponse> = {
        data: {
          claims: [
            // {}
          ],
        },
        isSuccess: true,
        totalRowCount: 1
      };

      if (userInfoResp.isSuccess) {
        const user = mapClaimsToUser(userInfoResp.data.claims);

        dispatch(jwtslice.actions.LOGIN({
          user: user,
        }));
        // dispatch({
        //   type: 'LOGIN',
        //   payload: {
        //     user: {
        //       // TODO: User info here...
        //     },
        //   },
        // });
      }
    }
    else {
      throw new ResponseModelError(response);
    }
  };


  const loginAccessToken = async (accessToken: string) => {
    const jwt = jwtDecode<Jwt>(accessToken);
    const response: ResponseModel<AccessTokenResponse> = {
      data: {
        accessToken: accessToken,
        expiresIn: Date.now() - (jwt.exp * 1000),
        // refreshToken: accessToken,
        // tokenType: 'Bearer'
      },
      isSuccess: true,
      totalRowCount: 1
    };

    // console.log('Access Token Response', response);

    if (response.isSuccess) {
      const {
        accessToken,
        // refreshToken
      } = response.data;

      setSession(accessToken, () => refresh(/*refreshToken*/));
      // setRefreshToken(refreshToken);

      const userInfoResp = await getSelfClaims();

      if (userInfoResp.success) {
        const user = mapClaimsToUser(userInfoResp.datas);

        dispatch(jwtslice.actions.LOGIN({
          user: user,
        }));
        // dispatch({
        //   type: 'LOGIN',
        //   payload: {
        //     user: {
        //       // TODO: User info here...
        //     },
        //   },
        // });
      }
    }
    else {
      throw new ResponseModelError(response);
    }
  };

  const refresh = async (
    // refreshToken: string
  ) => {
    // const response = await axiosPostResponseModel<AccessTokenResponse>('/api/connect/refreshToken', {
    //   refreshToken: refreshToken,
    // });

    const response: ResponseModel<AccessTokenResponse> = {
      data: {
        accessToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiIxIiwiZ2l2ZW5fbmFtZSI6IlN5c3RlbSIsImZhbWlseV9uYW1lIjoiQWRtaW4iLCJlbWFpbCI6InN5c2FkbWluQGV0a2lub2t1bWEuY29tIiwicm9sZSI6IlN5c3RlbUFkbWluIiwibmJmIjoxNjU5MDExMzE1LCJleHAiOjE2NTk2MTYxMTUsImlhdCI6MTY1OTAxMTMxNX0.L-r_8DUr8zNEHfqYVNLwVILbXaFPo037_6ljIN7UffM',
        expiresIn: 9999999999999,
        // refreshToken: '123',
        // tokenType: 'Bearer'
      },
      isSuccess: true,
      totalRowCount: 1
    };

    // console.log('Refresh token response', response);
    if (response.isSuccess) {
      const {
        accessToken,
        // refreshToken
      } = response.data;

      setSession(accessToken, () => refresh(/*refreshToken*/));
      // setRefreshToken(refreshToken);

      // const userInfoResp = await axiosGetResponseModel<UserInfoResponse>('/api/connect/userInfo', {
      //   headers: {
      //     'Authorization': `Bearer ${accessToken}`
      //   }
      // });
      const userInfoResp: ResponseModel<UserInfoResponse> = {
        data: {
          claims: [
            // {}
          ],
        },
        isSuccess: true,
        totalRowCount: 1
      };

      if (userInfoResp.isSuccess) {
        const user = mapClaimsToUser(userInfoResp.data.claims);

        dispatch(jwtslice.actions.LOGIN({
          user: user,
        }));
      }
      else {
        // console.log('Going to sign user out...');
        setSession(null);
        setRefreshToken(null);
        dispatch(jwtslice.actions.INITIALIZE({
          isAuthenticated: false,
          user: null,
        }));
      }
    }
    else {
      // console.log('Going to sign user out...');
      setSession(null);
      setRefreshToken(null);
      dispatch(jwtslice.actions.INITIALIZE({
        isAuthenticated: false,
        user: null,
      }));
    }
  };

  const logout = async () => {
    dispatch(clearSelfUser());
    setSession(null);
    setRefreshToken(null);
    dispatch(jwtslice.actions.LOGOUT());
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        loginAccessToken,
        logout,
        refresh
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };

