import _mock from './_mock';
import { _response } from './_response';
import { randomInArray, randomNumberRange } from './funcs';
import { CurriculumDto } from '../contract/curriculum/CurriculumDto';


export const _curriculums: CurriculumDto[] = [...Array(60)].map((_, index) => ({
  id: index+1,
  avatarUrl: _mock.image.avatar(index),
  name: _mock.name.fullName(index),
  email: _mock.email(index),
  phoneNumber: _mock.phoneNumber(index),
  address: '908 Jack Locks',
  country: _mock.address.country(index),
  state: 'Virginia',
  city: 'Rancho Cordova',
  zipCode: '85807',
  company: _mock.company(index),
  isVerified: _mock.boolean(index),
  status: randomInArray(['active', 'banned']),
  role: _mock.role(index),
}));

export const _curriculum: CurriculumDto = _curriculums[0];


export async function _listCurriculums() {
  await new Promise((resolve) => setTimeout(resolve, 500));

  return _response({
    data: _curriculums,
    totalRowCount: _curriculums.length
  });
}

export async function _getCurriculum(id: number) {
  await new Promise((resolve) => setTimeout(resolve, 500));

  return _response({
    data: {
      ..._curriculum,
      id: id
    },
    totalRowCount: _curriculums.length
  });
}

export async function _createCurriculum(request: any) {
  await new Promise((resolve) => setTimeout(resolve, 500));

  return _response({
    data: {
      ..._curriculum,
    },
    totalRowCount: _curriculums.length
  });
}

export async function _updateCurriculum(id: number, request: any) {
  await new Promise((resolve) => setTimeout(resolve, 500));

  return _response({
    data: {
      ..._curriculum,
      id: id
    },
    totalRowCount: _curriculums.length
  });
}