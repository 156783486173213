import { useEffect } from 'react';
import { getCurrentUserCurriculumItemSuccess, getUserCurriculumItemSuccess, startLoadingCurrentUserCurriculumItem, startLoadingUserCurriculumItem } from '../../redux/slices/student';
import { useDispatch, useSelector } from '../../redux/store';
import { getCurrentUserCurriculumItem } from '../../services/lms/membership/userCurriculumItem/getCurrentUserCurriculumItem';
import { useStatelesFetch, useStatelesFetch2 } from '../useFetch';
import { getUserCurriculumItem } from '../../services/lms/membership/userCurriculumItem/getUserCurriculumItem';
import { DataApiResponse } from '../../contract/base/Response';
import { UserCurriculumItemInfo } from '../../contract/lms/membership/UserCurriculumItem';

export default function useUserCurriculumItem(options: {
  load: boolean;
  membershipId: number;
  userCurriculumItemId?: number;
}) {
  const { load, membershipId, userCurriculumItemId, } = options;

  const { fetch } = useStatelesFetch2<DataApiResponse<UserCurriculumItemInfo>>({
    // fetcher: !userCurriculumItemId ? getCurrentUserCurriculumItem : getUserCurriculumItem,
  }, membershipId, userCurriculumItemId);

  const currentUserCurriculumItemList = useSelector(x => x.student.currentUserCurriculumItemList);
  const userCurriculumItemList = useSelector(x => x.student.userCurriculumItemList);
  const dispatch = useDispatch();

  useEffect(() => {
    doFetch();
  }, [load, membershipId, userCurriculumItemId]);

  async function doFetch() {
    if (!load) {
      return;
    }

    if (userCurriculumItemId) {
      if (membershipId in userCurriculumItemList &&
        userCurriculumItemId in userCurriculumItemList[membershipId] &&
        userCurriculumItemList[membershipId][userCurriculumItemId].loading) {
        return;
      }

      // TODO: We might want to force reload this data???
      if (membershipId in userCurriculumItemList &&
        userCurriculumItemId in userCurriculumItemList[membershipId] &&
        !!userCurriculumItemList[membershipId][userCurriculumItemId].userCurriculumItem) {
        return;
      }

      // TODO: We might want to force reload this data???
      if (membershipId in userCurriculumItemList &&
        userCurriculumItemId in userCurriculumItemList[membershipId] &&
        !!userCurriculumItemList[membershipId][userCurriculumItemId].loading) {
        return;
      }

      dispatch(startLoadingUserCurriculumItem({
        membershipId: membershipId,
        userCurriculumItemId: userCurriculumItemId,
      }));

      let response = await fetch(!userCurriculumItemId ? getCurrentUserCurriculumItem : getUserCurriculumItem);
      if (response?.success) {
        dispatch(getUserCurriculumItemSuccess({
          membershipId: membershipId,
          userCurriculumItemId: userCurriculumItemId,
          userCurriculumItem: response.data,
        }));
      }
    }
    else {
      if (membershipId in currentUserCurriculumItemList &&
        currentUserCurriculumItemList[membershipId].loading) {
        return;
      }

      // TODO: We might want to force reload this data???
      if (membershipId in currentUserCurriculumItemList &&
        !!currentUserCurriculumItemList[membershipId].userCurriculumItem) {
        return;
      }

      // TODO: We might want to force reload this data???
      if (membershipId in currentUserCurriculumItemList &&
        !!currentUserCurriculumItemList[membershipId].loading) {
        return;
      }

      dispatch(startLoadingCurrentUserCurriculumItem({
        membershipId: membershipId,
      }));

      let response = await fetch(!userCurriculumItemId ? getCurrentUserCurriculumItem : getUserCurriculumItem);
      if (response?.success) {
        dispatch(getCurrentUserCurriculumItemSuccess({
          membershipId: membershipId,
          userCurriculumItem: response.data,
        }));
      }
    }
  }

  // console.log('User curriculum item id', userCurriculumItemId);

  return !userCurriculumItemId ? {
    ...currentUserCurriculumItemList[membershipId]
  } : {
    ...(userCurriculumItemList[membershipId] ? userCurriculumItemList[membershipId][userCurriculumItemId] : {})
  };
}