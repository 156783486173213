import { m } from 'framer-motion';
// @mui
import { Container, Typography } from '@mui/material';
// hooks
import useAuth from '../hooks/useAuth';
// components
import { MotionContainer, varBounce } from '../components/animate';
// assets
import { PropsWithChildren, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { ForbiddenIllustration } from '../assets';
import Roles from '../contract/constants/Roles';

export type RoleBasedGuardProps = PropsWithChildren<{
  hasContent?: boolean;
  roles?: string[];
  redirectTo?: string | ((role: string) => string);
}>;

export default function RoleBasedGuard({ hasContent, roles, children, redirectTo }: RoleBasedGuardProps) {
  // Logic here to get current user role
  const { user } = useAuth();

  const navigate = useNavigate();

  // const currentRole = 'user';
  const currentRole = user?.role; // admin;

  useEffect(() => {
    // console.log('Roles', roles);
    // console.log('Roles', redirectTo);
    if (typeof roles !== 'undefined' && !!currentRole && !roles.includes(currentRole) && !!redirectTo) {
      if (typeof redirectTo === 'string') {
        navigate(redirectTo);
      }
      else {
        navigate(redirectTo(currentRole));
      }
    }
  }, [roles, user, redirectTo]);

  if (typeof roles !== 'undefined' && !!currentRole && !roles.includes(currentRole)) {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            Yetkisiz Erişim Reddedildi
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>Bu sayfaya erişim yetkiniz yok</Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>
      </Container>
    ) : null;
  }

  return <>{children}</>;
}

export function BuyerGuard(props: Omit<RoleBasedGuardProps, "roles">) {
  return (
    <RoleBasedGuard {...props} roles={[Roles.Student, Roles.Parent, Roles.OrganizationAdmin]}>{props.children}</RoleBasedGuard>
  );
}

export function StudentGuard(props: Omit<RoleBasedGuardProps, "roles">) {
  return (
    <RoleBasedGuard {...props} roles={[Roles.Student]}>{props.children}</RoleBasedGuard>
  );
}

export function OrganizationAdminGuard(props: Omit<RoleBasedGuardProps, "roles">) {
  return (
    <RoleBasedGuard {...props} roles={[Roles.OrganizationAdmin]}>{props.children}</RoleBasedGuard>
  );
}

export function SystemAdminGuard(props: Omit<RoleBasedGuardProps, "roles">) {
  return (
    <RoleBasedGuard {...props} roles={[Roles.SystemAdmin, Roles.PortalAdmin]}>{props.children}</RoleBasedGuard>
  );
}