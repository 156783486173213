import { ListApiResponse } from '../../../../contract/base/Response';
import { UserCurriculumItemTestList } from '../../../../contract/lms/membership/UserCurriculumItemTest';
import axiosInstance from '../../../../utils/axios';
import { handleError } from '../../../base/baseService';

export async function getMembershipUserCurriculumItemTests(
  membershipId: number,
  itemId: number) {
  try {
    const response = await axiosInstance.get<ListApiResponse<UserCurriculumItemTestList>>(`/memberships/${membershipId}/items/${itemId}/tests`);

    return response.data;
  }
  catch (error) {
    return handleError(error);
  }
}