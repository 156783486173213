// routes
import { PATH_AUTH, PATH_DOCS, PATH_PAGE } from '../../routes/paths';
// components
import { PATH_AFTER_LOGIN } from '../../config';
// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

// const menuConfig = [
//   {
//     title: 'Ana Sayfa',
//     icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
//     path: '/',
//   },
//   {
//     title: 'Sayfalar',
//     path: '/sayfalar',
//     icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />,
//     children: [
//       {
//         subheader: 'Kurumsal',
//         items: [
//           { title: 'Hakkımızda', path: PATH_PAGE.about },
//           { title: 'İletişim', path: PATH_PAGE.contact },
//           { title: 'SSS', path: PATH_PAGE.faqs },
//           { title: 'Paketler', path: PATH_PAGE.packageBuyGuest },
//           { title: 'Payment', path: PATH_PAGE.payment },
//           { title: 'Bakım Yapılıyor', path: PATH_PAGE.maintenance },
//           { title: 'Coming Soon', path: PATH_PAGE.comingSoon },
//         ],
//       },
//       {
//         subheader: 'Kimlik',
//         items: [
//           { title: 'Giriş Yap', path: PATH_AUTH.loginUnprotected },
//           { title: 'Kayıt Ol', path: PATH_AUTH.registerUnprotected },
//           { title: 'Parola Sıfırla', path: PATH_AUTH.resetPassword },
//           { title: 'E-postanı Doğrula', path: PATH_AUTH.verify },
//         ],
//       },
//       {
//         subheader: 'Hata',
//         items: [
//           { title: 'Page 403', path: PATH_PAGE.page403 },
//           { title: 'Page 404', path: PATH_PAGE.page404 },
//           { title: 'Page 500', path: PATH_PAGE.page500 },
//         ],
//       },
//       {
//         subheader: 'Panel',
//         items: [{ title: 'Panel', path: PATH_AFTER_LOGIN }],
//       },
//     ],
//   },
//   // {
//   //   title: 'Documentation',
//   //   icon: <Iconify icon={'eva:book-open-fill'} {...ICON_SIZE} />,
//   //   path: PATH_DOCS,
//   // },
//   {
//     title: 'Giriş Yap',
//     icon: <Iconify icon={'eva:log-in-fill'} {...ICON_SIZE} />,
//     path: PATH_AUTH.login,
//   },
// ];

const menuConfig = [
  {
    title: 'Ana Sayfa',
    // icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    icon: 'eva:home-fill',
    iconSize: { ICON_SIZE },
    path: '/',
  },
  {
    title: 'Kurumsal',
    icon: 'eva:file-fill',
    iconSize: { ICON_SIZE },
    children: [
      {
        subheader: 'Kurumsal',
        items: [
          { title: 'Hakkımızda', path: PATH_PAGE.about },
          { title: 'İletişim', path: PATH_PAGE.contact },
          { title: 'SSS', path: PATH_PAGE.faqs },
          { title: 'Paketler', path: PATH_PAGE.packageBuyGuest },
          // { title: 'Bakım Yapılıyor', path: PATH_PAGE.maintenance },
          // { title: 'Coming Soon', path: PATH_PAGE.comingSoon },
        ],
      }
    ],
  },
  {
    title: 'Üyelik',
    icon: 'eva:person-fill',
    iconSize: { ICON_SIZE },
    children: [
      {
        subheader: 'Üyelik',
        items: [
          { title: 'Giriş Yap', path: PATH_AUTH.login },
          { title: 'Kayıt Ol', path: PATH_AUTH.register },
          { title: 'Parola Sıfırla', path: PATH_AUTH.resetPassword },
          { title: 'E-postanı Doğrula', path: PATH_AUTH.verify },
        ],
      }
    ],
  },
  // {
  //   title: 'Documentation',
  //   icon: <Iconify icon={'eva:book-open-fill'} {...ICON_SIZE} />,
  //   path: PATH_DOCS,
  // },
  // {
  //   title: 'Satın Al',
  //   icon: 'eva:shopping-cart-fill',
  //   iconSize: { ICON_SIZE },
  //   path: PATH_AUTH.login,
  // },
];

export default menuConfig;
