import { Avatar, Box, Chip, Link, ListItemText } from '@mui/material';
import { MouseEventHandler, useEffect } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { isExternalLink } from '..';
import Iconify from '../../Iconify';
import { PlaygroundNavConfigGroupItem } from './Config';
import { ListItemIconStyle, ListItemStyle, ListItemTextStyle } from './style';

type PlaygroundNavItemRootProps = {
  item: PlaygroundNavConfigGroupItem;
  isCollapse: boolean;
  open?: boolean;
  active: boolean;
  activeColor?: string;
  onOpen?: () => void;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export function PlaygroundNavItemRoot({
  item,
  isCollapse,
  open = false,
  active,
  activeColor,
  onOpen,
  onClick,
}: PlaygroundNavItemRootProps) {
  const { title, path, icon, info, children } = item;

  const renderContent = (
    <>
      {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}
      <ListItemTextStyle
        disableTypography
        primary={title}
        // sx={{
        //   fontSize: 10,
        // }}
        onClick={onClick}
        // @ts-ignore
        isCollapse={isCollapse}
      />
      {!isCollapse && (
        <>
          {info && info}
          {children && <ArrowIcon open={open} />}
        </>
      )}
    </>
  );

  if (children) {
    return (
      <ListItemStyle
        onClick={onOpen}
        // @ts-ignore
        activeRoot={active}
        disabled={item.disabled}
        activeColor={activeColor}
      >
        {renderContent}
      </ListItemStyle>
    );
  }

  return isExternalLink(path) ? (
    <ListItemStyle
      // @ts-ignore
      component={Link}
      href={path}
      target="_blank"
      rel="noopener"
      onClick={onClick}
    >
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle
      // @ts-ignore
      component={RouterLink}
      to={path}
      activeRoot={active}
      activeColor={activeColor}
      onClick={onClick}
    >
      {renderContent}
    </ListItemStyle>
  );
}


type PlaygroundNavItemSubProps = {
  parent: PlaygroundNavConfigGroupItem;
  order: number;
  item: PlaygroundNavConfigGroupItem;
  open?: boolean;
  active?: boolean;
  onOpen?: () => void;
};

export function PlaygroundNavItemSub({
  parent,
  order,
  item,
  open = false,
  active = false,
  onOpen,
}: PlaygroundNavItemSubProps) {
  const { title, path, info, children, onClick, disabled, isCompleted } = item;

  // useEffect(() => {
  //   console.log('Me order ist', {
  //     t: item.title,
  //     order
  //   });
  // }, [order]);

  const renderContent = (
    <>
      <DotIcon
        order={order}
        active={active}
        activeColor={item.activeColor}
      />
      <ListItemText disableTypography primary={title} sx={{
        textDecoration: isCompleted ? 'line-through' : 'unset',

      }} />
      {info && info}
      {children && <ArrowIcon open={open} />}
    </>
  );

  if (children) {
    return (
      <ListItemStyle
        onClick={onOpen}
        disabled={disabled}

        // @ts-ignore
        activeSub={active}

        subItem>
        {renderContent}
      </ListItemStyle>
    );
  }

  return isExternalLink(path) ? (
    <ListItemStyle
      // @ts-ignore
      component={Link}
      disabled={disabled}
      href={path}
      target="_blank"
      rel="noopener"
      subItem
      onClick={onClick}
    >
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle
      // @ts-ignore
      component={RouterLink}
      disabled={disabled}
      to={path}
      activeSub={active}
      subItem
      onClick={onClick}
    >
      {renderContent}
    </ListItemStyle>
  );
}


type DotIconProps = {
  order: number;
  active: boolean;
  activeColor?: string;
};

export function DotIcon({ order, active, activeColor, }: DotIconProps) {
  return (
    <Avatar
      sx={{
        bgcolor: 'text.disabled',
        color: 'black',
        width: 24,
        height: 24,
        fontSize: 14,
        fontWeight: (theme) => theme.typography.fontWeightBold,
        mr: 2,
        transition: (theme) =>
          theme.transitions.create('transform', {
            duration: theme.transitions.duration.shorter,
          }),
        ...(active && {
          // transform: 'scale(2)',
          bgcolor: activeColor || 'primary.main',
          color: (theme) => theme.palette.getContrastText(activeColor || theme.palette.primary.main),
        }),
      }}
    // size="small"
    >
      {order}
    </Avatar>
  );
  return (
    <Chip
      sx={{
        bgcolor: 'text.disabled',
        mr: 2,
        transition: (theme) =>
          theme.transitions.create('transform', {
            duration: theme.transitions.duration.shorter,
          }),
        ...(active && {
          // transform: 'scale(2)',
          bgcolor: activeColor || 'primary.main',
        }),
      }}
      size="small"
      label={order}
    />
  );

  return (
    <ListItemIconStyle>
      <Chip
        sx={{
          bgcolor: 'text.disabled',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            // transform: 'scale(2)',
            bgcolor: activeColor || 'primary.main',
          }),
        }}
        label={1}
      />
      {/* <Box
        component="span"
        sx={{
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'text.disabled',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: 'scale(2)',
            bgcolor: activeColor || 'primary.main',
          }),
        }}
      /> */}
    </ListItemIconStyle>
  );
}


type ArrowIconProps = {
  open?: boolean;
};

export function ArrowIcon({ open }: ArrowIconProps) {
  return (
    <Iconify
      icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
      sx={{ width: 16, height: 16, ml: 1 }}
    />
  );
}
