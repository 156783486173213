import { PropsWithChildren } from 'react';
import { FieldValues, FormProvider as Form, UseFormReturn } from 'react-hook-form';

export default function FormProvider<TFieldValues extends FieldValues = FieldValues, TContext = any>(props: PropsWithChildren<{
  onSubmit?: any;
  methods: UseFormReturn<TFieldValues, TContext>;
}>) {
  const { children, onSubmit, methods } = props;
  return (
    <Form {...methods}>
      <form onSubmit={onSubmit}>{children}</form>
    </Form>
  );
}
