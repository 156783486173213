import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CurriculumDto } from '../../../contract/curriculum/CurriculumDto';
// TODO: ...

import { _listCurriculums } from '../../../_mock/_curriculums';
import { dispatch, 
  // store 
} from '../../store';

type State = {
  isLoading: boolean;
  errors: string[] | null;
  curriculumList: CurriculumDto[] | null;
};

const initialState: State = {
  isLoading: false,
  errors: null,
  curriculumList: null,
};

const slice = createSlice({
  name: 'curriculum',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasErrors(state, action: PayloadAction<string[]>) {
      state.isLoading = false;
      state.errors = action.payload;
    },

    // GET CUSTOM FILES
    getCurriculumSuccess(state, action: PayloadAction<{ curriculums: CurriculumDto[]; }>) {
      state.isLoading = false;
      state.curriculumList = action.payload.curriculums;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
} = slice.actions;

export function getCurriculums() {
  return async () => {
    dispatch(slice.actions.startLoading());

    // TODO: ...
    // const response = await listCustomFields();
    const response = await _listCurriculums();

    if (response.isSuccess) {
      dispatch(slice.actions.getCurriculumSuccess({
        curriculums: response.data,
      }));
    }
    else {
      dispatch(slice.actions.hasErrors(response.errorList.map(x => x.description!)));
    }
  };
}