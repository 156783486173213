import { Button } from '@mui/material';
import Iconify from '../../../../components/Iconify';
import usePlaygroundContext from '../../../../hooks/usePlaygroundContext';

export default function PlayButton() {
  const { currentTaskId, mode, active, setActive } = usePlaygroundContext();
  const handleClick = () => {
    setActive(!active);
  };

  return (
    <>
      <Button
        disabled={mode === 'exercise' && !currentTaskId}
        onClick={handleClick}
        sx={{
          // width: 40,
          // height: 40,
          // @ts-ignore -- TODO: ??
          // ...(open && { bgcolor: 'action.selected' }),
        }}
        startIcon={
          !active ? <Iconify icon="eva:play-circle-outline" /> : <Iconify icon="eva:pause-circle-outline" />
        }
        variant="contained"
        color="primary"
      // color="text.primary"
      >
        {!active && "Başla"}
        {!!active && "Duraklat"}
      </Button>
    </>
  );
}
