import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  MenuItem,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { MouseEventHandler, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { IconButtonAnimate } from '../../../components/animate';
import MenuPopover from '../../../components/MenuPopover';
import MyAvatar from '../../../components/MyAvatar';
import { roleText } from '../../../contract/constants/Roles';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import { clearSelfUser } from '../../../redux/slices/user/user';
import { useDispatch } from '../../../redux/store';
import { PATH_AUTH, PATH_DASHBOARD } from '../../../routes/paths';
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';

const helpEntries: HelpEntry[] = [
  { title: 'Kayıt Olma Rehberi', text: 'Lorem ipsum blah blah...', link: '#' },
  { title: 'Kayıt Olma Rehberi', text: 'Lorem ipsum blah blah...', link: '#' },
  { title: 'Kayıt Olma Rehberi', text: 'Lorem ipsum blah blah...', link: '#' },
  { title: 'Kayıt Olma Rehberi', text: 'Lorem ipsum blah blah...', link: '#' },
  { title: 'Kayıt Olma Rehberi', text: 'Lorem ipsum blah blah...', link: '#' },
  { title: 'Kayıt Olma Rehberi', text: 'Lorem ipsum blah blah...', link: '#' },
  { title: 'Kayıt Olma Rehberi', text: 'Lorem ipsum blah blah...', link: '#' },
  { title: 'Kayıt Olma Rehberi', text: 'Lorem ipsum blah blah...', link: '#' },
  { title: 'Kayıt Olma Rehberi', text: 'Lorem ipsum blah blah...', link: '#' },
  { title: 'Kayıt Olma Rehberi', text: 'Lorem ipsum blah blah...', link: '#' },
  { title: 'Kayıt Olma Rehberi', text: 'Lorem ipsum blah blah...', link: '#' },
  { title: 'Kayıt Olma Rehberi', text: 'Lorem ipsum blah blah...', link: '#' },
  { title: 'Kayıt Olma Rehberi', text: 'Lorem ipsum blah blah...', link: '#' },
  { title: 'Kayıt Olma Rehberi', text: 'Lorem ipsum blah blah...', link: '#' },
];

export default function HelpPopover() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, logout } = useAuth();

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState<Element | null>(null);

  const handleOpen: MouseEventHandler<HTMLButtonElement> = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      dispatch(clearSelfUser());
      navigate(PATH_AUTH.login, { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  return (
    <>
      <IconButtonAnimate
        // color={'primary'}
        size="large"
        onClick={handleOpen}
        // sx={{ width: 40, height: 40 }}
        sx={{
          color: (theme) => theme.palette.primary.dark,
          ...(open && {
            color: (theme) => alpha(theme.palette.primary.main, 0.8),
            // '&:before': {
            //   zIndex: 1,
            //   content: "''",
            //   width: '100%',
            //   height: '100%',
            //   borderRadius: '50%',
            //   position: 'absolute',
            //   bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            // },
          }),
        }}
        autoShake={!open}
      >
        {/* <Badge badgeContent={totalUnRead} color="error"> */}
        <Iconify icon="mdi:question-mark-circle" // fontSize={ } 
        />
        {/* </Badge> */}
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Rehberler</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {/* You have {totalUnRead} unread messages */}
            </Typography>
          </Box>

          {/* {totalUnRead > 0 && ( */}
          {/* <Tooltip title=" Mark all as read">
            <IconButton color="primary" onClick={() => { }}>
              <Iconify icon="eva:done-all-fill" width={20} height={20} />
            </IconButton>
          </Tooltip> */}
          {/* )} */}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: 340 }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Yeni
              </ListSubheader>
            }
          >
            {helpEntries.slice(0, 2).map((help, i) => (
              <HelpItem key={i} help={help} />
            ))}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Tamamlanan
              </ListSubheader>
            }
          >
            {helpEntries.slice(2).map((help, i) => (
              <HelpItem key={i} help={help} />
            ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            Tümünü göster
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}


type HelpEntry = {
  title: string;
  text: string;
  link: string;
};

function HelpItem({ help }: { help: HelpEntry; }) {
  const { avatar, title } = renderContent(help);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        // ...(notification.isUnRead && {
        //   bgcolor: 'action.selected',
        // }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {/* {fToNow(notification.createdAt)} */}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

function renderContent(help: HelpEntry) {
  const title = (
    <Typography variant="subtitle2">
      {help.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {help.text}
      </Typography>
    </Typography>
  );

  return {
    avatar: null, // help.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}