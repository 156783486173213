import { m, Variant, Variants } from 'framer-motion';
import { forwardRef, PropsWithChildren, useState } from 'react';
import { Box, IconButton, IconButtonProps } from '@mui/material';
import useTimeout from '../../utils/useTimeout';
import useInterval from '../../utils/useInterval';

type IconButtonAnimateProps = PropsWithChildren<IconButtonProps & {
  autoShake?: boolean;
}>;
const IconButtonAnimate = forwardRef<HTMLButtonElement, IconButtonAnimateProps>(({ children, size = 'medium', autoShake, ...other }, ref) => (
  <AnimateWrap size={size} autoShake={autoShake}>
    <IconButton size={size} ref={ref} {...other}>
      {children}
    </IconButton>
  </AnimateWrap>
));

export default IconButtonAnimate;

// ----------------------------------------------------------------------

// const getRandomDelay = () => -(Math.random() * 0.7 + 0.05);

// const randomDuration = () => Math.random() * 0.07 + 1;

const scaleMin = 0.95;
const scaleMax = 1.05;
const rotateMin = -15;
const rotateMax = 15;


const smin = scaleMin;
const smax = scaleMax;
const rmin = rotateMin;
const rmax = rotateMax;

const scaleOrder = [smax, smin, smax, smin, smax, smin, 1];
const rtateOrder = [0, rmax, rmin, rmax, rmin, rmax, rmin, rmax, rmin, 0];

const dur = 0.7;
const repeatDelay = 10;

const shakeVariant: Variant = {
  // scale: scaleOrder,
  rotate: rtateOrder,
  transition: {
    delay: -1,
    repeat: Infinity,
    duration: dur,
    repeatDelay: repeatDelay,
  }
};

const unShakeVariant: Variant = {
  rotate: 0,
};

const varSmall: Variants = {
  hover: { scale: 1.1 },
  tap: { scale: 0.95 },
  shake: shakeVariant,
  unShake: unShakeVariant,
};

const varMedium: Variants = {
  hover: { scale: 1.09 },
  tap: { scale: 0.97 },
  shake: shakeVariant,
  unShake: unShakeVariant,
};

const varLarge: Variants = {
  hover: { scale: 1.08 },
  tap: { scale: 0.99 },
  shake: shakeVariant,
  unShake: unShakeVariant,
};


function AnimateWrap({ size, children, autoShake }: PropsWithChildren<{ size: string; autoShake?: boolean; }>) {
  const isSmall = size === 'small';
  const isLarge = size === 'large';

  const [tick, setTick] = useState(true);

  return (
    <Box
      component={m.div}
      whileTap="tap"
      whileHover="hover"
      whileInView={autoShake ? (tick ? "shake" : undefined) : 'unshake'}
      variants={(isSmall && varSmall) || (isLarge && varLarge) || varMedium}
      sx={{
        display: 'inline-flex'
      }}
    >
      {children}
    </Box>
  );
}