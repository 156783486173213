import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, Stack } from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import SocialsButton from '../../components/SocialsButton';
import IyzicoIllustration from '../../assets/illustration_iyzico';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'KURUMSAL',
    children: [
      { name: 'Hakkımızda', href: PATH_PAGE.about },
      { name: 'İletişim', href: PATH_PAGE.contact },
      { name: 'Sıkça Sorular Sorular', href: PATH_PAGE.faqs },
    ],
  },
  {
    headline: 'YASAL',
    children: [
      { name: 'Kullanım Koşulları', href: PATH_PAGE.termsOfUse },
      { name: 'Gizlilik Sözleşmesi', href: PATH_PAGE.privacy },
    ],
  },
  {
    headline: 'İletişim',
    children: [
      { name: 'support@etkinokuma.com', href: 'mailto:support@etkinokuma.com', external: true },
      // { name: 'Los Angeles, 359  Hidden Valley Road', href: '#' },
    ],
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />
      <Container sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
          </Grid>
          <Grid item xs={8} md={3}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              Etkin Okuma anlayarak hızlı okuma ve eğitim platformudur.
            </Typography>

            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
            >
              <SocialsButton sx={{ mx: 0.5 }} />
            </Stack>

            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ maxWidth: '100vw', mt: 5, mb: { xs: 5, md: 0 } }}
            >
              <IyzicoIllustration
                sx={{ maxWidth: '90vw' }}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack
              spacing={5}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
            >
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2}>
                  <Typography component="p" variant="overline">
                    {list.headline}
                  </Typography>
                  {list.children.map((link) => (
                    !link.external ? (
                      <Link
                        to={link.href}
                        key={link.name}
                        color="inherit"
                        variant="body2"
                        component={RouterLink}
                        sx={{ display: 'block' }}
                      >
                        {link.name}
                      </Link>
                    ) : (
                      <Link
                        href={link.href}
                        target="_blank"
                        key={link.name}
                        color="inherit"
                        variant="body2"
                        sx={{ display: 'block' }}
                      >
                        {link.name}
                      </Link>
                    )
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'center' },
          }}
        >
          © {new Date().getFullYear()} Etkin Okuma. Tüm hakları saklıdır.
          <br />
          <Link href="https://awaresol.com/">Awaresol</Link>
          &nbsp; tarafından özenle geliştirildi
        </Typography>
      </Container>
    </RootStyle>
  );
}