// hooks
import useSelfUser from '../hooks/identity/user/useSelfUser';
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ sx, ...other }) {
  const { data: user } = useSelfUser({
    load: true,
  });

  return (
    <Avatar
      src={user?.profilePicture?.url}
      alt={user?.firstName}
      color={user?.photoURL ? 'default' : createAvatar(user?.firstName).color}
      {...other}
    >
      {createAvatar(user?.firstName).name}
    </Avatar>
  );
}
