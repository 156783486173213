// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import { MouseEventHandler } from 'react';
import Iconify from '../../../components/Iconify';
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';
import Roles from '../../../contract/constants/Roles';
import { User } from '../../../contract/identity/User';
import LabelAnimate from '../../../components/LabelAnimate';
// import FitnessCenterIcon from 'icon';

// ----------------------------------------------------------------------

export type NavItem = {
  title: string;
  path?: string;
  icon?: JSX.Element;
  info?: JSX.Element;
  disabled?: boolean;
  roles?: string[];
  customUserCheck?: (user: User) => boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  active?: (path: string) => boolean;
  important?: boolean;
};

export type NavConfigGroupItem = NavItem & {
  children?: NavConfigGroupItem[];
  roles?: string[];
  caption?: string;
};

export type NavConfigGroup = {
  subheader: string;
  items: NavConfigGroupItem[];
  roles?: string[],
  customUserCheck?: (user: User) => boolean;
};

const getIcon = (name: string) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  shopping: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  student: getIcon('ic_student'),
  curriculum: getIcon('ic_guide'),
  task: getIcon('ic_task'),
  order: <Iconify icon="icon-park-outline:transaction-order" />,
  organization: <Iconify icon="bxs:business" />,
  organizationMember: getIcon('ic_user'),
  exercise: <Iconify icon="eva:settings-2-outline" />,
  customExercise: <Iconify icon="eva:settings-2-outline" />,
  content: <Iconify icon="fluent:content-settings-24-regular" />,
  contentGroup: <Iconify icon="carbon:cics-system-group" />,
  imageContent: <Iconify icon="ic:round-perm-media" />,
  test: <Iconify icon="eva:checkmark-circle-outline" />,
  playground: <Iconify icon="eva:play-circle-outline" />,
  level: <Iconify icon="eva:trending-up-outline" />,
  category: <Iconify icon="eva:at-outline" />,
  faq: <Iconify icon="eva:question-mark-circle-outline" />,
  coupon: <Iconify icon="bxs:coupon" />,
  competition: <Iconify icon="akar-icons:trophy" />,
  membership: <Iconify icon="ic:baseline-card-membership" />,
  placementTest: <Iconify icon="healthicons:medium-level-outline" />,
  achievements: <Iconify icon="bx:medal" />,
};

const navConfig: NavConfigGroup[] = [
  // ONBOARDING CUSTOMER
  {
    subheader: 'me',
    customUserCheck: (u) => !u.hasActiveMembership,
    
    items: [
      {
        title: 'placementTest',
        path: PATH_DASHBOARD.student.placementTest,
        icon: ICONS.placementTest,
        roles: [Roles.Student, Roles.Parent,],
        customUserCheck: (u) => !u.placementTestId,
        info: <LabelAnimate autoShake color="error">ÖNEMLİ</LabelAnimate>,
        important: true,
      },
      {
        title: 'student',
        path: PATH_DASHBOARD.student.today,
        customUserCheck: (u) => !!u.placementTestId,
        icon: ICONS.student,
        roles: [Roles.Student,],
      },
    ],
  },

  // ME
  {
    subheader: 'me',
    customUserCheck: (u) => u.hasActiveMembership,
    items: [
      {
        title: 'placementTest',
        path: PATH_DASHBOARD.student.placementTest,
        icon: ICONS.placementTest,
        roles: [Roles.Student, Roles.Parent,],
        customUserCheck: (u) => !u.placementTestId,
        info: <LabelAnimate autoShake color="error">ÖNEMLİ</LabelAnimate>,
        important: true,
      },

      {
        title: 'activeCurriculum',
        path: PATH_DASHBOARD.membership.active,
        icon: ICONS.membership,
        roles: [Roles.Student, Roles.Parent,],
        customUserCheck: (u) => !!u.hasActiveMembership,
        // info: <Label color="info">ÖNEMLİ</Label>,
        active: (p) => p.startsWith(PATH_DASHBOARD.membership.root),
      },
      {
        title: 'student',
        path: PATH_DASHBOARD.student.root,
        icon: ICONS.student,
        children: [
          {
            title: 'today', path: PATH_DASHBOARD.student.today,
          },
          {
            title: 'learningMap',
            path: PATH_DASHBOARD.student.learningMap,
            info: <Label color="info">BETA</Label>,
          },
          { title: 'testResults', path: PATH_DASHBOARD.student.placementTest, },
          { title: 'reports', path: PATH_DASHBOARD.student.reports, },
        ],
        roles: [Roles.Student,],
      },

      {
        title: 'competition',
        path: PATH_DASHBOARD.competition.root,
        icon: ICONS.competition,
        roles: [Roles.Student, Roles.Parent,],
      },

      // {
      //   title: 'achievement',
      //   path: PATH_DASHBOARD.competition.achievements,
      //   icon: ICONS.achievements,
      //   roles: [Roles.Student, Roles.Parent,],
      // },
    ],
    roles: [Roles.Student, Roles.Parent]
  },

  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    customUserCheck: (u) => u.hasActiveMembership,
    items: [
      // { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard, roles: [Roles.SystemAdmin, Roles.PortalAdmin,], },
      // { title: 'ecommerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce, roles: [Roles.SystemAdmin, Roles.PortalAdmin,], },
      // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics, roles: [Roles.SystemAdmin, Roles.PortalAdmin,], },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking, roles: [Roles.SystemAdmin, Roles.PortalAdmin,], },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking, roles: [Roles.SystemAdmin, Roles.PortalAdmin,], },
      // { title: 'student', path: PATH_DASHBOARD.student.root, icon: ICONS.student, roles: [Roles.Student,], },


      // {
      //   title: 'student',
      //   path: PATH_DASHBOARD.student.root,
      //   icon: ICONS.student,
      //   children: [
      //     { title: 'today', path: PATH_DASHBOARD.student.today },
      //     { title: 'learningMap', path: PATH_DASHBOARD.student.learningMap },
      //   ],
      //   roles: [Roles.Student,]
      // },

      // {
      //   title: 'shopping',
      //   path: PATH_DASHBOARD.shopping.shop,
      //   icon: ICONS.cart,
      //   roles: [Roles.Student, Roles.OrganizationAdmin]
      // },

      // TODO: Move to "Membership (Üyelik)" from "General"
      {
        title: 'order',
        path: PATH_DASHBOARD.order.root,
        icon: ICONS.order,
        roles: [Roles.Student, Roles.Parent, Roles.OrganizationAdmin]
      },
      // TODO: Move to "Membership (Üyelik)" from "General"
      {
        title: 'membership', // TODO: Translate to "Abonelik" instead of "Üyelik"
        path: PATH_DASHBOARD.membership.active,
        icon: ICONS.membership,
        roles: [Roles.Student, Roles.Parent, Roles.OrganizationAdmin]
      },
      // TODO: Move to "Membership (Üyelik)" from "General"
      {
        title: 'shopping',
        path: PATH_DASHBOARD.shopping.root,
        icon: ICONS.cart,
        children: [
          { title: 'shop', path: PATH_DASHBOARD.shopping.shop },
          { title: 'checkout', path: PATH_DASHBOARD.shopping.checkout },
        ],
        roles: [Roles.Student, Roles.Parent, Roles.OrganizationAdmin]
      },
    ],
    roles: [Roles.Student, Roles.Parent, Roles.OrganizationAdmin]
  },

  {
    subheader: 'general',
    items: [
      { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard, roles: [Roles.SystemAdmin, Roles.PortalAdmin,], },
    ],
    roles: [Roles.SystemAdmin, Roles.PortalAdmin]
  },

  {
    subheader: 'lms',
    items: [
      {
        title: 'category',
        path: PATH_DASHBOARD.category.root,
        icon: ICONS.category,
        children: [
          { title: 'list', path: PATH_DASHBOARD.category.list },
          { title: 'create', path: PATH_DASHBOARD.category.new },
        ],
        roles: [Roles.SystemAdmin, Roles.PortalAdmin],
      },
      // CUSTOM EXERCISE
      {
        title: 'customExercise',
        path: PATH_DASHBOARD.customExercise.root,
        icon: ICONS.customExercise,
        children: [
          { title: 'list', path: PATH_DASHBOARD.customExercise.list },
          { title: 'create', path: PATH_DASHBOARD.customExercise.new },
        ],
        roles: [Roles.SystemAdmin, Roles.PortalAdmin],
      },
      // TEST
      {
        title: 'test',
        path: PATH_DASHBOARD.test.root,
        icon: ICONS.test,
        children: [
          { title: 'list', path: PATH_DASHBOARD.test.list },
          { title: 'create', path: PATH_DASHBOARD.test.new },
        ],
        roles: [Roles.SystemAdmin, Roles.PortalAdmin],
      },
      // CURRICULUM
      {
        title: 'curriculum',
        path: PATH_DASHBOARD.curriculum.root,
        icon: ICONS.curriculum,
        children: [
          { title: 'list', path: PATH_DASHBOARD.curriculum.list },
          { title: 'create', path: PATH_DASHBOARD.curriculum.new },
        ],
        roles: [Roles.SystemAdmin, Roles.PortalAdmin],
      },
      // LEVEL
      {
        title: 'level',
        path: PATH_DASHBOARD.level.root,
        icon: ICONS.level,
        children: [
          { title: 'list', path: PATH_DASHBOARD.level.list },
          { title: 'create', path: PATH_DASHBOARD.level.new },
        ],
        roles: [Roles.SystemAdmin, Roles.PortalAdmin],
      },


      {
        title: 'content',
        path: PATH_DASHBOARD.contentGroup.root,
        icon: ICONS.content,
        children: [
          { title: 'index', path: PATH_DASHBOARD.content.root },

          // CONTENT GROUP
          // {
          //   title: 'contentGroup',
          //   path: PATH_DASHBOARD.contentGroup.root,
          //   icon: ICONS.contentGroup,
          //   children: [
          //     { title: 'list', path: PATH_DASHBOARD.contentGroup.list },
          //     { title: 'create', path: PATH_DASHBOARD.contentGroup.new },
          //   ],
          // },

          // // IMAGE CONTENT
          // {
          //   title: 'imageContent',
          //   path: PATH_DASHBOARD.imageContent.root,
          //   icon: ICONS.imageContent,
          //   children: [
          //     { title: 'list', path: PATH_DASHBOARD.imageContent.list },
          //     { title: 'create', path: PATH_DASHBOARD.imageContent.new },
          //     { title: 'createGroup', path: PATH_DASHBOARD.imageContent.newGroup },
          //   ],
          // },

          // // TEXT CONTENT
          // {
          //   title: 'textContent',
          //   path: PATH_DASHBOARD.textContent.root,
          //   icon: ICONS.imageContent,
          //   children: [
          //     { title: 'list', path: PATH_DASHBOARD.textContent.list },
          //     { title: 'create', path: PATH_DASHBOARD.textContent.new },
          //   ],
          // },
        ],
        roles: [Roles.SystemAdmin, Roles.PortalAdmin],
      },
    ],
    roles: [Roles.PortalAdmin, Roles.SystemAdmin],
  },

  {
    subheader: 'sales',
    items: [
      // PRODUCT
      {
        title: 'product',
        path: PATH_DASHBOARD.product.root,
        icon: ICONS.ecommerce,
        children: [
          { title: 'list', path: PATH_DASHBOARD.product.list },
          { title: 'create', path: PATH_DASHBOARD.product.new },
        ],
        roles: [Roles.SystemAdmin, Roles.PortalAdmin],
      },
      {
        title: 'order',
        path: PATH_DASHBOARD.order.root,
        icon: ICONS.ecommerce,
        roles: [Roles.SystemAdmin, Roles.PortalAdmin],
      },
      // COUPON
      {
        title: 'coupon',
        path: PATH_DASHBOARD.coupon.root,
        icon: ICONS.coupon,
        children: [
          { title: 'list', path: PATH_DASHBOARD.coupon.list },
          { title: 'create', path: PATH_DASHBOARD.coupon.new },
        ],
        roles: [Roles.SystemAdmin, Roles.PortalAdmin],
      },
    ],
    roles: [Roles.SystemAdmin, Roles.PortalAdmin],
  },
  {
    subheader: 'management',
    items: [
      // // USER
      // {
      //   title: 'userold',
      //   path: PATH_DASHBOARD.user.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'list', path: PATH_DASHBOARD.user.list },
      //     { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
      //   ],
      //   roles: [Roles.SystemAdmin, Roles.PortalAdmin, Roles.OrganizationAdmin]
      // },

      // KATALOG
      // {
      //   title: 'catalog',
      //   path: PATH_DASHBOARD.catalog.root,
      //   icon: ICONS.ecommerce,
      //   children: [
      //     { title: 'list', path: PATH_DASHBOARD.catalog.list, },
      //     { title: 'create', path: PATH_DASHBOARD.catalog.new },
      //     { title: 'edit', path: PATH_DASHBOARD.catalog.demoEdit },
      //   ],
      //   roles: [Roles.SystemAdmin, Roles.PortalAdmin],
      // },







      // E-COMMERCE
      // {
      //   title: 'ecommerce',
      //   path: PATH_DASHBOARD.eCommerce.root,
      //   icon: ICONS.cart,
      //   children: [
      //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
      //     { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
      //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
      //     { title: 'create', path: PATH_DASHBOARD.eCommerce.new },
      //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit },
      //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
      //   ],
      // },
      // // Organization
      // {
      //   title: 'organization_old',
      //   path: PATH_DASHBOARD.organization_old.root,
      //   icon: ICONS.invoice,
      //   children: [
      //     { title: 'list', path: PATH_DASHBOARD.organization_old.list },
      //     { title: 'create', path: PATH_DASHBOARD.organization_old.new },
      //   ],
      //   roles: [Roles.SystemAdmin, Roles.PortalAdmin],
      // },


      // Organization
      {
        title: 'organization',
        path: PATH_DASHBOARD.organization.root,
        icon: ICONS.organization,
        children: [
          { title: 'list', path: PATH_DASHBOARD.organization.list },
          { title: 'create', path: PATH_DASHBOARD.organization.new },
        ],
        roles: [Roles.SystemAdmin, Roles.PortalAdmin,],
      },

      // USER
      {
        title: 'user',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'list', path: PATH_DASHBOARD.user.list },
          { title: 'fullMembershipAccessStatuses', path: PATH_DASHBOARD.user.fullMembershipAccessStatuses },
          // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
        ],
        roles: [Roles.SystemAdmin, Roles.PortalAdmin,],
      },

      // OrganizationUnit
      {
        title: 'organizationUnit',
        path: PATH_DASHBOARD.organizationUnit.root,
        icon: ICONS.invoice,
        children: [
          { title: 'list', path: PATH_DASHBOARD.organizationUnit.list },
          { title: 'create', path: PATH_DASHBOARD.organizationUnit.new },
        ],
        roles: [Roles.OrganizationAdmin],
      },

      // OrganizationUnit
      {
        title: 'organizationMember',
        path: PATH_DASHBOARD.organizationMember.root,
        icon: ICONS.user,
        children: [
          { title: 'list', path: PATH_DASHBOARD.organizationMember.list },
          { title: 'create', path: PATH_DASHBOARD.organizationMember.new },
          { title: 'bulk', path: PATH_DASHBOARD.organizationMember.bulk },
        ],
        roles: [Roles.OrganizationAdmin],
      },

      // CURRICULUM OLD
      // {
      //   title: 'curriculum_old',
      //   path: PATH_DASHBOARD.curriculum_old.root,
      //   icon: ICONS.curriculum,
      //   children: [
      //     { title: 'list', path: PATH_DASHBOARD.curriculum_old.list },
      //     { title: 'new', path: PATH_DASHBOARD.curriculum_old.new },
      //     { title: 'edit', path: PATH_DASHBOARD.curriculum_old.demoEdit },
      //     { title: 'view', path: PATH_DASHBOARD.curriculum_old.demoView },
      //   ],
      // },

      // // INVOICE
      // {
      //   title: 'invoice',
      //   path: PATH_DASHBOARD.invoice.root,
      //   icon: ICONS.invoice,
      //   children: [
      //     { title: 'list', path: PATH_DASHBOARD.invoice.list },
      //     { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
      //     { title: 'create', path: PATH_DASHBOARD.invoice.new },
      //     { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
      //   ],
      //   roles: [Roles.SystemAdmin, Roles.PortalAdmin],
      // },
      // // BLOG
      // {
      //   title: 'blog',
      //   path: PATH_DASHBOARD.blog.root,
      //   icon: ICONS.blog,
      //   children: [
      //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
      //     { title: 'post', path: PATH_DASHBOARD.blog.demoView },
      //     { title: 'create', path: PATH_DASHBOARD.blog.new },
      //   ],
      //   roles: [Roles.SystemAdmin, Roles.PortalAdmin],
      // },
    ],
    roles: [Roles.SystemAdmin, Roles.PortalAdmin, Roles.OrganizationAdmin,],
  },

  {
    subheader: 'website',
    items: [
      // EXERCISE
      {
        title: 'faq',
        path: PATH_DASHBOARD.faq.root,
        icon: ICONS.faq,
        children: [
          { title: 'list', path: PATH_DASHBOARD.faq.list },
          { title: 'create', path: PATH_DASHBOARD.faq.new },
        ],
        roles: [Roles.SystemAdmin, Roles.PortalAdmin],
      },
    ],
    roles: [Roles.SystemAdmin, Roles.PortalAdmin],
  },



  {
    subheader: 'admin',
    items: [
      // EXERCISE
      {
        title: 'exercise',
        path: PATH_DASHBOARD.exercise.root,
        icon: ICONS.exercise,
        children: [
          { title: 'list', path: PATH_DASHBOARD.exercise.list },
          { title: 'create', path: PATH_DASHBOARD.exercise.new },
        ],
        roles: [Roles.SystemAdmin],
      },
      // PLAYGROUND
      {
        title: 'playground',
        path: PATH_DASHBOARD.playground.root,
        icon: ICONS.playground,
        roles: [Roles.SystemAdmin],
      },
    ],
    roles: [Roles.SystemAdmin],
  },

  // // APP
  // // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: <Label color="error">+32</Label>,
  //     },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     { title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban },
  //   ],
  //   roles: [Roles.SystemAdmin],
  // },

  // // DEMO MENU STATES
  // {
  //   subheader: 'other_cases',
  //   items: [
  //     {
  //       // default roles : All roles can see this entry.
  //       // roles: ['user'] Only users can see this item.
  //       // roles: ['admin'] Only admin can see this item.
  //       // roles: ['admin', 'manager'] Only admin/manager can see this item.
  //       // Reference from 'src/guards/RoleBasedGuard'.
  //       title: 'item_by_roles',
  //       path: PATH_DASHBOARD.permissionDenied,
  //       icon: ICONS.menuItem,
  //       roles: ['admin'],
  //       caption: 'only_admin_can_see_this_item',
  //     },
  //     {
  //       title: 'menu_level_1',
  //       path: '#1',
  //       icon: ICONS.menuItem,
  //       children: [
  //         { title: 'menu_level_2', path: '#2', disabled: true },
  //         {
  //           title: 'menu_level_2',
  //           path: '#3',
  //           children: [
  //             { title: 'menu_level_3', path: '#4' },
  //             { title: 'menu_level_3', path: '#5' },
  //           ],
  //         },
  //       ],
  //     },
  //     { title: 'item_disabled', path: '#disabled', icon: ICONS.menuItem, disabled: true },
  //     {
  //       title: 'item_label',
  //       path: '#label',
  //       icon: ICONS.menuItem,
  //       info: (
  //         <Label color="info" startIcon={<Iconify icon="eva:email-fill" />}>
  //           NEW
  //         </Label>
  //       ),
  //     },
  //     { title: 'item_caption', path: '#caption', icon: ICONS.menuItem, caption: 'description' },
  //   ],
  //   roles: [Roles.SystemAdmin],
  // },
];

export default navConfig;
