import { DataApiResponse } from '../../../contract/base/Response';
import { User } from '../../../contract/identity/User';
import axiosInstance from '../../../utils/axios';
import { handleError } from '../../base/baseService';

export async function getSelfUser() {
  try {
    const response = await axiosInstance.get<DataApiResponse<User>>('/users/self');

    return response.data;
  }
  catch (error) {
    return handleError(error);
  }
}