import { useFormContext, Controller } from 'react-hook-form';
import { Switch, FormControlLabel, FormControlLabelProps, SwitchProps } from '@mui/material';
import { PropsWithChildren } from 'react';


export default function RHFSwitch({ name, ...other }: PropsWithChildren<{ name: string; } & Omit<FormControlLabelProps, 'control'>>) {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      {...other}
      control={
        <Controller name={name} control={control} render={({ field }) => <Switch
          {...field}
          value={(field.value === null || field.value === undefined) ? false : field.value}
          checked={(field.value === null || field.value === undefined) ? false : field.value}
          onChange={(e) => {
            field.onChange(e.target.checked)
          }}
        />} />
      }
    />
  );
}

export function RHFSwitchControlled({ name, switchProps, ...other }: PropsWithChildren<{ name: string; switchProps: SwitchProps } & Omit<FormControlLabelProps, 'control'>>) {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      {...other}
      control={
        <Controller name={name} control={control} render={({ field }) => <Switch
          {...field}
          {...switchProps}
          value={(field.value === null || field.value === undefined) ? false : field.value}
          checked={(field.value === null || field.value === undefined) ? false : field.value}
          onChange={(e, checked) => {
            field.onChange(e.target.checked);
            switchProps?.onChange && switchProps?.onChange(e, checked);
          }}
        />} />
      }
    />
  );
}