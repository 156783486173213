import { MouseEventHandler, useState } from 'react';
import { MenuItem, Stack } from '@mui/material';
import useLocales from '../../../hooks/useLocales';
import Image from '../../../components/Image';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import Flag from 'react-world-flags';

export default function LanguagePopover() {
  const { allLang, currentLang, onChangeLang, translate } = useLocales();

  const [open, setOpen] = useState<Element | null>(null);

  const handleOpen: MouseEventHandler<HTMLButtonElement> = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          // @ts-ignore -- TODO: ??
          ...(open && { bgcolor: 'action.selected' }),
        }}
      >
        {/* <Image disabledEffect src={currentLang.icon} alt={currentLang.label} /> */}
        <Flag code={currentLang.icon} fallback={currentLang.label} style={{ width: 28, marginRight: 2 }} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {allLang.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => {
                onChangeLang(option.value);
                handleClose();
              }}
            >
              {/* <Image disabledEffect alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} /> */}
              <Flag code={option.icon} fallback={currentLang.label} style={{ width: 28, marginRight: 2 }} />

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
