import { DataApiResponse } from '../../../contract/base/Response';
import { AddressDetail } from '../../../contract/common/AddressDetail';
import axiosInstance from '../../../utils/axios';
import { handleError } from '../../base/baseService';

export async function getSelfAddress() {
  try {
    const response = await axiosInstance.get<DataApiResponse<AddressDetail>>('/users/self/address');

    return response.data;
  }
  catch (error) {
    return handleError(error);
  }
}