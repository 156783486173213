import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { ApiError } from '../../../contract/base/Response';
import { Product } from '../../../contract/catalog/Product';
import { AddressDetail } from '../../../contract/common/AddressDetail';
import { PaymentMethod } from '../../../contract/enum/PaymentMethod';
import { Order } from '../../../contract/order/Order';
import { listActiveProducts } from '../../../services/catalog/product/listActiveProducts';
import { previewOrder } from '../../../services/order/order/previewOrder';
import axiosInstance from '../../../utils/axios';
import { dispatch, store } from '../../store';

export type CartItem = {
  productId: number;
  name: string;
  price: number;
  quantity: number;
  isStudent: boolean;
};

const initialState: {
  isLoading: boolean;
  error: ApiError[] | null;
  products: Product[];
  checkout: {
    activeStep: number;
    organizationUnitIds: number[];
    memberIds: number[];
    allMembers: boolean;
    exceptMemberIds: number[];
    searchFilter: string;
    unitsFilter: number[];
    cart: CartItem[];
    subtotal: number;
    discount: number;
    tax: number;
    total: number;
    couponCode: string;
    billing: AddressDetail | null;
    order: Order | null;
  };
} = {
  isLoading: false,
  error: null,
  products: [],
  checkout: {
    activeStep: 0,
    organizationUnitIds: [],
    memberIds: [],
    allMembers: false,
    exceptMemberIds: [],
    searchFilter: '',
    unitsFilter: [],
    cart: [],
    subtotal: 0,
    discount: 0,
    tax: 0,
    total: 0,
    couponCode: '',
    billing: null,
    order: null,
  },
};

const slice = createSlice({
  name: 'shopping',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action: PayloadAction<ApiError[]>) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },

    // CHECKOUT
    getCart(state, action: PayloadAction<CartItem[]>) {
      const cart = action.payload;

      const subtotal = sum(cart.map((cartItem) => cartItem.price * cartItem.quantity));
      const discount = cart.length === 0 ? 0 : state.checkout.discount;
      const tax = cart.length === 0 ? 0 : state.checkout.tax;
      const billing = cart.length === 0 ? null : state.checkout.billing;

      state.checkout.cart = cart;
      state.checkout.discount = discount;
      state.checkout.tax = tax;
      state.checkout.billing = billing;
      state.checkout.subtotal = subtotal;
      state.checkout.total = subtotal - discount;
    },

    addCart(state, action: PayloadAction<CartItem>) {
      const product = action.payload;
      const isEmptyCart = state.checkout.cart.length === 0;

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, product];
      } else {
        state.checkout.cart = state.checkout.cart.map((_product) => {
          const isExisted = _product.productId === product.productId;
          if (isExisted) {
            return {
              ..._product,
              quantity: product.isStudent ? 1 : _product.quantity + 1,
            };
          }
          return _product;
        });
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, product], 'productId');
    },

    setCart(state, action: PayloadAction<CartItem[]>) {
      const products = action.payload;
      // state.checkout.cart = products;
      state.checkout.cart = uniqBy([...products], 'productId');
    },

    deleteCart(state, action: PayloadAction<number>) {
      const updateCart = state.checkout.cart.filter((item) => item.productId !== action.payload);

      state.checkout.cart = updateCart;
    },

    resetCart(state) {
      // state.checkout.activeStep = 0;
      // state.checkout.cart = [];
      // state.checkout.total = 0;
      // state.checkout.subtotal = 0;
      // state.checkout.discount = 0;
      // state.checkout.billing = null;
      state.checkout = {
        ...initialState.checkout
      };
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = state.checkout.cart.map((product) => {
        if (product.productId === productId) {
          return {
            ...product,
            quantity: product.quantity + 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = state.checkout.cart.map((product) => {
        if (product.productId === productId) {
          return {
            ...product,
            quantity: product.quantity - 1,
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    createBilling(state, action: PayloadAction<AddressDetail | null>) {
      state.checkout.billing = action.payload;
    },

    applyDiscount(state, action) {
      const discount = action.payload;
      state.checkout.discount = discount;
      state.checkout.total = state.checkout.subtotal - discount;
    },

    updateTotals(state, action: PayloadAction<{
      subtotal: number;
      discount: number;
      tax: number;
      total: number;
    }>) {
      state.checkout.subtotal = action.payload.subtotal;
      state.checkout.discount = action.payload.discount;
      state.checkout.tax = action.payload.tax;
      state.checkout.total = action.payload.total;
    },

    setOrganizationUnitIds(state, action: PayloadAction<number[]>) {
      state.checkout.organizationUnitIds = action.payload;
    },

    setMemberIds(state, action: PayloadAction<number[]>) {
      state.checkout.memberIds = action.payload;
    },

    setAllMembers(state, action: PayloadAction<boolean>) {
      state.checkout.allMembers = action.payload;
    },

    setExceptMemberIds(state, action: PayloadAction<number[]>) {
      state.checkout.exceptMemberIds = action.payload;
    },

    setSearchFilter(state, action: PayloadAction<string>) {
      state.checkout.searchFilter = action.payload;
    },

    setUnitsFilter(state, action: PayloadAction<number[]>) {
      state.checkout.unitsFilter = action.payload;
    },

    setCouponCode(state, action: PayloadAction<string>) {
      state.checkout.couponCode = action.payload;
    },

    setOrder(state, action: PayloadAction<Order | null>) {
      state.checkout.order = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCart,
  addCart,
  setCart,
  resetCart,
  onGotoStep,
  onBackStep,
  onNextStep,
  deleteCart,
  createBilling,
  applyDiscount,
  increaseQuantity,
  decreaseQuantity,
  setOrganizationUnitIds,
  setMemberIds,
  setAllMembers,
  setExceptMemberIds,
  setSearchFilter,
  setUnitsFilter,
  setCouponCode,
  setOrder,
} = slice.actions;

// ----------------------------------------------------------------------

export function getProducts() {
  return async () => {
    dispatch(slice.actions.startLoading());

    const response = await listActiveProducts();
    if (response.success) {
      dispatch(slice.actions.getProductsSuccess(response.datas));
    }
    else {
      dispatch(slice.actions.hasError(response.errors));
    }
  };
}

export function applyCoupon({ paymentMethod, clear }: {
  paymentMethod: PaymentMethod;
  clear: boolean;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const state = store.getState().shopping;

    const response = await previewOrder({
      paymentMethod: paymentMethod,
      billingAddress: {
        city: '',
        country: '',
        line1: '',
        line2: '',
        province: '',
        state: '',
        zipCode: '',
      },
      coupons: (!!state.checkout.couponCode && !clear) ? [{
        couponToken: state.checkout.couponCode
      }] : [],
      lineItems: state.checkout.cart.map((x) => ({
        productId: x.productId,
        amount: x.quantity
      })),
      notes: []
    });
    if (response.success) {
      dispatch(slice.actions.updateTotals({
        subtotal: response.data.subTotal.amount,
        discount: response.data.discount.amount,
        tax: response.data.tax.amount,
        total: response.data.total.amount,
      }));
    }
    else {
      dispatch(slice.actions.hasError(response.errors));
    }
  };
}
