import { PropsWithChildren, createContext, useEffect, useRef, useState } from 'react';

type EventArgs = {
  handled: boolean;
};

type EventHandler = (value: number | null, e: EventArgs) => boolean;

export type CurrentTaskParameter<TValue = unknown> = {
  type: 'boolean';
  key: string;
  name: string;
  value: boolean;
} | {
  type: 'selection';
  key: string;
  name: string;
  value: TValue;
  valueSet: { value: TValue; label: string; }[];
} | {
  type: 'info';
  key: string;
  name: string;
  value: TValue;
} | {
  type: 'number';
  key: string;
  name: string;
  value: number;
  readonly?: boolean;
};

type PlaygroundContextState = {
  mode: 'exercise' | 'playground';
  setMode: (value: 'exercise' | 'playground') => void;

  active: boolean;
  setActive: (value: boolean) => void;

  showFilters: boolean;
  setShowFilters: (value: boolean) => void;

  playSound: boolean;
  setPlaySound: (value: boolean) => void;

  playMusic: boolean;
  setPlayMusic: (value: boolean) => void;

  currentTaskId: number | null;
  setCurrentTaskId: (value: number | null) => void;
  addTaskChangedEventHandler: (eventHandler: EventHandler) => void;

  currentTestId: number | null;
  setCurrentTestId: (value: number | null) => void;
  addTestChangedEventHandler: (eventHandler: EventHandler) => void;

  currentTaskParameters: CurrentTaskParameter[];
  setCurrentTaskParameters: (parameters: CurrentTaskParameter[]) => void;

  currentTaskInfoParameters: ({ type: 'info' } & CurrentTaskParameter)[];
  setCurrentTaskInfoParameters: (parameters: ({ type: 'info' } & CurrentTaskParameter)[]) => void;
};

const cb = () => () => { };

const initialState: PlaygroundContextState = {
  mode: 'exercise',
  setMode: cb(),

  active: false,
  setActive: cb(),

  showFilters: false,
  setShowFilters: cb(),

  playSound: false,
  setPlaySound: cb(),

  playMusic: false,
  setPlayMusic: cb(),

  currentTaskId: null,
  setCurrentTaskId: cb(),
  addTaskChangedEventHandler: (eventHandler: EventHandler) => false,

  currentTestId: null,
  setCurrentTestId: cb(),
  addTestChangedEventHandler: (eventHandler: EventHandler) => false,

  currentTaskParameters: [],
  setCurrentTaskParameters: cb(),

  currentTaskInfoParameters: [],
  setCurrentTaskInfoParameters: cb(),
};

const PlaygroundContext = createContext<PlaygroundContextState>(initialState);

function PlaygroundContextProvider({ children }: PropsWithChildren<{}>) {
  const [mode, setMode] = useState(initialState.mode);
  const [active, setActive] = useState(initialState.active);
  const [showFilters, setShowFilters] = useState(initialState.showFilters);
  const [playSound, setPlaySound] = useState(initialState.playSound);
  const [playMusic, setPlayMusic] = useState(initialState.playMusic);
  const [currentTaskId, setCurrentTaskId] = useState(initialState.currentTaskId);
  const [currentTestId, setCurrentTestId] = useState(initialState.currentTestId);
  const [taskChangedEventHandlers, setTaskChangedEventHandlers] = useState<EventHandler[]>([]);
  const [testChangedEventHandlers, setTestChangedEventHandlers] = useState<EventHandler[]>([]);
  const [currentTaskParameters, setCurrentTaskParameters] = useState<CurrentTaskParameter[]>([]);
  const [currentTaskInfoParameters, setCurrentTaskInfoParameters] = useState<({ type: 'info' } & CurrentTaskParameter)[]>([]);
  const audio = useRef(new Audio('/assets/music2.mpeg'));

  const handleSetCurrentTaskId = (value: number | null) => {
    if (!taskChangedEventHandlers ||
      !taskChangedEventHandlers.length) {
      setCurrentTaskId(value);
      return;
    }

    let ea: EventArgs = {
      handled: false
    };
    for (let i = 0; i < taskChangedEventHandlers.length; i++) {
      const handler = taskChangedEventHandlers[i];
      if (!handler(value, ea)) {
        // console.log('Handler returned false', ea);
        return;
      }

      // console.log('EA', ea);
      if (ea.handled) {
        return;
      }
    }
  };

  const handleSetCurrentTestId = (value: number | null) => {
    if (!testChangedEventHandlers ||
      !testChangedEventHandlers.length) {
      setCurrentTestId(value);
      return;
    }

    let ea: EventArgs = {
      handled: false
    };
    for (let i = 0; i < testChangedEventHandlers.length; i++) {
      const handler = testChangedEventHandlers[i];
      if (!handler(value, ea)) {
        // console.log('Handler returned false', ea);
        return;
      }

      // console.log('EA', ea);
      if (ea.handled) {
        return;
      }
    }
  };

  useEffect(() => {
    if (playSound && playMusic) {
      setPlayMusic(false);
    }
  }, [playSound]);

  useEffect(() => {
    if (playMusic && playSound) {
      setPlaySound(false);
    }
  }, [playMusic]);

  useEffect(() => {
    if (playMusic) {
      audio.current?.play();
    }
    else {
      audio.current?.pause();
    }

    return () => {
      audio.current?.pause();
    };
  }, [playMusic]);

  return (
    <PlaygroundContext.Provider
      value={{
        mode: mode,
        setMode: setMode,

        active: active,
        setActive: setActive,

        showFilters: showFilters,
        setShowFilters: setShowFilters,

        playSound: playSound,
        setPlaySound: setPlaySound,

        playMusic: playMusic,
        setPlayMusic: setPlayMusic,

        currentTaskId: currentTaskId,
        setCurrentTaskId: handleSetCurrentTaskId,
        addTaskChangedEventHandler: (eventHandler) => setTaskChangedEventHandlers(x => [...x, eventHandler]),

        currentTestId: currentTestId,
        setCurrentTestId: handleSetCurrentTestId,
        addTestChangedEventHandler: (eventHandler) => setTestChangedEventHandlers(x => [...x, eventHandler]),

        currentTaskParameters: currentTaskParameters,
        setCurrentTaskParameters: setCurrentTaskParameters,

        currentTaskInfoParameters: currentTaskInfoParameters,
        setCurrentTaskInfoParameters: setCurrentTaskInfoParameters,
      }}
    >
      {children}
    </PlaygroundContext.Provider>
  );
}

export { PlaygroundContext, PlaygroundContextProvider };
