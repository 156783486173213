import { Icon } from '@iconify/react';
import { Box, BoxProps } from '@mui/material';

type PropType = {
  icon: JSX.Element | string;
} & BoxProps;

export default function Iconify(props: PropType) {
  const { icon, sx, ...other } = props;
  return <Box component={Icon} icon={icon} sx={{ ...sx }} {...other} />;
}
