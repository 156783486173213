export namespace ExtraPropertyKeys {
  export namespace Category {
    export namespace Exercise {
      export const Icon: string = "ep.category.exercise.icon";
      export const Color: string = "ep.category.exercise.color";
    };
    export namespace Faq {
      export const Icon: string = "ep.category.faq.icon";
      export const Color: string = "ep.category.faq.color";
    }
  }
}