import { Money } from '../../../contract/aware.currency/Money';
import { DataApiResponse } from '../../../contract/base/Response';
import { AddressDetail } from '../../../contract/common/AddressDetail';
import { PaymentMethod } from '../../../contract/enum/PaymentMethod';
import { CreateOrderCoupon } from '../../../contract/order/OrderCoupon';
import { CreateOrderLineItem } from '../../../contract/order/OrderLineItem';
import { CreateOrderNote } from '../../../contract/order/OrderNote';
import axiosInstance from '../../../utils/axios';
import { handleError } from '../../base/baseService';

type PreviewOrder = {
  paymentMethod: PaymentMethod;
  billingAddress: AddressDetail;
  coupons: CreateOrderCoupon[];
  lineItems: CreateOrderLineItem[];
  notes: CreateOrderNote[];
};

// TODO: Move to contract...
export type OrderTotalsResponse = {
  subTotal: Money;
  discount: Money;
  tax: Money;
  total: Money;
};

export async function previewOrder(request: PreviewOrder) {
  try {
    const response = await axiosInstance.post<DataApiResponse<OrderTotalsResponse>>('/orders/preview', request);

    return response.data;
  }
  catch (error) {
    return handleError(error);
  }
}