import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
import { PATH_PAGE } from '../../../routes/paths';

export default function LoginJoinTest() {
  return (
    <>
      <Stack direction="row" alignItems="center" sx={{ mb: 5 }} spacing={3} >
        <Box sx={{ flexGrow: 1 }}>

          {/* <Card>
            <CardContent
              sx={{ display: 'flex', flexDirection: 'column' }}> */}
          <Box
            sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}
          >

            <Typography variant="h4" gutterBottom>
              Ücretsiz seviye belirme testine katıl
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              ve okuma becerilerini değerlendir!
            </Typography>
            <Button
              sx={{ alignSelf: 'end' }}
              component={RouterLink} to={PATH_PAGE.placementTest.root} size="large" fullWidth type="button" variant="contained" >
              Teste Katıl
            </Button>
          </Box>
          {/* </CardContent>
          </Card> */}
          <Divider variant="middle" />

          <Box
            sx={{ mt: 2 }}
          >
            <Typography variant="h4" gutterBottom>
              Giriş Yap
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Kullanıcı bilgilerinizi girin.
            </Typography>
          </ Box>
        </Box>

        {/* <Tooltip title={capitalCase(method)} placement="right">
                <>
                  <Image
                    disabledEffect
                    src={"https://etkinokuma.com/images/etkin-okumabox.png"}
                    // src={`https://minimal-assets-api.vercel.app/assets/icons/auth/ic_${method}.png`}
                    // TODO: etkin-okuma box icon??
                    sx={{ width: 48, height: 48 }}
                  />
                </>
              </Tooltip> */}
      </Stack>
    </>
  );
}