import { createSearchParams, URLSearchParamsInit, useNavigate } from 'react-router-dom';

function useNavigateSearch<T>() {
  const navigate = useNavigate();
  return (pathname: string, params: URLSearchParamsInit) =>
    navigate({
      pathname: pathname,
      search: `?${createSearchParams(params)}`,
    });
}

export default useNavigateSearch;