import { useFormContext, Controller } from 'react-hook-form';
import { Radio, RadioGroup, FormHelperText, FormControlLabel, RadioGroupProps } from '@mui/material';

type RHFRadioGroupProps = RadioGroupProps & {
  name: string;
  options: string[];
  getOptionLabel?: string[];
};

export default function RHFRadioGroup({ name, options, getOptionLabel, ...other }: RHFRadioGroupProps) {
  const { control, trigger } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <RadioGroup
            {...field}
            row
            {...other}
            value={field.value || null}
            onChange={(e) => { field.onChange(e); trigger(); }}
          >
            {options.map((option, index) => (
              <FormControlLabel
                key={option}
                value={option}
                // onClick={() => setTimeout(() => trigger()}
                control={<Radio onChange={() => trigger()} onClick={() => trigger()} />}
                label={getOptionLabel?.length ? getOptionLabel[index] : option}
              />
            ))}
          </RadioGroup>

          {!!error && (
            <FormHelperText error sx={{ px: 2 }}>
              {error.message}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
}
