import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../../contract/identity/User';
import {
  dispatch, store,
} from '../../store';
import { getSelfUser as gsu } from '../../../services/identity/user/getSelfUser';

type State = {
  isLoading: boolean;
  errors: string[] | null;

  loadingSelfUser: boolean;
  selfUser: User | null;
};

const initialState: State = {
  isLoading: false,
  errors: null,

  loadingSelfUser: false,
  selfUser: null,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasErrors(state, action: PayloadAction<string[]>) {
      state.isLoading = false;
      state.errors = action.payload;
    },

    // START LOADING SELF USER
    startLoadingSelfUser(state) {
      state.loadingSelfUser = true;
    },

    // GET SELF USER
    getSelfUserSuccess(state, action: PayloadAction<{ user: User | null; }>) {
      state.loadingSelfUser = false;
      state.selfUser = action.payload.user;
    },

    // CLEAR SELF USER
    clearSelfUser(state) {
      state.loadingSelfUser = false;
      state.selfUser = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoadingSelfUser,
  getSelfUserSuccess,
  clearSelfUser,
} = slice.actions;

export function getSelfUser() {
  return async () => {
    if (store.getState().user.loadingSelfUser) {
      console.log('Already loading self user...');
      return;
    }

    dispatch(slice.actions.startLoadingSelfUser());

    const response = await gsu();

    if (response.success) {
      dispatch(slice.actions.getSelfUserSuccess({
        user: response.data,
      }));
    }
    else {
      dispatch(slice.actions.hasErrors(response.errors.map(x => x.message)));
    }
  };
}